import TabbedComponent from "./TabbedComponent";
import BetsList from "./BetsList";
import MatchedBetsList from "./MatchedBetsList/MatchedBetsList";
const BetTracker = () => {

  const tabs = [
    {
      name: "Matched Bets",
      component: <MatchedBetsList />
    },
    {
      name: "All Bets",
      component: <BetsList />
    },
  ]

  return (
    <div>
      <h1>Bet Tracker</h1>
      <TabbedComponent tabs={tabs} />
    </div>
  )
}

export default BetTracker;