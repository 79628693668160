import {useEffect, useState} from "react";
import {fetchDeposits, fetchSportsbookSignups, fetchWithdrawals} from "../../utils/ApiUtils";
import moment from "moment";
import {
  currencyFormatter,
  matchedBetIsFreeBet,
  matchedBetIsMiddle,
  matchedBetIsMiddleThatHit, matchedBetWonBothSides
} from "../../utils/BetUtils";
import "./MonthlySummary.css";

const MonthlySummary = ({deposits, withdrawals, sportsbookSignups, matchedBets, month, ...rest}) => {
  const [depositCount, setDepositCount] = useState(0);
  const [depositAmount, setDepositAmount] = useState(0);
  const [depositBonusMoneyAmount, setDepositMoneyBonusAmount] = useState(0);
  const [depositBonusMoneyCount, setDepositBonusMoneyCount] = useState(0);
  const [depositFreeBetsCount, setDepositFreeBetsCount] = useState(0);
  const [depositFreeBetsAmount, setDepositFreeBetsAmount] = useState(0);
  const [withdrawalCount, setWithdrawalCount] = useState(0);
  const [withdrawalAmount, setWithdrawalAmount] = useState(0);
  const [matchedBetCount, setMatchedBetCount] = useState(0);
  const [matchedBetTotalWagered, setMatchedBetTotalWagered] = useState(0);
  const [freeBetCount, setFreeBetCount] = useState(0);
  const [freeBetsWagered, setFreeBetsWagered] = useState(0);
  const [middleBetCount, setMiddleBetCount] = useState(0);
  const [middleBetsHit, setMiddleBetsHit] = useState(0);
  const [realMoneyWagered, setRealMoneyWagered] = useState(0);
  const [netWinnings, setNetWinnings] = useState(0);

  useEffect(() => {
    let _depositCount = 0;
    let _depositAmount = 0;
    let _depositBonusMoneyCount = 0;
    let _depositBonusMoneyAmount = 0;
    let _depositFreeBetsCount = 0;
    let _depositFreeBetsAmount = 0;

    for (const index in deposits) {
      const deposit = deposits[index];
      if (moment(deposit.date).month() === month) {

        if (deposit.money_type.name.toLowerCase() === `real money`) {
          _depositCount += 1;
          _depositAmount += +(deposit.amount);
        } else if (deposit.money_type.name.toLowerCase() === `bonus real money`) {
          _depositBonusMoneyCount += 1;
          _depositBonusMoneyAmount += +(deposit.amount);
        } else if (deposit.money_type.name.toLowerCase() === `free-bets (stake not returned)`) {
          _depositFreeBetsCount += 1;
          _depositFreeBetsAmount += +(deposit.amount);
        }
      }
    }

    setDepositCount(_depositCount);
    setDepositAmount(_depositAmount);
    setDepositBonusMoneyCount(_depositBonusMoneyCount);
    setDepositMoneyBonusAmount(_depositBonusMoneyAmount);
    setDepositFreeBetsCount(_depositFreeBetsCount);
    setDepositFreeBetsAmount(_depositFreeBetsAmount);

    let _withdrawalCount = 0;
    let _withdrawalAmount = 0;

    for (const index in withdrawals) {
      const withdrawal = withdrawals[index];
      if (moment(withdrawal.date).month() === month) {
        _withdrawalCount += 1;
        _withdrawalAmount += +(withdrawal.amount);
      }
    }

    setWithdrawalCount(_withdrawalCount);
    setWithdrawalAmount(_withdrawalAmount);


    let _matchedBetCount = 0;
    let _matchedBetTotalWagered = 0;
    let _middleBetCount = 0;
    let _freeBetCount = 0;
    let _freeBetsWagered = 0;
    let _realMoneyWagered = 0;
    let _netWinnings = 0;
    let _middlesHit = 0;

    for (const index in matchedBets) {
      const matchedBet = matchedBets[index];
      if (moment(matchedBet.bet_a.conditions[0].pinnacle_event.starts).month() === month) {
        _matchedBetCount += 1;
        _matchedBetTotalWagered += +(matchedBet.bet_a.wager_amount);

        /*
        if (matchedBetIsMiddle(matchedBet)) {
          _middleBetCount += 1;

          if (matchedBet.bet_a.bet_status.name.toLowerCase() !== `open` && matchedBet.bet_b.bet_status.name.toLowerCase() !== `open`) {
            if (matchedBet.bet_a.bet_status.name.toLowerCase() !== `loss` && matchedBet.bet_b.bet_status.name.toLowerCase() !== `loss`) {
              if (matchedBet.bet_a.bet_status.name.toLowerCase() === 'half-win/half-push' && matchedBet.bet_b.bet_status.name.toLowerCase() !== 'half-loss/half-push') {
                _middlesHit += 1;
              } else if (matchedBet.bet_a.bet_status.name.toLowerCase() === 'half-loss/half-push' && matchedBet.bet_b.bet_status.name.toLowerCase() !== 'half-win/half-push') {
                _middlesHit += 1;
              } else if (matchedBet.bet_a.bet_status.name.toLowerCase() !== 'push/void' || matchedBet.bet_b.bet_status.name.toLowerCase() !== 'push/void') {
                _middlesHit += 1;
              }
            }
          }
        }
        */

        if (matchedBetIsMiddle(matchedBet)) {
          _middleBetCount += 1;
        }
        if (matchedBetWonBothSides(matchedBet) || matchedBetIsMiddleThatHit(matchedBet)) {
          _middlesHit += 1;
        }

        if (matchedBetIsFreeBet(matchedBet)) {
          _freeBetCount += 1;
          _freeBetsWagered += +(matchedBet.bet_a.wager_amount);
        } else {
          _realMoneyWagered += +(matchedBet.bet_a.wager_amount);
        }

        _realMoneyWagered += +(matchedBet.bet_b.wager_amount);

        if (matchedBet.bet_a.bet_status.name.toLowerCase() !== `open`) {
          _netWinnings += matchedBet.bet_a.net_winnings;
        }
        if (matchedBet.bet_b.bet_status.name.toLowerCase() !== `open`) {
          _netWinnings += matchedBet.bet_b.net_winnings;
        }
      }
    }

    setMatchedBetCount(_matchedBetCount);
    setMatchedBetTotalWagered(_matchedBetTotalWagered);
    setMiddleBetCount(_middleBetCount);
    setRealMoneyWagered(_realMoneyWagered);
    setFreeBetsWagered(_freeBetsWagered);
    setFreeBetCount(_freeBetCount);
    setNetWinnings(_netWinnings + _depositBonusMoneyAmount);
    setMiddleBetsHit(_middlesHit);

  }, [deposits, withdrawals, matchedBets, month])

  return (
    <div id='monthly-summary' {...rest}>
      <h1>{moment(`${month+1}`, 'M').format('MMMM')} Summary</h1>

      <div id={'monthly-summary--main'}>
        <div className={'monthly-summary--tile'}>
          <h1>Net Profit/Loss </h1>
          <h2 className={`${netWinnings >= 0 ? `positive_money` : `negative_money`}`}>{currencyFormatter.format(netWinnings)}</h2>
        </div>


        <div className={'monthly-summary--tile'}>
          <h1>Deposits</h1>
          <h2>{depositCount}</h2>
        </div>

        <div className={'monthly-summary--tile'}>
          <h1>Deposited</h1>
          <h2>{currencyFormatter.format(depositAmount)}</h2>
        </div>

        <div className={'monthly-summary--tile'}>
          <h1>Bonus Money Received</h1>
          <h2 className={depositBonusMoneyAmount > 0 ? `positive_money` : ``}>{currencyFormatter.format(depositBonusMoneyAmount)}</h2>
        </div>

        <div className={'monthly-summary--tile'}>
          <h1>Free-Bets  Received</h1>
          <h2 className={depositFreeBetsAmount > 0 ? `positive_money` : ``}>{currencyFormatter.format(depositFreeBetsAmount)}</h2>
        </div>


        <div className={'monthly-summary--tile'}>
          <h1>Withdrawals</h1>
          <h2>{withdrawalCount}</h2>
        </div>

        <div className={'monthly-summary--tile'}>
          <h1>Withdrawn</h1>
          <h2>{currencyFormatter.format(withdrawalAmount)}</h2>
        </div>


        <div className={'monthly-summary--tile'}>
          <h1>Matched Bets</h1>
          <h2>{matchedBetCount}</h2>
        </div>

        <div className={'monthly-summary--tile'}>
          <h1>Wagered</h1>
          <h2>{currencyFormatter.format(realMoneyWagered)}</h2>
        </div>


        <div className={'monthly-summary--tile'}>
          <h1>Middle Bets</h1>
          <h2>{middleBetCount}</h2>
        </div>

        <div className={'monthly-summary--tile'}>
          <h1>Middle Bets Hit</h1>
          <h2 className={middleBetsHit > 0 ? `positive_money` : ``}>{middleBetsHit}</h2>
        </div>


        <div className={'monthly-summary--tile'}>
          <h1>Free-Bet Conversions</h1>
          <h2>{freeBetCount}</h2>
        </div>

        <div className={'monthly-summary--tile'}>
          <h1>Free-Bets Wagered</h1>
          <h2>{currencyFormatter.format(freeBetsWagered)}</h2>
        </div>
      </div>
    </div>

  )

}


export default MonthlySummary;