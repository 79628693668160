import React, { useState, useEffect } from 'react';
import axios from 'axios';
import apiClient from "../APIClient";
import './BetsList.css';
import { currencyFormatter, descriptionForBet } from '../utils/BetUtils';
import BetTile from "./BetTile";
import { fetchBets, fetchBetStatuses } from "../utils/ApiUtils";

function BetsList() {
  const [bets, setBets] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: null,
  });

  // Add a new state for bet statuses
  const [betStatuses, setBetStatuses] = useState([]);


  useEffect(() => {
    fetchBets(setBets);
    fetchBetStatuses(setBetStatuses);
  }, []);

  // Add a new state for the updated bet status
  const [updatedStatus, setUpdatedStatus] = useState(null);

  // Add a function to handle bet status change
  const handleStatusChange = (betId, newStatus) => {
    setUpdatedStatus(newStatus);

    const updatedBetData = {
      bet_status: newStatus,
    };

    // Save the updated bet status
    apiClient
      .put(`/api/bet-status-update/${betId}/`, updatedBetData)
      .then((response) => {
        const updated_bet = response.data.bet;
        const related_bet = response.data.related_bet;

        const betToUpdate = bets.find((bet) => bet.id === betId);

        let index = bets.findIndex((bet) => bet.id === updated_bet.id);

        // Update the bets array with the updated bet object
        let updatedBets = [
          ...bets.slice(0, index),
          updated_bet,
          ...bets.slice(index + 1),
        ];

        if (related_bet) {
          index = bets.findIndex((bet) => bet.id === related_bet.id);
          updatedBets[index] = related_bet;
        }

        setBets(updatedBets);

      })
  };

  const sortBy = (key) => {
    let direction = 'ascending';

    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }

    const sortedBets = [...bets].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === 'ascending' ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });

    setBets(sortedBets);
    setSortConfig({ key, direction });
  };

  return (
    <div>
      <h1>Bets List</h1>
      <table id='bets-list'>
        <tbody>
          {bets.map((bet) => (
            <tr key={bet.id}>
              <td>
                <BetTile
                  bet={bet}
                />
              </td>
              <td>
                <div>
                  <select
                    value={bet.bet_status.id}
                    onChange={(e) => handleStatusChange(bet.id, e.target.value)}
                  >
                    {betStatuses.map((status) => (
                      <option key={status.id} value={status.id}>
                        {status.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className={bet.net_winnings > 0 ? 'net-winnings winnings' : bet.net_winnings < 0 ? 'net-winnings losses' : 'net-winnings'}>
                  {currencyFormatter.format(bet.net_winnings)}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default BetsList;
