import SearchableDropdown from "./SearchableDropdown";
import React from "react";
import { useState, useEffect } from 'react';
import {useParams} from "react-router-dom";
import MatchedBetCalculator from "./components/MatchedBetCalculator";
import axios from "axios";
import apiClient from "./APIClient";
import {fetchBetBonusTypes, fetchSportsbookSignups} from "./utils/ApiUtils";

const Calculator = () => {
  const params = useParams();
  const [sportsbookSignups, setSportsbookSignups] = useState([]);
  const [bet_bonus_types, setBetBonusTypes] = useState([]);

  useEffect(() => {
    //apiClient.get('/api/sportsbooks/').then((response) => setSportsbooks(response.data))
    fetchSportsbookSignups(setSportsbookSignups);
    fetchBetBonusTypes(setBetBonusTypes);
  }, []);

  if (!params.t || params.t === "matched") {
    return (
      <MatchedBetCalculator
        sportsbookSignups={sportsbookSignups}
        betBonusTypes={bet_bonus_types}
      />
    );
  };
};

export default Calculator;