import React, {useContext, useEffect, useRef} from 'react';
import { StatusMessageContext } from '../StatusMessageContext';

const StatusMessage = () => {
  const { statusMessages, clearStatusMessages } = useContext(StatusMessageContext);
  const timeoutRef = useRef(null);

  useEffect(() => {
    if (statusMessages && !timeoutRef.current) {
      timeoutRef.current = setTimeout(() => {
        clearStatusMessages();
        timeoutRef.current = null;
      }, 7500); // Auto-hide the message after 5 seconds
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
    };
  }, [statusMessages, clearStatusMessages]);

  if (!statusMessages) {
    return null;
  }


  const messages = statusMessages.map(m =>
    <div className={m.className}>{m.message}</div>
  );

  return (
    <div className="status-messages">
      {messages}
    </div>
  );
};

export default StatusMessage;