import React, {useContext, useEffect, useState} from "react";
import {AuthContext} from "../AuthContext";
import {Navigate, Link} from "react-router-dom";
import BetsCalendar from "./calendar/BetsCalendar";
import MatchedBetsList from "./MatchedBetsList/MatchedBetsList";
import {fetchDeposits, fetchMatchedBetsByDateRange, fetchSportsbookSignups, fetchWithdrawals} from "../utils/ApiUtils";
import moment from "moment";
import BetCalculator from "./calculator/BetCalculator";
import "./modal/Modal.css"
import "./Dashboard.css"
import MonthlySummary from "./MonthlySummary/MonthlySummary";
import SyncLoader from "react-spinners/SyncLoader";

const Dashboard = () => {
  let [activeStartDate, setActiveStartDate] = useState(new Date());
  let [date, setDate] = useState(new Date());
  let [matchedBets, setMatchedBets] = useState([]);
  let [calculatorVisible, setCalculatorVisible] = useState(false);
  const [deposits, setDeposits] = useState([]);
  const [withdrawals, setWithdrawals] = useState([]);
  const [sportsbookSignups, setSportsbookSignups] = useState([]);
  const [showBetsList, setShowBetsList] = useState(true);
  const [showMonthlySummary, setShowMonthlySummary] = useState(false);
  const [loadingCount, setLoadingCount] = useState(0);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    const now = new Date();

    addLoading();
    fetchDeposits( (b) => {
      setDeposits(b);
      removeLoading();
    }, (b) => {
      removeLoading();
    });
    addLoading();
    fetchWithdrawals( (b) => {
      setWithdrawals(b);
      removeLoading();
    }, (b) => {
      removeLoading();
    });
    addLoading();
    fetchSportsbookSignups( (b) => {
      setSportsbookSignups(b);
      removeLoading();
    }, (b) => {
      removeLoading();
    });
  }, []);

  const addLoading = () => {
    setLoadingCount((prevValue) => {
      return prevValue + 1;
    });
  }
  const removeLoading = () => {
    setLoadingCount((prevValue) => {
      return prevValue - 1;
    });
  }

  const [wager_a_data, setWagerAData] = useState({
      wager: 100,
      odds: '',
      bet_bonus_type: 0,
      payout: 200,
      risk_free_percentage_returned: 100,
      risk_free_max_value_returned: 0,
      risk_free_estimated_conversion_rate: 60,
      sportsbook_signup: null,
  });
  const [wager_b_data, setWagerBData] = useState({
      wager: 100,
      odds: '',
      bet_bonus_type: 0,
      payout: 200,
      risk_free_percentage_returned: 100,
      risk_free_max_value_returned: 0,
      risk_free_estimated_conversion_rate: 60,
      sportsbook_signup: null,
  });

  const _setWagerAData = (data) => {
    setWagerAData(data);
  }

  const dateSelected = (date, matchedBets) => {
    setShowMonthlySummary(false);
    setShowBetsList(true);
    setDate(date);
    //setMatchedBets(matchedBets);
  }

  const activeStartDateChanged = (newActiveStartDate, view) => {
    setActiveStartDate(newActiveStartDate);
  }

  useEffect(() => {
    updateMatchedBets(!initialized);
    setInitialized(true);
  }, [activeStartDate]);

  useEffect(() => {
    // Set up the interval
    const intervalId = setInterval(() => { updateMatchedBets(true); }, 60000);
    // Clear interval on component unmount
    return () => clearInterval(intervalId);

  }, []); // Empty dependency array ensures this runs once on mount

  const updateMatchedBets = (forSpecificDay) => {
    addLoading();
    let startDate = moment(activeStartDate).startOf('month').subtract(7, 'day').format('YYYY-MM-DD');
    let endDate = moment(activeStartDate).endOf('month').add(7, 'day').format('YYYY-MM-DD');
    if (forSpecificDay) {
      startDate = moment(date).format('YYYY-MM-DD'); //moment(activeStartDate).startOf('month').subtract(7, 'day').format('YYYY-MM-DD');
      endDate = moment(date).format('YYYY-MM-DD'); //moment(activeStartDate).endOf('month').add(7, 'day').format('YYYY-MM-DD');

      fetchMatchedBetsByDateRange(startDate, endDate, (updatedMatchedBets) => {

        setMatchedBets(prevMatchedBets => {
          if (prevMatchedBets.length === 0) {
            return updatedMatchedBets;
          }
          return prevMatchedBets.map(bet => {
            const updatedBet = updatedMatchedBets.find(uBet => uBet.id === bet.id);
            // If a matching updated bet is found, return it, otherwise return the original bet
            return updatedBet ? updatedBet : bet;
          });
        });
        removeLoading();
      }, (error) => {
        removeLoading();
      });
    } else {
      fetchMatchedBetsByDateRange(startDate, endDate, (updatedMatchedBets) => {
        setMatchedBets(updatedMatchedBets);
        removeLoading();
      }, (error) => {
        removeLoading();
      });
    }
  }


  const matchedBetAdded = (matchedBet) => {
    setMatchedBets([...matchedBets, matchedBet]);
  }

  const showCalculator = () => {
    setCalculatorVisible(true);
  }
  const hideModal = () => {
    setCalculatorVisible(false);
  }

  const _showBetsList = () => {
    setShowBetsList(true);
    setShowMonthlySummary(false);
  }

  const _showMonthlySummary = () => {
    setShowBetsList(false);
    setShowMonthlySummary(true);
  }

  return (
    <div>

      <BetsCalendar
        matchedBets={matchedBets}
        dateSelected={dateSelected}
        activeStartDateChanged={activeStartDateChanged}
      />

      <div className={'dashboard-bets-list-container'}>
        <div className={'navigation'}>
          <span className={'material-symbols-outlined clickable'}
            onClick={_showBetsList}
              data-tooltip-id="main-tooltip"
            data-tooltip-content={"Matched Bets List"}>
            format_list_bulleted
          </span>
          <span className={'material-symbols-outlined clickable'}
            onClick={_showMonthlySummary}
            data-tooltip-id="main-tooltip"
            data-tooltip-content={"Monthly Summary"}>
            monitoring
          </span>
          {sportsbookSignups.length > 0 &&
            <span>
              <span data-tooltip-id="main-tooltip"
                className={'material-symbols-outlined clickable'}
                  data-tooltip-content={"Calculator"}
                onClick={() => {showCalculator()}} >
                calculate
              </span>
            </span>
          }

          {loadingCount <= 0 &&
            <span className={'material-symbols-outlined clickable'}
              onClick={() => {updateMatchedBets(true); }}
              data-tooltip-id="main-tooltip"
              data-tooltip-content={"Refresh"}>
              refresh
            </span>
          }

          <span id={'loading-indicator'} style={{display: loadingCount > 0 ? 'inline' : 'none'}}>
            <SyncLoader
              size={5}
              color={'var(--header-color)'}
              loading={true}
              cssOverride={{position: 'relative', top: '2px'}}
              data-tooltip-id="main-tooltip"
              data-tooltip-content={"Loading..."}
              speedMultiplier={0.55}
              />
          </span>
        </div>

        <MonthlySummary
          deposits={deposits}
          withdrawals={withdrawals}
          sportsbookSignups={sportsbookSignups}
          matchedBets={matchedBets}
          month={moment(activeStartDate).month()}
          style={{display: showMonthlySummary ? 'block' : 'none'}}
          />

        <MatchedBetsList
          matchedBets={matchedBets}
          setMatchedBets={setMatchedBets}
          startDate={date}
          endDate={date}
          style={{display: showBetsList ? 'block' : 'none'}}
          />
      </div>

      {sportsbookSignups.length > 0 &&
        <div id={'floating-calculator-button'} className={'clickable'} onClick={() => {showCalculator()}} data-tooltip-id="main-tooltip" data-tooltip-content={"Calculator"}>
          <span className={'material-symbols-outlined md-36'}>
            calculate
          </span>
        </div>
      }

      <div className="modal-overlay" onMouseDown={hideModal} data-testid='modal' id={'bet-calculator-modal'} style={{ display: calculatorVisible ? 'flex' : 'none' }}>
        <div className="modal-content" onMouseDown={(e) => e.stopPropagation()}>
          <BetCalculator
            matchedBetAdded={matchedBetAdded}
            wager_a_data={wager_a_data}
            wager_b_data={wager_b_data}
            setWagerAData={_setWagerAData}
            setWagerBData={setWagerBData}
          />
        </div>
      </div>


    </div>
  )
}

export default Dashboard;