import betterbettor from '../images/betterbettor.png'
import React, {useContext} from 'react';
import {Link} from "react-router-dom";
import {AuthContext} from "../AuthContext";

const Header = () => {
  const { isAuthenticated, logoutUser } = useContext(AuthContext);


  return (
    <div id="header">
      <span id="logo">
        <Link to='/dashboard'>
          <img src={betterbettor} />
        </Link>
        <span id={'logo--text'}>
          Better Bettor
        </span>
      </span>

      <span id={'header-navigation'}>
        <span>
          <Link to='/dashboard'>
            <span data-tooltip-id="main-tooltip"
                  data-tooltip-content={"Dashboard"}
                  className={'material-symbols-outlined'}>
              calendar_month
            </span>
          </Link>
        </span>
        <span>
          <Link to='/sportsbook-signups'>
            <span data-tooltip-id="main-tooltip"
                  data-tooltip-content={"Sportsbook Signups"}
                  className={'material-symbols-outlined'}>
              library_books
            </span>
          </Link>
        </span>
        <span>
          <Link to='/banking'>
            <span data-tooltip-id="main-tooltip"
                  data-tooltip-content={"Banking"}
                  className={'material-symbols-outlined'}>
              account_balance
            </span>
          </Link>
        </span>
        {isAuthenticated &&
          <span>
            <span data-tooltip-id="main-tooltip"
                  data-tooltip-content={"Logout"}
                  className={'material-symbols-outlined clickable'}
                  onClick={logoutUser}>
              logout
            </span>
          </span>
        }
      </span>

    </div>
  )
}

export default Header;