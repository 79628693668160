import {
  descriptionForBetCondition,
  eventTitleForBet,
  dateTimeFormatted,
  currencyFormatter,
  matchedBet,
  matchedBetTypeDescription,
  betTypeDescription, friendlyTimeUntil
} from "../../utils/BetUtils";
import './MatchedBetBetTile.css'
import { useState, useEffect } from "react";
import {fetchBetConditionTypes} from "../../utils/ApiUtils";
const MatchedBetBetTile = ({bet, betConditionTypes}) => {
  const [bet_bonus_type_name, setBetBonusTypeName] = useState(null);
  //const [betConditionTypes, setBetConditionTypes] = useState([]);
  const [betConditionTypeName, setBetConditionTypeName] = useState('');

  useEffect(() => {
    if(!betConditionTypes) { return }

    const type = betConditionTypes.find((bct) => bct.id === bet.conditions[0].bet_condition_type);
    if (!type) { return }
    setBetConditionTypeName(type.name);
  }, [betConditionTypes]);

  return (
    <div className="matchedbet-bet-tile">
      <div className="matchedbet-bet-tile--title">
        {descriptionForBetCondition(bet.conditions[0], betConditionTypes)}
      </div>
      <div className="matchedbet-bet-tile--subtitle">{betConditionTypeName} - {bet.conditions[0].pinnacle_period && bet.conditions[0].pinnacle_period.description}</div>
      <div className="matchedbet-bet-tile--bet-details">
        <div className="matchedbet-bet-tile--sportsbook">{bet.sportsbook_signup.label}</div>
        <div className="matchedbet-bet-tile--odds">{bet.odds}</div>
        <div className="matchedbet-bet-tile--wager">
          {bet.bet_bonus_type && bet.bet_bonus_type.id !== 0 &&
            <span className="material-symbols-outlined md-18" data-tooltip-id="main-tooltip" data-tooltip-content={betTypeDescription(bet)}>redeem</span>
          }
          <span>{currencyFormatter.format(bet.wager_amount)}</span>
        </div>
      </div>

      {bet.bet_status.name.toLowerCase() === 'win' &&
        <div className={'matchedbet-tile--bet-status won'} data-tooltip-id="main-tooltip" data-tooltip-content={'This bet won'}>&nbsp;</div>
      }
      {bet.bet_status.name.toLowerCase() === 'loss' &&
        <div className={'matchedbet-tile--bet-status lost'} data-tooltip-id="main-tooltip" data-tooltip-content={'This bet lost'}>&nbsp;</div>
      }
      {bet.bet_status.name.toLowerCase() === 'push/void' &&
        <div className={'matchedbet-tile--bet-status pushed'} data-tooltip-id="main-tooltip" data-tooltip-content={'This bet pushed/voided'}>&nbsp;</div>
      }
      {bet.bet_status.name.toLowerCase() === 'open' &&
        <div className={'matchedbet-tile--bet-status'} data-tooltip-id="main-tooltip" data-tooltip-content={'This bet is unsettled'}>&nbsp;</div>
      }
    </div>
  )
}

export default MatchedBetBetTile;