import React, {useContext, useState} from 'react';
import {BrowserRouter, Route, Routes, useLocation} from 'react-router-dom';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import './App.css';
import AppRouter from "./AppRouter";
import Bet from "./Bet";
import Calculator from "./Calculator";
import Register from "./components/Register";
import Login from "./components/Login";
import ProtectedComponent from './ProtectedComponent';
import { AuthContextProvider, AuthContext } from './AuthContext';
import LandingPage from "./components/LandingPage";
import Header from "./components/Header";
import {StatusMessageProvider} from "./StatusMessageContext";
import StatusMessage from "./components/StatusMessage";
import Dashboard from "./components/Dashboard"
import Footer from "./components/Footer"
import SportsbookSignups from "./components/SportsbookSignups"
import BetsList from "./components/BetsList";
import Banking from "./components/Banking";
import BetTracker from "./components/BetTracker";
import {ModalProvider} from "./components/modal/ModalContext";
import Modal from "./components/modal/Modal";
import BetCalculator from "./components/calculator/BetCalculator";
import BetsCalendar from "./components/calendar/BetsCalendar";
/*
import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import LandingPage from './components/LandingPage';
import LoginPage from './components/LoginPage';
import RegisterPage from './components/RegisterPage';
import Dashboard from './components/Dashboard';
*/

function App() {

  return (
    <StatusMessageProvider>
      <AuthContextProvider>
        <ModalProvider>
          <BrowserRouter>
            <Header />
              <div id="body">
                <StatusMessage />
                  <Routes>
                    <Route path="" element={<LandingPage />} />

                    <Route path="/login" element={<Login />} />
                    <Route path="/register" element={<Register />} />

                    <Route path="/dashboard" element={<ProtectedComponent component={Dashboard} />} />
                    <Route path="/sportsbook-signups" element={<ProtectedComponent component={SportsbookSignups} />} />
                    <Route path="/:id" element={<ProtectedComponent component={Bet}  />} />
                    <Route path="/calculator/:t" element={<ProtectedComponent component={Calculator}  />} />
                    <Route path="/calculator2/:t" element={<ProtectedComponent component={BetCalculator}  />} />
                    <Route path="/banking" element={<ProtectedComponent component={Banking}  />} />
                    <Route path="/tracker/:start_date?/:end_date?" element={<ProtectedComponent component={BetTracker}  />} />

                    <Route path="/calendar" element={<ProtectedComponent component={BetsCalendar}  />} />

                  </Routes>
                <ReactTooltip place="top" type="dark" effect="solid" id={'main-tooltip'}/>
              </div>
            <Footer />
            <Modal />
          </BrowserRouter>
        </ModalProvider>
      </AuthContextProvider>
    </StatusMessageProvider>
  );
}

export default App;