import {useEffect} from "react";
import apiClient from "../APIClient";
import SearchableDropdown from "../SearchableDropdown";
import {useState} from "react";
import {fetchMoneyTypes, fetchSportsbookSignups} from "../utils/ApiUtils";
import './TransactionItemForm.css'
import {useModal} from "./modal/ModalContext";

const TransactionItemForm = ({itemAdded, item_type_name, sportsbook_signups, money_types}) => {
  const getInputDateTime = () => {
    return new Date().toISOString().slice(0, 10);
  }

  //const [sportsbook_signups, setSportsbookSignups] = useState([]);
  const [selected_sportsbook_signup, setSelectedSportsbookSignup] = useState(null);
  //const [money_types, setMoneyTypes] = useState([]);
  const [money_type, setMoneyType] = useState('');
  const [amount, setAmount] = useState('');
  const [notes, setNotes] = useState('');
  const [date, setDate] = useState(() => getInputDateTime());
  const { modalContent, hideModal } = useModal();

  /*

  useEffect(() => {
    fetchSportsbookSignups(setSportsbookSignups);
    fetchMoneyTypes(setMoneyTypes);
  }, [])
  */

  const addItem = () => {
    if (!selected_sportsbook_signup || !amount) {
      console.log('Missing required fields');
      return;
    }

    const _date = new Date(date);
    _date.setMinutes(_date.getMinutes() + _date.getTimezoneOffset());
    const data = {
      sportsbook_signup_id: selected_sportsbook_signup.id,
      amount: amount,
      notes: notes,
      date: _date,
      money_type_id: money_type.id
    }
    apiClient
      .post(`/api/${item_type_name}s/`, data)
      .then((response) => {
        console.log(response);
        itemAdded(response.data);
      })
      .catch((error) => {
        console.error('Error adding item:', error);
      });

    hideModal();
  }

  const friendlyItemName = () => {
    return item_type_name.charAt(0).toUpperCase() + item_type_name.slice(1).toLowerCase();
  }

  return (
    <div className={'transaction-item-form'}>
      <h2>Add {friendlyItemName()}</h2>
      <div>
        <SearchableDropdown
          value={selected_sportsbook_signup}
          options={sportsbook_signups}
          onChange={setSelectedSportsbookSignup}
          placeholder="Select a Sportsbook..."
          />
      </div>
      <div>
        <SearchableDropdown
          value={money_type}
          options={money_types}
          onChange={setMoneyType}
          placeholder={`Type of ${friendlyItemName()}...`}
          data-testid='money-type-dropdown'
          />
      </div>
      <div>
        <input type={"number"} placeholder={`${friendlyItemName()} Amount`} value={amount} onChange={(e) => { setAmount(e.target.value) }} />
        {selected_sportsbook_signup &&
          <span>
            {selected_sportsbook_signup.currency.code}
          </span>
        }
      </div>
      <div>
        <input type={'date'} placeholder={`Date`} value={date} onChange={(e) => { setDate(e.target.value) }} />
      </div>
      <div>
        <textarea placeholder={`Notes`} value={notes} onChange={(e) => { setNotes(e.target.value) }} />
      </div>
      <div>
        <input type={"button"} value={`Add ${friendlyItemName()}`} onClick={addItem} />
        <input type={"button"} value={`Cancel`} onClick={hideModal} />
      </div>
    </div>
  )
}

export default TransactionItemForm;