export const InputError = ({message}) => {
  return (
    <div className="input-error">{message}</div>
  )
}


export function findInputError(errors, name) {
  const filtered = Object.keys(errors)
    .filter(key => key === name)
    .reduce((cur, key) => {
        return Object.assign(cur, { error: errors[key]});
    }, {});
  return filtered;
}

export function isFormValid(err) {
  return Object.keys(err).length === 0;
}
