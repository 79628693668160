import React, { createContext, useState, useEffect, useContext } from 'react';
import axios from "axios";
import apiClient from "./APIClient";
import {StatusMessageContext} from "./StatusMessageContext";
const AuthContext = createContext();

const AuthContextProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [login_failed, setLoginFailed] = useState(null);
  const { addStatusMessage } = useContext(StatusMessageContext);

  useEffect(() => {
    // Check for existing user session, e.g., using a token stored in localStorage
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      setCurrentUser(JSON.parse(storedUser));
      setIsAuthenticated(true);
    }
  }, []);

  const loginUser = (username, password) => {
      // Replace this URL with the actual API endpoint for user login
      const apiUrl = '/api/login';


      apiClient.post(apiUrl,{
          username,
          password,
        }).then((response) => {

          if (!response.status === 200) {
            addStatusMessage({"className": "status-error", "message": `There was a problem during login.`});
            logoutUser();
            return;
          }

          const data = response.data;

          setCurrentUser(data.user);
          setIsAuthenticated(true);
          // Store the token or user data in localStorage for persisting the session
          localStorage.setItem('user', JSON.stringify(data.user));
          localStorage.setItem('token', data.token);
        })
        .catch((error) => {
          console.error('Error during login:', error);
          // Handle login errors, e.g., show a notification or update the UI
          addStatusMessage({"className": "status-error", "message": `There was a problem during login.  ${error.response.data.error}`});
          logoutUser();
        });
  };

  const logoutUser = () => {
    setCurrentUser(null);
    setIsAuthenticated(false);
    localStorage.removeItem('user');
    localStorage.removeItem('token');
  };

  const registerUser = (user) => {
    // Perform the actual registration logic here, e.g., send a request to your API
    //setCurrentUser(user);
    //setIsAuthenticated(true);
    //localStorage.setItem('user', JSON.stringify(user));

  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        currentUser,
        loginUser,
        logoutUser,
        registerUser
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContextProvider, AuthContext };