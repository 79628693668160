import React from "react";
import { useState} from "react";
import CurrencyInput from "../CurrencyInput";

const RiskFreeBetAdvancedOptions = ({risk_free_percentage_returned, risk_free_max_value_returned, risk_free_estimated_conversion_rate, setRiskFreePercentageReturned, setRiskFreeMaxValueReturned, setRiskFreeEstimatedConversionRate, currency_symbol, currency_code,hideAdvancedOptions}) => {
  const [localPercentageReturned, setLocalPercentageReturned] = useState(risk_free_percentage_returned);
  const [localMaxValueReturned, setLocalMaxValueReturned] = useState(risk_free_max_value_returned);
  const [localEstimatedConversionRate, setLocalEstimatedConversionRate] = useState(risk_free_estimated_conversion_rate);

  const _setRiskFreePercentageReturned = (value) => {
    setRiskFreePercentageReturned(value);
    setLocalPercentageReturned(value);
  }
  const _setRiskFreeEstimatedConversionRate = (value) => {
    setRiskFreeEstimatedConversionRate(value);
    setLocalEstimatedConversionRate(value);
  }

  const _setRiskFreeMaxValueReturned = (value) => {
    if (value === '') {
      value = 0;
    }
    setRiskFreeMaxValueReturned(value);
    setLocalMaxValueReturned(value);
  }

  return (
    <div>
      <div>
        <label>
          <div>Percentage of stake returned on loss:</div>
          <input className={'percentage'} type={'number'} value={localPercentageReturned} onChange={(e) => _setRiskFreePercentageReturned(e.target.value)} />%
        </label>
      </div>
      <div>
        <label>
          <div>Maximum stake returned on loss:</div>
          <div className={'small-info'}>(0 for unlimited)</div>
          <CurrencyInput
            currency_symbol={currency_symbol}
            currency_code={currency_code}
            value={localMaxValueReturned}
            onChange={(e) => _setRiskFreeMaxValueReturned(e.target.value)}
            />
        </label>
      </div>
      <div>
        <label>
          <div>Estimated conversion of Free Bets/Bonus Money on loss:</div>
          <input className={'percentage'} type={'number'} value={localEstimatedConversionRate} onChange={(e) => _setRiskFreeEstimatedConversionRate(e.target.value)} />%
        </label>
      </div>
      <div>
        <button onClick={() => hideAdvancedOptions()}>Hide</button>
      </div>
    </div>
  )
}

export default RiskFreeBetAdvancedOptions;