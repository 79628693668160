import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import SearchableDropdown from "./SearchableDropdown";
import DateTimePicker from 'react-datetime-picker'
import './App.css';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import BetForm from "./BetForm";
import EventSelector from "./components/EventSelector";
import apiClient from "./APIClient";

const BetCondition = ({id, removeFunction, collectValues, condition_data}) => {
  const [bet_status, setBetStatus] = useState('');
  const [event_date, setEventDate] = useState(new Date());
  const [condition, setCondition] = useState('');
  const [league, setLeague] = useState('');
  const [sport, setSport] = useState('');
  const [odds, setOdds] = useState('');
  const [bet_condition_type, setBetConditionType] = useState(0);
  const [initialized, setInitialized] = useState(false);

  const [league_search_options, setLeagueSearchOptions] = useState([]);

  const [betConditionTypes, setBetConditionTypes] = useState([]);
  const [betStatuses, setBetStatuses] = useState([]);
  const [leagues, setLeagues] = useState([]);
  const [sports, setSports] = useState([]);

  useEffect(() => {
    apiClient.get('/api/bet-condition-types/').then((response) => setBetConditionTypes(response.data));
    apiClient.get('/api/bet-statuses/').then((response) => setBetStatuses(response.data));
    apiClient.get('/api/leagues/').then((response) => setLeagues(response.data));
    apiClient.get('/api/sports/').then((response) => setSports(response.data));
  }, []);

  useEffect(() => {
    if (leagues.length > 0 && sports.length > 0) {
      //setSport(leagues[0].sport);

      var options = [];
      for (var l in leagues) {
          options.push({'id': leagues[l].id, 'label': `${sports.filter((s) => { return s.id === leagues[l].sport })[0].name} - ${leagues[l].name} (${leagues[l].abbreviation})`});
      }
      setLeagueSearchOptions(options);
      //setLeague(leagues[0].id);
    }
  }, [leagues,sports]);

  useEffect(() => {

  }, [league_search_options]);

  useEffect(() => {
    if (betConditionTypes.length > 0) {
      setBetConditionType(betConditionTypes[0].id);
    }
  }, [betConditionTypes]);

  useEffect(() => {
    // Call the collectValues callback function with the current state values and the current condition ID
    collectValues(id, {event_date, condition, league, sport, odds, bet_condition_type, bet_status});
  }, [event_date, condition, league, sport, odds, bet_condition_type, bet_status]);

  //Custom setLeague function to also automatically set the Sport
  const _setLeague = (value) => {
      setLeague(value);
      const foundLeague = leagues.find((l) => {
          return l.id == value;
      })
      //setSport(foundLeague.sport);
  }

  useEffect(() => {
      if (condition_data && !initialized) {
          if (!isNaN(+condition_data.odds)) {
            setOdds(condition_data.odds);
            setCondition(condition_data.condition);
            setEventDate(condition_data.event_date);
            setLeague(condition_data.league);
            setBetStatus(condition_data.bet_status);
            //setSport(condition_data.sport);
            setBetConditionType(condition_data.bet_condition_type);
          }
        setInitialized(true);
      }
  }, [condition_data]);

  const _removeFunction = () => {
      removeFunction(id);
  }

  return (
      <div className='bet-condition-container'>
        <div>
            <label>
              Find Event:
              <EventSelector
                  setEventDate={setEventDate}
                  setLeague={setLeague}
                  setCondition={setCondition}
              />
            </label>
        </div>
        <div>
          <label>
            Bet Type:
            <select value={bet_condition_type} onChange={(e) => setBetConditionType(e.target.value)}>
              {betConditionTypes.map((bct) => (
                  <option key={bct.id} value={bct.id}>
                    {bct.name}
                  </option>
              ))}
            </select>
          </label>
        </div>
        <div>
          <label>
            Odds:
            <input type="number" step="0.01" value={odds} onChange={(e) => setOdds(e.target.value)}/>
          </label>
        </div>

        <div>
          <label>
            Event Date:
            <DateTimePicker value={event_date} onChange={setEventDate} clearIcon={null} disableClock={true}/>
          </label>
        </div>

          <div>
            <label>
              League:
              <SearchableDropdown
                  options={league_search_options}
                  value={league}
                  initialValue={league}
                  onChange={(selected) => _setLeague(selected.id)}
              />
            </label>
          </div>
          {/*
        <div>
          <label>
            League:
            <select value={league} onChange={(e) => _setLeague(e.target.value)}>
              {leagues.map((l) => (
                  <option key={l.id} value={l.id}>
                    {l.name}
                  </option>
              ))}
            </select>
          </label>
        </div>
        <div>
          <label>
            Sport:
            <select value={sport} onChange={(e) => setSport(e.target.value)}>
              {sports.map((s) => (
                  <option key={s.id} value={s.id}>
                    {s.name}
                  </option>
              ))}
            </select>
          </label>
        </div>
        */}
        <div>
          <label>
            Bet Condition:
            <input type="text" value={condition} onChange={(e) => setCondition(e.target.value)}/>
          </label>
        </div>
      <div>
          <input type='button' value="Remove" onClick={_removeFunction} />
      </div>

      <div>
        <label>
          Condition Status:
          <select value={bet_status} onChange={(e) => setBetStatus(e.target.value)}>
            {betStatuses.map((bs) => (
              <option key={bs.id} value={bs.id}>
                {bs.name}
              </option>
            ))}
          </select>
        </label>
      </div>
    </div>

  );
};

export default BetCondition;