import React, {useContext, useEffect, useState} from 'react';
import { Route, Navigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';

const ProtectedComponent = ({ component: Component, unauthorized_redirect_url='/' }) => {
  const [rendered, setRendered] = useState(false);
  const { isAuthenticated } = useContext(AuthContext);
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    setRendered(true);
  }, []);


  useEffect(() => {
    if (rendered && !isAuthenticated) {
      setRedirect(true);
    }
  }, [rendered, isAuthenticated]);

  if (redirect) {
    return (
      <Navigate to={unauthorized_redirect_url}/>
    )
  } else {
    return (
      <Component />
    );
  }
};

export default ProtectedComponent;