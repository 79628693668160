import {useContext} from "react";
import {AuthContext} from "../AuthContext";
import {Navigate} from "react-router-dom";



const LandingPage = () => {
  const { isAuthenticated } = useContext(AuthContext);

  if (isAuthenticated) {
    return (
      <Navigate to={{pathname: '/dashboard', state: { from: '' }}} />
    )
  } else {
    return (
      <div>
        <a href="/login">Login</a> -
        <a href="/register">Register</a>
      </div>
    );
  }
}

export default LandingPage;