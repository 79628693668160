import React, {useEffect, useState} from "react";
import './MatchedBetPayoutSummary.css'
import {betBonusTypeIdIsFreeBet, betBonusTypeIdIsRiskFreeBet} from "../utils/BetUtils";

const MatchedBetPayoutSummary = ({wagers_data, betBonusTypes}) => {
  const [total_wager, setTotalWager] = useState(0);
  const [mismatched_currency, setMismatchedCurrency] = useState(false);
  const [mismatched_profit_loss, setMismatchedProfitLoss] = useState(false);

  const wager_a_data = () => {
    return wagers_data[0];
  }
  const wager_b_data = () => {
    return wagers_data[1];
  }
  const mismatchedCurrency = () => {
    return wager_a_data().sportsbook_signup.currency !== wager_b_data().sportsbook_signup.currency;
  };

  const mismatchedProfitLoss = () => {
    const total_wager = totalWager();
    return (
      betAWinsProfit() !== betBWinsProfit()
    )
    /*return (
      (wager_a_data().payout - total_wager).toFixed(2) !==
      (((wager_b_data().payout + expectedBonusConverionOnALoss()) / wager_b_data().exchange_rate) - total_wager).toFixed(2)
    );

     */
  };

  const expectedBonusConverionOnALoss = () => {
    if (betBonusTypeIdIsRiskFreeBet(wagers_data[0].bet_bonus_type)) {
      return Math.min(wager_a_data().wager * wager_a_data().risk_free_percentage_returned, wager_a_data().risk_free_max_value_returned === 0 ? wager_a_data().wager * wager_a_data().risk_free_percentage_returned : wager_a_data().risk_free_max_value_returned) * wager_a_data().risk_free_estimated_conversion_rate;
    }
    return 0;
  }

  const totalWager = (exchange_rate = 1.0) => {
    const ret = () => {
      return +(wager_a_data().wager) + (+(wager_b_data().wager) / wager_b_data().exchange_rate);
    };
    return +(ret()).toFixed(2);
  };

  const totalRealMoneyWager = (exchange_rate = 1.0) => {
    return ((totalWager() - totalFreeBetWager()) * exchange_rate).toFixed(2);
  };

  const totalRiskFreeWager = () => {
    const exchange_rate = wager_a_data().exchange_rate || 1.0;
    if (betBonusTypeIdIsRiskFreeBet(wagers_data[0].bet_bonus_type)) {
      return wager_a_data().wager;
    }
    return 0;
  };

  const totalFreeBetWager = () => {

    const exchange_rate = wager_b_data().exchange_rate || 1.0;
    const bbt = betBonusTypes.find((bbt) => bbt.id === +wagers_data[0].bet_bonus_type);
    const bbt_name = bbt.name.toLowerCase();
    const bbtb = betBonusTypes.find((bbt) => bbt.id === +wagers_data[1].bet_bonus_type);
    const bbtb_name = bbtb.name.toLowerCase();
    const ret = () => {
      let wager = 0.0;
      if (bbt_name === "Free Bet (Stake not returned)".toLowerCase()) {
        wager += +(wager_a_data().wager);
      }
      if (bbtb_name === "Free Bet (Stake not returned)".toLowerCase()) {
        wager += +(wager_b_data().wager) / exchange_rate;
      }
      return wager;
    };
    return (+ret()).toFixed(2);
  };

  const betAWinsProfit = (exchange_rate = 1.0) => {
    return (
      (+(wager_a_data().payout) - totalRealMoneyWager()) * exchange_rate
    ).toFixed(2);
  };

  const futureABonusConversion = () => {
    if (betBonusTypeIdIsRiskFreeBet(wagers_data[0].bet_bonus_type)) {
      return Math.min(wagers_data[0].wager * (wagers_data[0].risk_free_percentage_returned / 100.0), wagers_data[0].risk_free_max_value_returned=== 0 ? wagers_data[0].wager * (wagers_data[0].risk_free_percentage_returned / 100.0) : wagers_data[0].risk_free_max_value_returned) * (wagers_data[0].risk_free_estimated_conversion_rate / 100.0);
    }
    return 0;
  }

  const betBWinsProfit = (exchange_rate = 1.0) => {
    return (
      (((+(wager_b_data().payout / wager_b_data().exchange_rate)) - totalRealMoneyWager()) + futureABonusConversion()) * exchange_rate
    ).toFixed(2);
  };

  useEffect(() => {
    if (!(wagers_data[0].sportsbook_signup && wagers_data[1].sportsbook_signup)) { return }
    const newTotalWager = (+(wager_a_data().wager) + +(wager_b_data().wager / wager_b_data().exchange_rate)).toFixed(2);
    setTotalWager(newTotalWager);
    setMismatchedCurrency(wager_a_data().sportsbook_signup.currency !== wager_b_data().sportsbook_signup.currency);
    setMismatchedProfitLoss((wager_a_data().payout - newTotalWager).toFixed(2) !== ((wager_b_data().payout / wager_b_data().exchange_rate) - newTotalWager).toFixed(2));
  }, [wagers_data])

  if (wager_a_data().sportsbook_signup && wager_b_data().sportsbook_signup) {
    const bbt = betBonusTypes.find((bbt) => bbt.id === +(wagers_data[0].bet_bonus_type));
    const bbt_name = bbt.name.toLowerCase();
    const bbtb = betBonusTypes.find((bbt) => bbt.id === +(wagers_data[1].bet_bonus_type));
    const bbtb_name = bbt.name.toLowerCase();

    return (
      <table className='matched-bet-payout-summary'>
        <thead>
        <tr>
          <td colSpan='3'>
            <div className='summary-header'>COMBINED WAGER</div>
            <div>
              {(bbt_name === "Free Bet (Stake not returned)".toLowerCase()) &&
                <div>
                  <div>${totalFreeBetWager()} {wager_a_data().sportsbook_signup.currency} Free Bet</div>
                  {mismatched_currency &&
                    <div className='secondary-currency-amount'>${totalFreeBetWager(wager_a_data().exchange_rate)} {wager_b_data().sportsbook_signup.currency}</div>
                  }
                  <div>+</div>
                  <div>${totalRealMoneyWager()} {wager_a_data().sportsbook_signup.currency} Real Money</div>
                  {mismatched_currency &&
                    <div className='secondary-currency-amount'>${totalRealMoneyWager(wager_a_data().exchange_rate)} {wager_b_data().sportsbook_signup.currency}</div>
                  }
                </div>
              }
              {(bbt_name !== "Free Bet (Stake not returned)".toLowerCase()) &&
                <div>
                  <div>${totalRealMoneyWager()} {wager_a_data().sportsbook_signup.currency}</div>
                  {mismatched_currency &&
                    <div className='secondary-currency-amount'>${totalRealMoneyWager(wager_a_data().exchange_rate)} {wager_b_data().sportsbook_signup.currency}</div>
                  }
                </div>
              }
            </div>
          </td>
        </tr>
        </thead>

        <tbody>
        <tr>
          <td colSpan='3'>
            <div className='summary-header'>PROFIT/LOSS</div>
          </td>
        </tr>
        {mismatchedProfitLoss() &&
        <tr>
          <td>
            <div className='summary-header'>Bet A wins:</div>
            <div>
              <div>
                ${betAWinsProfit()} {wager_a_data().sportsbook_signup.currency}
              </div>
              {mismatched_currency &&
                <div className='secondary-currency-amount'>${betAWinsProfit(wager_a_data().exchange_rate)} {wager_b_data().sportsbook_signup.currency}</div>
              }
              {+(wager_a_data().bet_bonus_type) === 0 && totalRealMoneyWager() > 0 &&
                <div className={'secondary-currency-amount'}>{(betAWinsProfit(wager_b_data().exchange_rate) / totalRealMoneyWager() * 100).toFixed(2)} %</div>
              }
            </div>
            {(bbt_name === "Free Bet (Stake not returned)".toLowerCase() || bbtb_name === "Free Bet (Stake not returned)".toLowerCase()) &&
              <div>
                <div className='summary-header'>Free-Bet Conversion Rate</div>
                <div>
                  {totalRealMoneyWager() == 0 &&
                    <div>Too much free money to calculate</div>
                  }

                  {totalRealMoneyWager() > 0 &&
                    <div>{(betAWinsProfit() / totalFreeBetWager() * 100).toFixed(2)} %</div>
                  }
                </div>
              </div>
            }
            {(bbt_name === "Risk-Free to Free Bet".toLowerCase() || bbtb_name === "Risk-Free to Bonus Money".toLowerCase()) &&
              <div>
                <div className='summary-header'>Risk-Free Conversion Rate</div>
                <div>
                  {totalRiskFreeWager() === 0 &&
                    <div>Too much free money to calculate</div>
                  }

                  {totalRiskFreeWager() > 0 &&
                    <div>{(betAWinsProfit() / totalRiskFreeWager() * 100).toFixed(2)} %</div>
                  }
                </div>
              </div>
            }
          </td>
          {Math.abs(betAWinsProfit() - betBWinsProfit()) > 10 &&
            <td style={{'width': '0', 'color': 'red'}}>
                <span className="material-symbols-outlined md-36" data-tooltip-id="main-tooltip" data-tooltip-content="Profit is mismatched!">
                  warning
                </span>
            </td>
          }
          <td>
            <div className='summary-header'>Bet B wins:</div>
            <div>
              <div>
                ${betBWinsProfit()} {wager_a_data().sportsbook_signup.currency}
              </div>
              {mismatched_currency &&
                <div className='secondary-currency-amount'>${betBWinsProfit(wager_b_data().exchange_rate)} {wager_b_data().sportsbook_signup.currency}</div>
              }
              {+(wager_a_data().bet_bonus_type) === 0 && totalRealMoneyWager() > 0 &&
                <div className={'secondary-currency-amount'}>{(betBWinsProfit(wager_b_data().exchange_rate) / totalRealMoneyWager() * 100).toFixed(2)} %</div>
              }
            </div>
            {(bbt_name === "Free Bet (Stake not returned)".toLowerCase() || bbtb_name === "Free Bet (Stake not returned)".toLowerCase()) &&
              <div>
                <div className='summary-header'>Free-Bet Conversion Rate</div>
                <div>
                  {totalRealMoneyWager() == 0 &&
                    <div>Too much free money to calculate</div>
                  }

                  {totalFreeBetWager() > 0 &&
                    <div>{(betBWinsProfit() / totalFreeBetWager() * 100).toFixed(2)} %</div>
                  }
                </div>
              </div>
            }
            {(bbt_name === "Risk-Free to Free Bet".toLowerCase() || bbtb_name === "Risk-Free to Bonus Money".toLowerCase()) &&
              <div>
                <div className='summary-header'>Risk-Free Conversion Rate</div>
                <div>
                  {totalRiskFreeWager() == 0 &&
                    <div>Too much free money to calculate</div>
                  }

                  {totalRiskFreeWager() > 0 &&
                    <div>{(betBWinsProfit() / totalRiskFreeWager() * 100).toFixed(2)} %</div>
                  }
                </div>
              </div>
            }
          </td>
        </tr>
        }
        {!mismatchedProfitLoss() &&
          <tr>
            <td>
              <div>
                <div>
                  ${betAWinsProfit()} {wager_a_data().sportsbook_signup.currency}
                </div>
                {mismatched_currency &&
                  <div className='secondary-currency-amount'>${betAWinsProfit(wager_a_data().exchange_rate)} {wager_b_data().sportsbook_signup.currency}</div>
                }
                {+(wager_a_data().bet_bonus_type) === 0 && totalRealMoneyWager() > 0 &&
                  <div className={'secondary-currency-amount'}>{(betBWinsProfit(wager_b_data().exchange_rate) / totalRealMoneyWager() * 100).toFixed(2)} %</div>
                }
                {(bbt_name === "Risk-Free to Free Bet".toLowerCase() || bbtb_name === "Risk-Free to Bonus Money".toLowerCase()) &&
                  <div>
                    <div className='summary-header'>Risk-Free Conversion Rate</div>
                    <div>
                      {totalRiskFreeWager() == 0 &&
                        <div>Too much free money to calculate</div>
                      }

                      {totalRiskFreeWager() > 0 &&
                        <div>{(betAWinsProfit() / totalRiskFreeWager() * 100).toFixed(2)} %</div>
                      }
                    </div>
                  </div>
                }
              </div>
            </td>
          </tr>
        }
        {!mismatchedProfitLoss() &&
          <tr>
            <td>
              {(bbt_name === "Free Bet (Stake not returned)".toLowerCase() || bbtb_name === "Free Bet (Stake not returned)".toLowerCase()) &&
                <div>
                  <div className='summary-header'>Free-Bet Conversion Rate</div>
                  <div>
                    {totalRealMoneyWager() == 0 &&
                      <div>Too much free money to calculate</div>
                    }

                    {totalRealMoneyWager() > 0 &&
                      <div>{(betAWinsProfit() / totalFreeBetWager() * 100).toFixed(2)} %</div>
                    }
                  </div>
                </div>
              }
            </td>
          </tr>
        }

        </tbody>

      </table>
    )
  }
}

export default MatchedBetPayoutSummary;