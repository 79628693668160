
import { useModal } from './modal/ModalContext';
import BetTile from "./BetTile";
import './ConfirmMatchedBetsDeletion.css'

const ConfirmMatchedBetsDeletion = ({matchedBet, deleteMatchedBet}) => {
  const { hideModal } = useModal();

  const _deleteMatchedBet = () => {
    deleteMatchedBet(matchedBet);
    hideModal();
  }

  return (
    <div className={'confirm-matched-bets-deletion'}>
      <h2>
        Are you sure you want to delete this matched bet?
      </h2>
      <p>Both bets below will be permanently removed from your tracker.</p>
      <div>
        <BetTile bet={matchedBet.bet_a} />
        <BetTile bet={matchedBet.bet_b} />
      </div>
      <div>
        <input type={'button'} value={'Yes, delete this matched bet'} onClick={_deleteMatchedBet}/>
        <input type={'button'} value={'No, never-mind'} onClick={hideModal}/>
      </div>
    </div>
  )
}
export default ConfirmMatchedBetsDeletion;