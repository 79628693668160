import TransactionItemForm from './TransactionItemForm';
import React, { useState, useEffect } from 'react';
import apiClient from "../APIClient";
import {useModal} from "./modal/ModalContext";
import {currencyFormatter} from "../utils/BetUtils";
import {fetchMoneyTypes, fetchSportsbookSignups} from "../utils/ApiUtils";
const TransactionList = ({items, setItems, itemAdded, item_type_name}) => {
  const { showModal } = useModal();
  const [sportsbook_signups, setSportsbookSignups] = useState([]);
  const [money_types, setMoneyTypes] = useState([]);

  const removeItem = (id) => {
    apiClient.delete(`/api/${item_type_name}s/${id}`).then((response) => {
      apiClient.get(`/api/${item_type_name}s/`).then((response) => setItems(response.data))
    });
  }

  const addItemForm = () => {
    showModal(<TransactionItemForm
      sportsbook_signups={sportsbook_signups}
      money_types={money_types}
      item_type_name={item_type_name}
      setItems={setItems}
      itemAdded={itemAdded}
    />);
  }

  useEffect(() => {
    fetchSportsbookSignups(setSportsbookSignups);
    fetchMoneyTypes(setMoneyTypes);
  }, [])


  return (
    <div>
      <h1>{item_type_name.charAt(0).toUpperCase() + item_type_name.slice(1).toLowerCase()} List</h1>
      <table>
        <thead>
          <tr>
            <th>Amount</th>
            <th>Date</th>
            <th>Sportsbook</th>
            <th>Type</th>
            <th>Notes</th>
            <th>Remove</th>
          </tr>
        </thead>
        <tbody>
          {items && items.map((item) => (
            item &&
            <tr key={item.id}>
              <td>{currencyFormatter.format(item.amount)}</td>
              <td>{new Date(item.date).toLocaleDateString()}</td>
              <td>{item.sportsbook_signup.sportsbook.name}</td>
              <td>{item.money_type.name}</td>
              <td>{item.notes}</td>
              <td>
                <input type={"button"} value={"Remove"} onClick={(e) => {removeItem(e.target.id)}} id={item.id} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <button onClick={addItemForm}>Add {item_type_name}</button>
    </div>
  )
}
export default TransactionList;