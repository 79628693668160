import React, {useEffect, useState} from 'react';
import {
  americanToDecimalOdds,
  convertOddsToDecimal,
  decimalToAmericanOdds,
  noDecimalPercentageFormatter,
  percentageFormatter
} from "../utils/BetUtils";
import './OddsInput.css';

const OddsInput = ({innerRef, value, setValue, ...rest}) => {
  const [implied_odds, setImpliedOdds] = useState(0);

  const fixInvalidAmericanOdds = () => {
      if (value > -100.0 && value < 100.0) {
          setValue(Math.round(decimalToAmericanOdds(americanToDecimalOdds(value))));
      }
      //return odds;
  }
  const oddsChanged = (e) => {
    let odds = e.target.value;
    //setImpliedOdds(1/convertOddsToDecimal(odds));
    setValue(odds);
  }

  useEffect(() => {
    setImpliedOdds(1/convertOddsToDecimal(value));
  }, [value]);


  return (
    <div className='odds-input-container'>
      <div>
        <input
          ref={innerRef} type='number'
          onChange={oddsChanged}
          onBlur={fixInvalidAmericanOdds}
          value={value}
          className='odds-input'
          placeholder='Odds i.e. +110'
          {...rest} />
        <span className='input-decoration__percentage' data-tooltip-id="main-tooltip" data-tooltip-content={`Approximate 'implied probability'`}>{noDecimalPercentageFormatter.format(implied_odds)}</span>
      </div>
    </div>
  )
}

export default OddsInput;