import React from 'react';
import { useModal } from './ModalContext';
import './Modal.css';

const Modal = () => {
  const { modalContent, hideModal } = useModal();

  if (!modalContent) return null;

  return (
    <div className="modal-overlay" onMouseDown={hideModal} data-testid='modal'>
      <div className="modal-content" onMouseDown={(e) => e.stopPropagation()}>
        {modalContent}
      </div>
    </div>
  );
};

export default Modal;
