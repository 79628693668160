import {useContext, useEffect, useState} from "react";
import apiClient from "../APIClient";
import SearchableDropdown from "../SearchableDropdown";
import {AuthContext} from "../AuthContext";
import './SportsbookSignups.css'
import {StatusMessageContext} from "../StatusMessageContext";


const SportsbookSignups = () => {
  const [sportsbooks, setSportsbooks] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [sportsbookSignups, setSportsbookSignups] = useState([]);
  const [sportsbookToAdd, setSportsbookToAdd] = useState(null);
  const [sportsbookToAddLabel, setSportsbookToAddLabel] = useState(null);
  const [currency, setCurrency] = useState(null);
  const { currentUser } = useContext(AuthContext);
  const { addErrorStatusMessage } = useContext(StatusMessageContext);


  useEffect(() => {
    apiClient.get('/api/sportsbooks/').then((response) => setSportsbooks(response.data))
    apiClient.get('/api/currencies/').then((response) => setCurrencies(response.data))
    apiClient.get('/api/sportsbook-signups/').then((response) => setSportsbookSignups(response.data))
  }, []);


  useEffect(() => {
    if (sportsbookToAdd) {
      setSportsbookToAddLabel(sportsbookToAdd.name);
    }
  }, [sportsbookToAdd]);

  const addSportsbookSignup = () => {

    if (!sportsbookToAdd || !currency || !sportsbookToAddLabel) {
      addErrorStatusMessage("Please select a sportsbook and the currency that you use for that book, and specify a label.")
      return;
    }

    const data = {
      sportsbook_id: sportsbookToAdd.id,
      user_id: currentUser.id,
      currency_id: currency.id,
      label: sportsbookToAddLabel
    }

    apiClient.post('/api/sportsbook-signups/', data)
      .then((response) => {
        setSportsbookSignups([...sportsbookSignups, response.data]);
      })
      .catch((error) => {
        addErrorStatusMessage(`There was a problem trying to add the signup. It may already exist.`)
      });

  }
  const removeSportsbookSignup = (id) => {
    const data = {
      id: id
    }
    apiClient.delete(`/api/sportsbook-signups/${id}`).then((response) => {
      apiClient.get('/api/sportsbook-signups/').then((response) => setSportsbookSignups(response.data))
    });
  }

  const handleCurrencyChange = (e) => {
    const updated = sportsbookSignups.map((ss) => {
      if (ss.id === +(e.target.id)) {
        const updated_item = {
          ...ss,
          currency: currencies.filter((c) => { return c.id === +(e.target.value) })[0],
        }
        return updated_item;
      }
      return ss;
    });
    setSportsbookSignups(updated);
  }
  const handleLabelChange = (e) => {
    sportsbookSignups.filter((ss) => { return ss.id === +(e.target.id) })[0].label = e.target.value;


    const updated = sportsbookSignups.map((ss) => {
      if (ss.id === +(e.target.id)) {
        const updated_item = {
          ...ss,
          label: e.target.value,
        }
        return updated_item;
      }
      return ss;
    });
    setSportsbookSignups(updated);
  }
  const updateSportsbookSignup = (e) => {

    const updated_data = {
      currency_id: sportsbookSignups.filter((ss) => { return ss.id === +(e.target.id)})[0].currency.id,
      label: sportsbookSignups.filter((ss) => { return ss.id === +(e.target.id)})[0].label
    }

    apiClient.patch(`/api/sportsbook-signups/${e.target.id}/`, updated_data)
      .then((response) => {

        const updated_list = sportsbookSignups.map((ss) => {
            if (ss.id !== response.data.id) {
              return ss;
            } else {
              return response.data;
            }
          });

        setSportsbookSignups(updated_list);
      })
      .catch((error) => {
        addErrorStatusMessage(`There was a problem trying to update the signup.`)
      });
  }


  return (

    <div id='sportsbook-signups-container'>
      <div>
        <div>
          <label>
            Add Sportsbook:
            <SearchableDropdown
                options={sportsbooks}
                onChange={setSportsbookToAdd}
                value={sportsbookToAdd}
                initialValue={sportsbookToAdd}
            />
          </label>
        </div>
        <div>
          <label>
            Currency you use for this book:
            <SearchableDropdown
                options={currencies}
                onChange={setCurrency}
                value={currency}
                initialValue={currency}
            />
          </label>
        </div>
        <div>
          <label>
            Label to use for this book:
            <div>
              <input type='text' value={sportsbookToAddLabel} onChange={(e) => setSportsbookToAddLabel(e.target.value)} />
            </div>
          </label>
        </div>
        <div>
          <input type='button' value='Add Sportsbook' onClick={addSportsbookSignup} />
        </div>
      </div>

      <table>
        <thead>
          <tr>
            <td>Name</td>
            <td>Signup Date</td>
            <td>Currency</td>
            <td>Remove</td>
          </tr>

        </thead>
        {sportsbookSignups && sportsbookSignups.map(ss => (
          <tr key={ss.id}>
            <td>
              <div><input type='text' value={ss.label || ss.sportsbook.name} id={ss.id} onChange={handleLabelChange} /></div>
              <div class='small-info'>{ss.sportsbook.name}</div>
            </td>
            <td>{new Date(ss.signup_date).toLocaleDateString()}</td>
            <td>
              <select
                value={ss.currency.id}
                onChange={handleCurrencyChange}
                id={ss.id}
              >
                {currencies && currencies.map((c) => (
                  <option key={c.id} value={c.id}>{c.name}</option>
                ))}
              </select>
            </td>
            <td>
              <input className='small-button' type='button' value='Save' id={ss.id}
                       onClick={updateSportsbookSignup}/>
              <input className='small-button' type='button' value='Remove' id={ss.id}
                     onClick={(e) => removeSportsbookSignup(e.target.id)}/>
            </td>
          </tr>
        ))}
      </table>
    </div>
  )
}


export default SportsbookSignups;