import React, { useState, useEffect } from 'react';
import axios from 'axios';
import apiClient from "../../APIClient";
import '../BetsList.css';
import './MatchedBetsList.css';
import moment from "moment";
import {
  currencyFormatter, dateTimeFormatted,
  descriptionForBet, eventTitleForBet, friendlyTimeUntil,
  matchedBetConversionRate,
  matchedBetTypeDescription,
  percentageFormatter, timeFormatted
} from '../../utils/BetUtils';
import BetTile from "../BetTile";
import MatchedBetTypeTile from "../MatchedBetTypeTile";
import MatchedBetBetTile from "../MatchedBetBetTile/MatchedBetBetTile";
import {
  fetchMatchedBets,
  fetchBetStatuses,
  fetchMatchedBetStatuses,
  deleteBet,
  fetchExchangeRate, fetchCurrentUser, fetchMatchedBetsByDateRange, fetchBetConditionTypes
} from "../../utils/ApiUtils";
import {useParams} from "react-router-dom";
import MatchedBetsListFilter from "./MatchedBetsListFilter";

function MatchedBetsList({matchedBets, setMatchedBets, startDate, endDate, ...rest}) {
  const params = useParams();
  const [showArbitrage, setShowArbitrage] = useState(true);
  const [showLowHold, setShowLowHold] = useState(true);
  const [showMiddle, setShowMiddle] = useState(true);
  const [showFreeBet, setShowFreeBet] = useState(true);
  const [showRiskFreeBet, setShowRiskFreeBet] = useState(true);
  const [showOpen, setShowOpen] = useState(true);
  const [showSettle, setShowSettled] = useState(true);
  const [filterText, setFilterText] = useState('');
  const [betConditionTypes, setBetConditionTypes] = useState([]);


  useEffect(() => {
    fetchBetConditionTypes(setBetConditionTypes);
  }, []);



  //matchedBets, setMatchedBets,

  if (!startDate) {
    startDate = moment().startOf('month').format('YYYY-MM-DD');
    endDate = moment().endOf('month').format('YYYY-MM-DD');
  } else {
    startDate = moment(startDate).format('YYYY-MM-DD');
    endDate = moment(endDate).format('YYYY-MM-DD');
  }


  //const [matchedBets, setMatchedBets] = useState([]);
  // Add a new state for bet statuses
  //const [betStatuses, setBetStatuses] = useState([]);
  const [matchedBetStatuses, setMatchedBetStatuses] = useState([]);
  const [exchange_rates, setExchangeRates] = useState({});
  const [betStatuses, setBetStatuses] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    //fetchMatchedBets(setMatchedBets);
    fetchBetStatuses(setBetStatuses);
    fetchMatchedBetStatuses(setMatchedBetStatuses);
    fetchCurrentUser(setCurrentUser);
  }, []);

  /*
  useEffect(() => {
    fetchMatchedBetsByDateRange(startDate, endDate, setMatchedBets);
    fetchMatchedBetStatuses(setMatchedBetStatuses);
  }, [startDate, endDate]);
  */

  const matchedBetsUpdated = (updated_bet_details) => {
    const matchedBet_id = updated_bet_details.matchedBet_id;
    const updated_bets = updated_bet_details.bets;

    let matchedBet = matchedBets.find((matchedBet) => matchedBet.id === matchedBet_id);
    const matchedBet_index = matchedBets.findIndex((matchedBet) => matchedBet.id === matchedBet_id);


    for (const [bet_id, updated_bet] of Object.entries(updated_bet_details.bets)) {
      if (matchedBet.bet_a.id === +(bet_id)) {
        matchedBet = {
          ...matchedBet,
          bet_a: updated_bet
        }
      }
      if (matchedBet.bet_b.id === +(bet_id)) {
        matchedBet = {
          ...matchedBet,
          bet_b: updated_bet
        }
      }
    }

    const updated_matchedBets = [
      ...matchedBets.slice(0, matchedBet_index),
      matchedBet,
      ...matchedBets.slice(matchedBet_index + 1),
    ];
    setMatchedBets(updated_matchedBets);

  }

  const deleteMatchedBet = (matchedBet) => {
    deleteBet(matchedBet.bet_a.id,);
    deleteBet(matchedBet.bet_b.id);
    matchedBetDeleted(matchedBet);
  }

  const matchedBetDeleted = (deleted_matchedBet) => {
    const matchedBet_index = matchedBets.findIndex((matchedBet) => matchedBet.id === deleted_matchedBet.id);

    const updated_matchedBets = [
      ...matchedBets.slice(0, matchedBet_index),
      ...matchedBets.slice(matchedBet_index + 1),
    ];
    setMatchedBets(updated_matchedBets);
  }

  /*
  const matchedBetsUpdated = (updated_bets) => {
    let updatedBets = [...bets];
    const matchedBet_id = updated_bets.matchedBet_id;
    for (const [bet_id, updated_bet] of Object.entries(updated_bets.bets)) {
      let index = updatedBets.findIndex((bet) => bet.id === updated_bet.id);
      // Update the bets array with the updated bet object
      updatedBets[index] = updated_bet;

      // Update the matched bets array with the updated bet object

      index = matchedBets.findIndex((matchedBet) => matchedBet.id === matchedBet_id);
      const related_bet = updated_bet.related_bet;
      const matchedBet = matchedBets[index];

      // Update the bets array with the updated bet object
      let updatedMatchedBets = [
        ...matchedBets.slice(0, index),
        {
          ...matchedBet,
          bet_a: updated_bet,
          bet_b: related_bet ? related_bet : matchedBets[index].bet_b
        },
        ...matchedBets.slice(index + 1),
      ];

      setMatchedBets(updatedMatchedBets);

    }
    setBets(updatedBets);
  }
  */

  return (
    <div className={'matched-bets-list-container'} {...rest}>
      {startDate &&
        <h1>
          {moment(startDate).format("dddd, MMMM Do YYYY")}
          {endDate && startDate !== endDate &&
            <span> - {moment(endDate).format("dddd, MMMM Do YYYY")}</span>
          }
        </h1>
      }

      <MatchedBetsListFilter
        showAribitrage={showArbitrage}
        setShowArbitrage={setShowArbitrage}
        showLowHold={showLowHold}
        setShowLowHold={setShowLowHold}
        showMiddle={showMiddle}
        setShowMiddle={setShowMiddle}
        showFreeBet={showFreeBet}
        setShowFreeBet={setShowFreeBet}
        showRiskFreeBet={showRiskFreeBet}
        setShowRiskFreeBet={setShowRiskFreeBet}
        showOpen={showOpen}
        showSettled={showSettle}
        setShowOpen={setShowOpen}
        setShowSettled={setShowSettled}
        filterText={filterText}
        setFilterText={setFilterText}
        />


      <table id='bets-list'>
        <tbody>
          {matchedBets.map((matchedBet, index) => {
            if (moment(matchedBet.bet_a.conditions[0].pinnacle_event.starts).isSame(moment(startDate), 'date') || moment(matchedBet.bet_a.conditions[0].pinnacle_event.starts).isSame(moment(endDate), 'date') || (moment(matchedBet.bet_a.conditions[0].pinnacle_event.starts).isAfter(moment(startDate)) && moment(matchedBet.bet_a.conditions[0].pinnacle_event.starts).isBefore(moment(endDate)))) {
              if (!showOpen && matchedBet.bet_a.bet_status.name === 'Open') { return; }
              if (!showSettle && matchedBet.bet_a.bet_status.name !== 'Open') { return; }

              if (!showMiddle && matchedBetTypeDescription(matchedBet) === 'Middle') { return; }
              if (!showLowHold && matchedBetTypeDescription(matchedBet) === 'Low-Hold') { return; }
              if (!showArbitrage && matchedBetTypeDescription(matchedBet) === 'Arbitrage') { return; }
              if (!showFreeBet && matchedBetTypeDescription(matchedBet) === 'Free-Bet Conversion') { return; }
              if (!showRiskFreeBet && matchedBetTypeDescription(matchedBet) === 'Risk-Free Conversion') { return; }

              if (filterText.trim().length > 0) {
                let matchedText = false;

                if (matchedBet.bet_a.conditions[0].team_name.toLowerCase().includes(filterText.trim().toLowerCase()) || matchedBet.bet_b.conditions[0].team_name.toLowerCase().includes(filterText.trim().toLowerCase())) {
                  matchedText = true;
                }
                if (matchedBet.bet_a.sportsbook_signup.sportsbook.name.toLowerCase().includes(filterText.trim().toLowerCase()) || matchedBet.bet_b.sportsbook_signup.sportsbook.name.toLowerCase().includes(filterText.trim().toLowerCase())) {
                  matchedText = true;
                }
                if (!matchedText) {
                  return;
                }
              }

              let scoreboard_type = (new Date(matchedBet.bet_a.conditions[0].pinnacle_event.starts)) < (new Date()) ? 'live_tv' : '';
              let scoreboard_tooltip = 'See scores on Google';
              if (matchedBet.bet_a.conditions[0].pinnacle_event.settled) {
                scoreboard_type = "scoreboard";
              }

              let scoreboard_link = scoreboard_type === '' ? (<span></span>) : (
                <span>
                  &nbsp;
                  <a data-tooltip-id="main-tooltip" data-tooltip-content={scoreboard_tooltip} href={`https://www.google.com/search?q=${matchedBet.bet_a.conditions[0].pinnacle_event.home} vs ${matchedBet.bet_a.conditions[0].pinnacle_event.away} ${new Date(matchedBet.bet_a.conditions[0].pinnacle_event.starts).toLocaleDateString()}`} target={'_blank'}>
                    <span className={'material-symbols-outlined md-18'}>{scoreboard_type}</span>
                  </a>
                </span>
              )

              return (
                <tr key={matchedBet.id}>
                  <td>
                    <table className={'matched-bet-list--event-table'}>
                      {(index === 0 || matchedBets[index].bet_a.conditions[0].pinnacle_event.id !== matchedBets[index-1].bet_a.conditions[0].pinnacle_event.id) &&
                        <thead>
                          <tr>
                            <th colSpan={3}>
                              <div className={'matched-bet-list--event-title'}>
                                {eventTitleForBet(matchedBet.bet_a)}
                              </div>
                              <div className={'matched-bet-list--event-date'}>
                                <span>
                                  {timeFormatted(matchedBet.bet_a.conditions[0].pinnacle_event.starts)}
                                </span>
                                {matchedBet.bet_a.conditions[0].pinnacle_event.settled !== true && matchedBet.bet_a.conditions[0].pinnacle_event.event_type === 'live' &&
                                  <span> - Live</span>
                                }
                                {matchedBet.bet_a.conditions[0].pinnacle_event.settled !== true && matchedBet.bet_a.conditions[0].pinnacle_event.event_type === 'prematch' &&
                                  <span>
                                    &nbsp;- {friendlyTimeUntil(matchedBet.bet_a.conditions[0].pinnacle_event.starts)}
                                    {friendlyTimeUntil(matchedBet.bet_a.conditions[0].pinnacle_event.starts) !== 'Live' &&
                                      <span className={'material-symbols-outlined md-18'}>
                                        schedule
                                      </span>
                                    }
                                    {scoreboard_link}
                                  </span>
                                }
                                {matchedBet.bet_a.conditions[0].pinnacle_event.settled === true &&
                                  <span>{scoreboard_link}</span>
                                }

                              </div>
                            </th>
                          </tr>
                        </thead>
                      }
                      <tbody>
                        <tr>
                          <td>
                            <MatchedBetBetTile
                              bet={matchedBet.bet_a}
                              betConditionTypes={betConditionTypes}
                            />
                          </td>
                          <td>
                            <MatchedBetTypeTile
                              matchedBet={matchedBet}
                              betsUpdated={matchedBetsUpdated}
                              deleteMatchedBet={deleteMatchedBet}
                              betStatuses={betStatuses}
                              matchedBetStatuses={matchedBetStatuses}
                            />
                          </td>
                          <td>
                            <MatchedBetBetTile
                              bet={matchedBet.bet_b}
                              betConditionTypes={betConditionTypes}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>

                  </td>
                </tr>
              )
            }
          })}
        </tbody>
      </table>
    </div>
  );
}

export default MatchedBetsList;
