import SearchableDropdown from "../../SearchableDropdown";
import {
    americanToDecimalOdds,
    betBonusTypeIdIsFreeBet,
    betBonusTypeIdIsRiskFreeBet,
    convertOddsToDecimal, decimalToAmericanOdds
} from "../../utils/BetUtils";
import React, {useEffect, useState} from "react";
import {useModal} from "../modal/ModalContext";
import BetCalculator from "./BetCalculator";
import RiskFreeBetAdvancedOptions from "./RiskFreeBetAdvancedOptions";
import CurrencyInput from "../CurrencyInput";
import OddsInput from "../OddsInput";
import './BetCalculatorInputs.css'


const BetCalculatorInputs = ({wager_data, setWagerData, sportsbook_signups, bet_bonus_types, registerInputField, id_suffix, unlinked_wagers, setUnlinkedWagers, showSuggestedWagers}) => {
    //const [sportsbook_signup, setSportsbookSignup] = useState(wager_data.sportsbook_signup);
    const [sportsbook_signup_options, setSportsbookSignupOptions] = useState(wager_data.sportsbook_signup);
    //const [odds, setOdds] = useState(wager_data.odds);
    //const [wager, setWager] = useState(wager_data.wager);
    const [payout, setPayout] = useState(0);
    //const [bet_bonus_type, setBetBonusType] = useState(wager_data.bet_bonus_type);
    //const [risk_free_percentage_returned, setRiskFreePercentageReturned] = useState(wager_data.risk_free_percentage_returned);
    //const [risk_free_max_value_returned, setRiskFreeMaxValueReturned] = useState(wager_data.risk_free_max_value_returned);
    //const [risk_free_estimated_conversion_rate, setRiskFreeEstimatedConversionRate] = useState(wager_data.risk_free_estimated_conversion_rate);
    const [show_advanced_risk_free_options, setShowAdvancedRiskFreeOptions] = useState(false);
    const { showModal, hideModal } = useModal();


    useEffect(() => {
        console.log("Running useEffect 8"); // Add a label to each useEffect

        //When we get the list of sportsbooks that the user is signed up for, we create the necessary data list that can
        //be used in the calculator

        if (!sportsbook_signups || sportsbook_signups.length === 0) { return }

        setSportsbookSignupOptions(sportsbook_signups.map((ss) => {
            return {
                id: ss.id,
                name: ss.sportsbook.name,
                label: ss.label,
                currency: ss.currency.code,
                signup_id: ss.id
            }
        }));
    }, [sportsbook_signups])

    const riskFreeOptionsForm = () => {
        showModal(<RiskFreeBetAdvancedOptions
            risk_free_percentage_returned={wager_data.risk_free_percentage_returned}
            risk_free_max_value_returned={wager_data.risk_free_max_value_returned}
            risk_free_estimated_conversion_rate={wager_data.risk_free_estimated_conversion_rate}
            setRiskFreePercentageReturned={setRiskFreePercentageReturned}
            setRiskFreeMaxValueReturned={setRiskFreeMaxValueReturned}
            setRiskFreeEstimatedConversionRate={setRiskFreeEstimatedConversionRate}
            hideAdvancedOptions={hideModal}
            currency_symbol={'$'}
            currency_code={wager_data.sportsbook_signup.currency}
        />);
    }

    const setRiskFreePercentageReturned = (new_risk_free_percentage_returned) => {
        setWagerData({
            risk_free_percentage_returned: new_risk_free_percentage_returned
        });
    }
    const setRiskFreeMaxValueReturned = (new_risk_free_max_value_returned) => {
        setWagerData({
            risk_free_max_value_returned: new_risk_free_max_value_returned
        });
    }
    const setRiskFreeEstimatedConversionRate = (new_risk_free_estimated_conversion_rate) => {
        setWagerData({
            risk_free_estimated_conversion_rate: new_risk_free_estimated_conversion_rate
        });
    }


    /*
    useEffect(() => {

        let payout = wager_data.wager * convertOddsToDecimal(wager_data.odds);
        /*if (betBonusTypeIdIsFreeBet(+bet_bonus_type)) {
            payout = wager * (convertOddsToDecimal(odds) - 1.0);
        } else if (betBonusTypeIdIsRiskFreeBet(+bet_bonus_type)) {
            payout = (wager * (convertOddsToDecimal(odds))) - (Math.min(wager * risk_free_percentage_returned, risk_free_max_value_returned) * risk_free_estimated_conversion_rate);
        }//////
        //setPayout(new_payout);

        setWagerData({
            sportsbook_signup: wager_data.sportsbook_signup,
            odds: wager_data.odds,
            wager: wager_data.wager,
            payout,
            bet_bonus_type: wager_data.bet_bonus_type,
            risk_free_estimated_conversion_rate,
            risk_free_percentage_returned,
            risk_free_max_value_returned,
        });

    }, [sportsbook_signup]);
    */

    const setOdds = (new_odds) => {
        setWagerData({
            odds: new_odds
        });
    }
    const setWager = (new_wager) => {
        setWagerData({
            wager: new_wager
        });
    }

    const setBetBonusType = (new_bet_bonus_type) => {
        setWagerData({
            bet_bonus_type: new_bet_bonus_type
        });
    }
    const setSportsbookSignup = (new_sportsbook_signup) => {
        setWagerData({
            sportsbook_signup: new_sportsbook_signup
        });
    }


    /*
    const fixInvalidAmericanOdds = () => {
        if (+wager_data.odds > -100.0 && +wager_data.odds < 100.0) {
            setOdds(Math.round(decimalToAmericanOdds(americanToDecimalOdds(wager_data.odds))));
        }
    }
    const fixInvalidOdds = () => {
        if (odds_format.toLowerCase() == 'american') {
            fixInvalidAmericanOdds();
        } else if (odds_format.toLowerCase() == 'decimal') {
            //TODO:  Need to deal with this...
        }
    }
    */

    let label = `Sportsbook${id_suffix}:`.replaceAll(/\n/g, '');
    if (label === 'Sportsbook_a:') {
        label = "Sportsbook A:";
    }
    if (label === 'Sportsbook_b:') {
        label = "Sportsbook B:";
    }
    return (
        <div className={`bet-details bet-details${id_suffix}`}>
            <div>
                <label>
                    <div>
                        {label}
                    </div>
                    <div>
                        <SearchableDropdown
                            options={sportsbook_signup_options}
                            onChange={setSportsbookSignup}
                            value={wager_data.sportsbook_signup}
                            initialValue={wager_data.sportsbook_signup}
                            tabIndex={1}
                            id={`sportsbook_signup${id_suffix}`}
                            data-testid={`sportsbook_signup${id_suffix}`}
                        />
                    </div>
                </label>
            </div>
            {wager_data.sportsbook_signup &&
                <div>
                    <div>
                        <OddsInput
                            value={wager_data.odds}
                            innerRef={(input) => { registerInputField(input) }}
                            setValue={(odds) => setOdds(odds)}
                            //onBlur={fixInvalidAmericanOdds}
                            id={`odds${id_suffix}`}
                            data-testid={`odds${id_suffix}`}
                            tabIndex={2}
                        />
                    </div>

                    <div>
                        {showSuggestedWagers &&
                          <span className="material-symbols-outlined md-18 clickable" onClick={showSuggestedWagers} data-tooltip-id="main-tooltip" data-tooltip-content="Suggested wagers that are rounded to $5 increments">
                            tips_and_updates
                          </span>
                        }
                        <CurrencyInput
                          currency_symbol={'$'}
                          currency_code={wager_data.sportsbook_signup ? wager_data.sportsbook_signup.currency : ""}
                          innerRef={(input) => { registerInputField(input) }}
                          value={wager_data.wager}
                          id={`wager${id_suffix}`}
                          data-testid={`wager${id_suffix}`}
                          onChange={(e) => setWager(e.target.value)}
                          placeholder='Wager'
                        />
                    </div>

                    {bet_bonus_types &&
                        <div>
                            {betBonusTypeIdIsRiskFreeBet(wager_data.bet_bonus_type) &&
                                <span className="material-symbols-outlined md-18 clickable" onClick={riskFreeOptionsForm}>
                                    settings
                                </span>
                            }
                            <select
                                onChange={(e) => { setBetBonusType(e.target.value) } }
                                id={`bet_bonus_type${id_suffix}`}
                                data-testid={`bet_bonus_type${id_suffix}`}
                                ref={(input) => { registerInputField(input) }}
                                value={wager_data.bet_bonus_type}
                                className={'bet-bonus-type'}
                                tabIndex={3}
                            >
                                {bet_bonus_types &&
                                    bet_bonus_types.map((bbt) => (
                                        <option key={bbt.id} value={bbt.id}>{bbt.name}</option>
                                    ))
                                }
                            </select>

                        </div>
                    }
                    {setUnlinkedWagers &&
                      <div className={'unlink-wagers-container'}>
                          <label className='small-label' data-tooltip-id="main-tooltip" data-tooltip-content="Check this to allow imbalanced wager values">
                              <input type={'checkbox'} data-testid={'unlink_wagers'} checked={unlinked_wagers} onChange={(e) => {setUnlinkedWagers(e.target.checked)}} />
                              Unlinked Wagers
                              <span className="material-symbols-outlined md-14">info</span>
                          </label>
                      </div>
                    }
                </div>
            }
        </div>
    )
}

export default BetCalculatorInputs;