import axios from "axios";
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import BetForm from './BetForm'
import apiClient from "./APIClient";
import MatchedBetCalculator from "./components/MatchedBetCalculator";

const Bet = () => {
  const params = useParams();
  const [sportsbooks, setSportsbooks] = useState([]);
  const [betConditionTypes, setBetConditionTypes] = useState([]);
  const [betStatuses, setBetStatuses] = useState([]);
  const [leagues, setLeagues] = useState([]);
  const [sports, setSports] = useState([]);
  const [betData, setBetData] = useState({});
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    apiClient.get('/api/sportsbooks/').then((response) => setSportsbooks(response.data));
    apiClient.get('/api/bet-condition-types/').then((response) => setBetConditionTypes(response.data));
    apiClient.get('/api/bet-statuses/').then((response) => setBetStatuses(response.data));
    apiClient.get('/api/leagues/').then((response) => setLeagues(response.data));
    apiClient.get('/api/sports/').then((response) => setSports(response.data));
    if (params.id) {
        setEditMode(true);
        apiClient.get(`/api/bets/${params.id}`).then((response) => setBetData(response.data));
    }
  }, []);

  const handleBetSubmit = (data) => {
    if (params.id) {
      apiClient.put(`/api/bets/${params.id}/`, data).then((response) => console.log(response));
    } else {
      apiClient.post('/api/bets/', data).then((response) => console.log(response));
    }
    
  };

  return (
    <div>
      <h1>New Bet</h1>
      <BetForm
        sportsbooks={sportsbooks}
        betConditionTypes={betConditionTypes}
        betStatuses={betStatuses}
        leagues={leagues}
        sports={sports}
        bet_data={betData}
        edit_mode={editMode}
        onSubmit={handleBetSubmit}
      />
    </div>
  );
};

export default Bet;
