import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import SearchableDropdown from "./SearchableDropdown";
import DateTimePicker from 'react-datetime-picker'
import './App.css';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import BetCondition from './BetCondition'
import BetConditionList from './BetConditionList'

const BetForm = ({ sportsbooks, betConditionTypes, betStatuses, leagues, sports, onSubmit, edit_mode, bet_data }) => {
  const [sportsbook, setSportsbook] = useState('');
  const [wager_amount, setWagerAmount] = useState(100);
  const [bet_status, setBetStatus] = useState('');
  const [odds, setOdds] = useState(100);

  const [conditionIDIncrementer, setConditionIDIncrementer] = useState(1);
  const [bet_conditions, setBetConditions] = useState({});

  useEffect(() => {
      if (bet_data.id) {
          setBetStatus(bet_data.bet_status);
          setWagerAmount(bet_data.wager_amount);
          setOdds(bet_data.odds);
          setSportsbook(bet_data.sportsbook)

          setBetConditions({});
          bet_data.conditions.map((c) => {
              collectBetConditionValues(c.id, {...c});
          });
      }
  }, [bet_data])

  const collectBetConditionValues = (id, values) => {
    setBetConditions((prevValues) => {
      return {...prevValues, [id]: values};
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      sportsbook,
      wager_amount,
      odds,
      conditions: Object.values(bet_conditions),
      bet_status,
    };
    onSubmit(data);
  };

  useEffect(() => {
    if (betStatuses.length > 0) {
      setBetStatus(betStatuses[0].id);
    }
  }, [betStatuses]);


  useEffect(() => {
      if (leagues.length > 0 && sports.length > 0 && betConditionTypes.length > 0) {
        if (Object.keys(bet_conditions).length === 0 && edit_mode === false) { addCondition(); }
      }

  }, [leagues, sports, betConditionTypes]);

  const removeCondition = (id) => {
      //const newConditions = bet_conditions.filter((item) => item.id !== id);

      //const {id_to_remove, ...new_conditions} = bet_conditions;
      //setBetConditions(new_conditions);

      const new_conditions = Object.assign({}, bet_conditions)
      delete new_conditions[id]
      setBetConditions(new_conditions)
      //setBetConditions(newConditions);
  }

  const addCondition = () => {
    //const new_condition = {'id': conditionIDIncrementer};
    //setBetConditions((bet_conditions) => {
    //    return {...bet_conditions, [conditionIDIncrementer]: new_condition};
    //});
      /*

    setBetConditions((prevValues) => {
      return {...prevValues, [1]: {'test': 1}};
    });
    */
    setConditionIDIncrementer(conditionIDIncrementer + 1);
    collectBetConditionValues(conditionIDIncrementer, {
        'odds': 100,
        'condition': "test",
        'event_date': new Date(),
        'bet_condition_type': 1,
        'league': 1,
    });
  };

  const sportsbookChange = (selected) => {
      setSportsbook(selected.id);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label>
          Sportsbook:
          <SearchableDropdown
              options={sportsbooks}
              onChange={sportsbookChange}
              value={sportsbook}
              initialValue={sportsbook}
          />
        </label>
      </div>
      <div>
        <label>
          Bet Status:
          <select value={bet_status} onChange={(e) => setBetStatus(e.target.value)}>
            {betStatuses.map((bs) => (
              <option key={bs.id} value={bs.id}>
                {bs.name}
              </option>
            ))}
          </select>
        </label>
      </div>
      <div>
        <label>
          Wager Amount:
          <input type="number" value={wager_amount} onChange={(e) => setWagerAmount(e.target.value)} />
        </label>
      </div>
      <div>
        <label>
          Odds:
          <input type="number" value={odds} onChange={(e) => setOdds(e.target.value)} />
        </label>
      </div>
        <div id="bet-conditions-container">
          <BetConditionList
              conditions={bet_conditions}
              betConditionTypes={betConditionTypes}
              leagues={leagues}
              sports={sports}
              betStatuses={betStatuses}
              removeFunction={removeCondition}
              collectValues={collectBetConditionValues}
          />
        </div>
      <div>
        <input type="button" value="Add another condition" onClick={addCondition} />
      </div>
      <div>
        <input type="submit" value="Submit" />
      </div>
    </form>
  );
};

export default BetForm;