import './CurrencyInput.css'


const CurrencyInput = ({innerRef, currency_symbol, currency_code, ...rest}) => {
  return (
    <div className='currency-input-container'>
      <div>
        <span className='input-decoration__currency-symbol'>{currency_symbol}</span>
        <input ref={innerRef} type='number' {...rest} />
        <span className='input-decoration__currency-code'>{currency_code}</span>
      </div>
    </div>
  )
}

export default CurrencyInput;