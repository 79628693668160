import PinnacleEventSelector from "./PinnacleEventSelector";
import React, {useEffect, useState} from "react";
import apiClient from "../APIClient";
import SearchableDropdown from "../SearchableDropdown";
import './MatchedBetConditions.css'
import PlayerSelector from "./PlayerSelector";
import {
  getAverageMoneylineDecimalOdds,
  totalsOptions,
  spreadOptions,
  moneylineUnderdogTeam,
  moneylineFavouriteTeam,
  americanToDecimalOdds, decimalToAmericanOdds, currencyFormatter, convertOddsToDecimal
} from "../utils/BetUtils";
import OddsInput from "./OddsInput";
import CurrencyInput from "./CurrencyInput";

const MatchedBetConditions = ({setBetAConditions, setBetBConditions, wager_a_data, wager_b_data, addMatchedBets}) => {
  //const [event_schedule, setEventSchedule] = useState(null);
  const [event, setEvent] = useState(null);
  const [bet_condition_type, setBetConditionType] = useState(1);
  const [betConditionTypes, setBetConditionTypes] = useState([]);
  const [playerPropBetTypes, setPlayerPropBetTypes] = useState([]);
  const [player_prop_bet_type, setPlayerPropBetType] = useState(1);
  const [betStatuses, setBetStatuses] = useState([]);
  const [leagues, setLeagues] = useState([]);
  const [sports, setSports] = useState([]);
  const [bet_a_handicap, setBetAHandicap] = useState(null);
  const [bet_b_handicap, setBetBHandicap] = useState(null);
  const [totals_options, setTotalsOptions] = useState([]);
  const [spread_options, setSpreadOptions] = useState([]);
  const [over_under_modifier, setOverUnder] = useState(1); //1 for over, -1 for under
  const [bet_a_player_prop_other_condition, setBetAPlayerPropOtherCondition] = useState(null);
  const [bet_b_player_prop_other_condition, setBetBPlayerPropOtherCondition] = useState(null);
  const [bet_a_other_condition, setBetAOtherCondition] = useState('');
  const [bet_b_other_condition, setBetBOtherCondition] = useState('');
  const [bookmakers, setBookmakers] = useState([]);
  const [average_moneyline_odds, setAverageMoneylineOdds] = useState({});
  const [period, setPeriod] = useState('');
  const [periods, setPeriods] = useState([]);
  const [betAOdds, setBetAOdds] = useState(wager_a_data.odds);
  const [betBOdds, setBetBOdds] = useState(wager_b_data.odds);
  const [betAWager, setBetAWager] = useState(wager_a_data.wager);
  const [betBWager, setBetBWager] = useState(wager_b_data.wager);

  //Player or Team ID of the specific player or team being bet on
  const [bet_a_target, setBetATarget] = useState('');
  const [bet_b_target, setBetBTarget] = useState('');

  useEffect(() => {
    apiClient.get('/api/bet-condition-types/').then((response) => setBetConditionTypes(response.data));
    apiClient.get('/api/bet-statuses/').then((response) => setBetStatuses(response.data));
    //apiClient.get('/api/leagues/').then((response) => setLeagues(response.data));
    apiClient.get('/api/psports/').then((response) => setSports(response.data));
    apiClient.get('/api/player-prop-bet-types/').then((response) => setPlayerPropBetTypes(response.data));
  }, []);

  useEffect(() => {
    if (!event) { return }

    //setBetATarget(moneylineUnderdogTeam(event_schedule.home_team, event_schedule.away_team, bookmakers));
    //setBetBTarget(moneylineFavouriteTeam(event_schedule.home_team, event_schedule.away_team, bookmakers));
    setBetATarget(event.home);
    setBetBTarget(event.away);

    const sport_periods = sports.filter((sport) => {
      return sport.id === event.sport;
    });
    if (sport_periods.length > 0) {
      setPeriods(sport_periods[0].periods);
      setPeriod(sport_periods[0].periods[0].id);
    } else {
      setPeriods([]);
      setPeriod('');
    }

  }, [event, sports]);

  /*
  useEffect(() => {
    if (!bet_a_handicap) {return}

    const cbc = currentBetCondition();
    if (!cbc) { return }
    if (cbc.name.toLowerCase() === 'total' || cbc.name.toLowerCase() === 'team total' || cbc.name.toLowerCase() === 'player prop') {
      setBetBHandicap(bet_a_handicap);
    }
    if (cbc.name.toLowerCase() === 'spread') {
      setBetBHandicap(spread_options.filter((so) => {
        return +(so.value) === (+(bet_a_handicap.value) * -1);
      })[0]);
    }


    setBetAConditions(betAConditionData());
    setBetBConditions(betBConditionData());

  }, [bet_a_handicap])
  */

  useEffect(() => {
    setBetAConditions(betAConditionData(bet_a_handicap));
    setBetBConditions(betBConditionData(bet_b_handicap));
  },[event, bet_condition_type, player_prop_bet_type, over_under_modifier,
    bet_a_player_prop_other_condition, bet_b_player_prop_other_condition, bet_a_target, bet_b_target]);

  const _setBetAHandicap = (handicap) => {
    if (currentBetCondition().name.toLowerCase() === "spread") {
      setBetAHandicap(handicap);
      const lbl = (+(handicap.value)*-1) > 0 ? `+${(+(handicap.value)*-1)}` : `${(+(handicap.value)*-1)}`
      const b_handicap = {label: lbl, value: +(handicap.value)*-1}
      setBetBHandicap(b_handicap);

      setBetAConditions(betAConditionData(handicap));
      setBetBConditions(betBConditionData(b_handicap));
    } else {
      setBetAHandicap(handicap);
      setBetBHandicap(handicap);

      setBetAConditions(betAConditionData(handicap));
      setBetBConditions(betBConditionData(handicap));
    }

  }
  const _setBetBHandicap = (handicap) => {
    setBetBHandicap(handicap);

    setBetBConditions(betBConditionData(handicap));
  }

  const currentPlayerPropBetType = () => {
    if (!player_prop_bet_type) { return null }
    const ret = playerPropBetTypes.filter((ppbt) => {
      return ppbt.id === player_prop_bet_type.id
    })
    return ret ? ret[0] : null;
  }
  const currentBetCondition = () => {
    if (betConditionTypes.filter((bct) => { return bct.id === +(bet_condition_type) })) {
      return betConditionTypes.filter((bct) => {
        return bct.id === +(bet_condition_type)
      })[0];
    }
  }

  const setSameTeamBetTargets = (e) => {
    if (e.target.value === event.home) {
      setBetATarget(event.home);
      setBetBTarget(event.home);
    } else {
      setBetATarget(event.away);
      setBetBTarget(event.away);
    }
  }

  const setMoneylineBetTargets = (e) => {

    if (e.target.id === 'bet_b_target') {
      if (e.target.value === event.home) {
        setBetBTarget(event.home);
        setBetATarget(event.away);
      } else {
        setBetBTarget(event.away);
        setBetATarget(event.home);
      }
    } else {
      if (e.target.value === event.home) {
        setBetATarget(event.home);
        setBetBTarget(event.away);
      } else {
        setBetATarget(event.away);
        setBetBTarget(event.home);
      }
    }
  }

  useEffect(() => {
    setSpreadOptions(spreadOptions());
    setTotalsOptions(totalsOptions());
  }, []);

  useEffect(() => {
    setBetAHandicap(null);
    setBetBHandicap(null);

    if (event) {
      if (currentBetCondition().name.toLowerCase() === 'team total') {
        setBetATarget(event.home);
        setBetBTarget(event.home);
      } else {
        setBetATarget(event.home);
        setBetBTarget(event.away);
      }

    }
  }, [bet_condition_type])

  const betAConditionData = (handicap) => {
    if (!(
      bet_a_target && bet_b_target && event
    )) {
      return;
    }

    handicap = handicap && handicap.value ? +(handicap.value) : handicap;
    if (currentBetCondition().name.toLowerCase() === 'total' ||
      currentBetCondition().name.toLowerCase() === 'team total') {
      handicap *= +over_under_modifier;
    }

    let condition = null;
    /*
    if (currentBetCondition().name.toLowerCase() === 'player prop') {
      condition = bet_b_player_prop_other_condition;
    }
    if (currentBetCondition().name.toLowerCase() !== 'moneyline') {
      condition = bet_b_other_condition;
    }*/
    if (currentBetCondition().name.toLowerCase() === 'other') {
      condition = bet_a_other_condition;
    }

    const ret = {
      bet_status: 1,
      odds: betAOdds,
      bet_condition_type: bet_condition_type,
      pinnacle_event: event.id,
      handicap: handicap,
      team_name: currentBetCondition().name.toLowerCase() === 'player prop' ? null : bet_a_target,
      player_name: currentBetCondition().name.toLowerCase() === 'player prop' ? bet_a_target : null,
      player_prop_bet_type: player_prop_bet_type.id || null,
      condition: condition, // currentBetCondition().name.toLowerCase() === 'player prop' ? bet_a_player_prop_other_condition : bet_a_other_condition, //Fix this
      pinnacle_period: period,
    }

    return ret
  }
  const betBConditionData = (handicap) => {
    if (!(
      bet_a_target && bet_b_target && event
    )) {
      return;
    }

    handicap = handicap && handicap.value ? +(handicap.value) : handicap;
    if (currentBetCondition().name.toLowerCase() === 'total' ||
      currentBetCondition().name.toLowerCase() === 'team total') {
      handicap *= -(+over_under_modifier);
    }
    let condition = null;
    /*
    if (currentBetCondition().name.toLowerCase() === 'player prop') {
      condition = bet_b_player_prop_other_condition;
    }
    if (currentBetCondition().name.toLowerCase() !== 'moneyline') {
      condition = bet_b_other_condition;
    }*/
    if (currentBetCondition().name.toLowerCase() === 'other') {
      condition = bet_b_other_condition;
    }

    const ret = {
      bet_status: 1,
      odds: betBOdds,
      bet_condition_type: +bet_condition_type,
      pinnacle_event: event.id,
      handicap: handicap,
      team_name: currentBetCondition().name.toLowerCase() === 'player prop' ? null : bet_b_target,
      player_name: currentBetCondition().name.toLowerCase() === 'player prop' ? bet_b_target : null,
      player_prop_bet_type: player_prop_bet_type.id || null,
      condition: condition, //currentBetCondition().name.toLowerCase() === 'player prop' ? bet_b_player_prop_other_condition : bet_b_other_condition, //Fix this
      pinnacle_period: period,
    }

    return ret
  }

  const _addMatchedBets = () => {
    const wagerAData = {
      ...wager_a_data,
      wager: betAWager,
    }
    const wagerBData = {
      ...wager_b_data,
      wager: betBWager,
    }
    addMatchedBets(wagerAData, wagerBData, betAConditionData(bet_a_handicap), betBConditionData(bet_b_handicap));
  }

  const bet_condition_description_for_period = (period, bet_condition_type) => {
    if (!period) { return '' }
    period = periods.find((p) => { return p.id === +period });
    if (!period) { return '' }
    if (bet_condition_type.name.toLowerCase() === "moneyline") {
      return period.moneyline_description;
    }
    if (bet_condition_type.name.toLowerCase() === "spread") {
      return period.spread_description;
    }
    if (bet_condition_type.name.toLowerCase() === "total") {
      return period.total_description;
    }
    if (bet_condition_type.name.toLowerCase() === "team total") {
      return "Team Total"; // Is this good?
    }
    if (bet_condition_type.name.toLowerCase() === "other") {
      return "Other"; // Is this good?
    }
    return '';
  }

  return (
    <div id='matched-bet-conditions'>
      <div>
        <div className={'matched-bet-details centered'}>
          <div>{wager_a_data.sportsbook_signup.name}</div>
          <div>
            <OddsInput
              value={betAOdds}
              setValue={setBetAOdds}
              />
            <CurrencyInput
              currency_symbol={'$'}
              currency_code={wager_a_data.sportsbook_signup ? wager_a_data.sportsbook_signup.currency : ""}
              value={betAWager}
              onChange={(e) => setBetAWager(e.target.value)}
              placeholder='Bet A Wager'
            />
          </div>
        </div>
        <div className={'matched-bet-details centered'}>
          <div>{wager_b_data.sportsbook_signup.name}</div>
          <div>
            <OddsInput
              value={betBOdds}
              setValue={setBetBOdds}
              />
            <CurrencyInput
              currency_symbol={'$'}
              currency_code={wager_b_data.sportsbook_signup ? wager_b_data.sportsbook_signup.currency : ""}
              value={betBWager}
              onChange={(e) => setBetBWager(e.target.value)}
              placeholder='Bet A Wager'
            />
          </div>
        </div>
      </div>
      <PinnacleEventSelector
        setEvent={setEvent}
        setEventBookmakers={setBookmakers}
        />

      {event && periods && period !== '' &&
        <div>
          <div>
            <label>
              <select value={bet_condition_type} onChange={(e) => setBetConditionType(e.target.value)}>
                {betConditionTypes.map((bct) => {
                  if (bet_condition_description_for_period(period, bct) !== '') {
                    return (
                      <option key={bct.id} value={bct.id}>
                        {bet_condition_description_for_period(period, bct)}
                      </option>
                    )
                  }
                })}
              </select>
            </label>
            <label>
              <select value={period} onChange={(e) => setPeriod(e.target.value)}>
                {periods.map((p) => (
                    <option key={p.id} value={p.id}>
                      {p.description}
                    </option>
                ))}
              </select>
            </label>
          </div>

          {betConditionTypes &&
            currentBetCondition() &&
            (currentBetCondition().name.toLowerCase() === 'moneyline' || currentBetCondition().name.toLowerCase() === 'spread') &&
            <div className='moneyline-container'>
              <div className='matched-bet-details'>
                <select value={bet_a_target} id='bet_a_target' onChange={setMoneylineBetTargets}>
                  <option value={event.home}>{event.home}</option>
                  <option value={event.away}>{event.away}</option>
                </select>
              </div>
              <div className='matched-bet-details'>
                <select value={bet_b_target} id='bet_b_target' onChange={setMoneylineBetTargets}>
                  <option value={event.home}>{event.home}</option>
                  <option value={event.away}>{event.away}</option>
                </select>
              </div>
            </div>
          }
          {betConditionTypes &&
            currentBetCondition() &&
            currentBetCondition().name.toLowerCase() === 'team total' &&
            <div className='team-total-container'>
              <select value={bet_a_target} onChange={setSameTeamBetTargets}>
                <option value={event.home}>{event.home}</option>
                <option value={event.away}>{event.away}</option>
              </select>
            </div>
          }

          {betConditionTypes &&
            currentBetCondition() &&
            (currentBetCondition().name.toLowerCase() === 'total' || currentBetCondition().name.toLowerCase() === 'team total') &&
            <div className='total-container'>
              <div className='bet-details'>
                <select value={over_under_modifier} onChange={(e) => { setOverUnder(e.target.value) }}>
                  <option value='1'>Over</option>
                  <option value='-1'>Under</option>
                </select>
                <SearchableDropdown
                  options={totals_options}
                  value={bet_a_handicap}
                  onChange={_setBetAHandicap}
                  />
              </div>
              <div className='bet-details'>
                <select value={over_under_modifier} onChange={(e) => { setOverUnder(e.target.value) }}>
                  <option value='-1'>Over</option>
                  <option value='1'>Under</option>
                </select>
                <SearchableDropdown
                  options={totals_options}
                  value={bet_b_handicap}
                  onChange={_setBetBHandicap}
                  />
              </div>
            </div>
          }

          {betConditionTypes &&
            currentBetCondition() &&
            currentBetCondition().name.toLowerCase() === 'spread' &&
            <div className='spread-container'>
              <div className='bet-details'>
                <SearchableDropdown
                  options={spread_options}
                  value={bet_a_handicap}
                  onChange={_setBetAHandicap}
                  />
              </div>
              <div className='bet-details'>
                <SearchableDropdown
                  options={spread_options}
                  value={bet_b_handicap}
                  onChange={_setBetBHandicap}
                  />
              </div>
            </div>
          }


          {playerPropBetTypes && player_prop_bet_type &&
            currentBetCondition() &&
            currentBetCondition().name.toLowerCase() === 'player prop' &&

            <div>


              <PlayerSelector
                setPlayer={(p) => {
                  setBetATarget(p.name);
                  setBetBTarget(p.name);
                }}
                value={{value: bet_a_target, label: bet_a_target}}
                />

              {bet_a_target &&
                <div>
                  <div>
                    <SearchableDropdown
                      options={playerPropBetTypes.filter((ppbt) => {
                        return (ppbt.applicable_sports.includes(event.league.sport))
                      })}
                      value={player_prop_bet_type}
                      onChange={setPlayerPropBetType}
                    />
                  </div>

                  {currentPlayerPropBetType() && currentPlayerPropBetType().name.toLowerCase() !== "other" &&
                    <div className='total-container'>
                      <div className='bet-details'>
                        <div>
                          <select value={over_under_modifier} onChange={(e) => { setOverUnder(e.target.value) }}>
                            <option value='1'>Over</option>
                            <option value='-1'>Under</option>
                          </select>
                        </div>
                        <SearchableDropdown
                          options={totals_options}
                          value={bet_a_handicap}
                          onChange={_setBetAHandicap}
                          />
                      </div>
                      <div className='bet-details'>
                        <div>
                          <select value={over_under_modifier} onChange={(e) => { setOverUnder(e.target.value) }}>
                            <option value='-1'>Over</option>
                            <option value='1'>Under</option>
                          </select>
                        </div>
                        <SearchableDropdown
                          options={totals_options}
                          value={bet_b_handicap}
                          onChange={_setBetBHandicap}
                          />
                      </div>
                    </div>
                  }

                  {currentPlayerPropBetType() && currentPlayerPropBetType().name.toLowerCase() === "other" &&
                    <div className='other-container'>
                      <div className='bet-details'>
                        <input type='text' value={bet_a_player_prop_other_condition} onChange={(e) => setBetAPlayerPropOtherCondition(e.target.value)} placeholder="Condition for Bet A to win" />
                      </div>
                      <div className='bet-details'>
                        <input type='text' value={bet_b_player_prop_other_condition} onChange={(e) => setBetBPlayerPropOtherCondition(e.target.value)} placeholder="Condition for Bet B to win" />
                      </div>
                    </div>
                  }
                </div>
              }
            </div>
          }

          {betConditionTypes &&
            currentBetCondition() &&
            (currentBetCondition().name.toLowerCase() === 'other' &&
              <div className='other-container'>
                <div className='bet-details'>
                  <input type='text' value={bet_a_other_condition} onChange={(e) => setBetAOtherCondition(e.target.value)} placeholder="Condition for Bet A to win" />
                </div>
                <div className='bet-details'>
                  <input type='text' value={bet_b_other_condition} onChange={(e) => setBetBOtherCondition(e.target.value)} placeholder="Condition for Bet B to win" />
                </div>
              </div>
            )
          }
          <input type='button' onClick={_addMatchedBets} value='Add Bets to Tracker' />
        </div>

      }
    </div>
  )
}

export default MatchedBetConditions;