
import {currencyFormatter, roundedWagers} from "../../utils/BetUtils";
import React, {useEffect, useState, useRef} from "react";
import {useModal} from "../modal/ModalContext";

const SuggestedWagers = ({ wager_a_data, wager_b_data, suggestedWagersSelected }) => {
  const [rounded_wagers, setRoundWagers] = useState([]);
  const {hideModal, showModal} = useModal();

  useEffect(() => {
    if (wager_a_data && wager_b_data) {
      setRoundWagers(roundedWagers(wager_a_data, wager_b_data));
    }
  }, [wager_a_data, wager_b_data]);

  return (
    <div>
      <h2>Select a pair of wagers</h2>
      {rounded_wagers && rounded_wagers.length > 0 && rounded_wagers.map((wager, index) => (
        <div key={`1_${index}`}>
          <span className={'clickable'} onClick={() => { suggestedWagersSelected(wager[0],Math.round(wager[1]))}}>{currencyFormatter.format(wager[0])}/{currencyFormatter.format(Math.round(wager[1]))}</span>
        </div>
      ))}
    </div>
  )

}

export default SuggestedWagers;