import { useEffect, useState } from 'react';
import { currencyFormatter } from "../utils/BetUtils";
import './BalanceSheet.css'
import {fetchCurrentUser, fetchExchangeRate} from "../utils/ApiUtils";

const BalanceSheet = ({balance_sheet}) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [exchange_rates, setExchangeRates] = useState({}); //{currency_code: exchange_rate}

  useEffect(() => {
    fetchCurrentUser(setCurrentUser);
  }, []);

  useEffect(() => {
    if (!currentUser) { return }

    const currencies = balance_sheet.map((sportsbook_signup) => {
      return sportsbook_signup.currency.code;
    });
    const unique_currencies = [...new Set(currencies)];
    const base_currency = currentUser.base_currency.code;

    unique_currencies.forEach((currency) => {
      if (currency !== base_currency) {
        fetchExchangeRate(currency, base_currency, (exchange_rate) => {
          setExchangeRates((prevExchangeRates) => {
            return {...prevExchangeRates, [`${currency}${base_currency}`]: exchange_rate};
          });
        });
      }
    });
  }, [balance_sheet, currentUser]);

  const exchangeRate = (from, to) => {
    if (`${from}${to}` in exchange_rates) {
      return exchange_rates[`${from}${to}`];
    }
    return 1.0;
  }

  return (
    <div className="balance-sheet">
      <h1>Balance Sheet</h1>
      <table id='balance-sheet'>
        <thead>
          <tr>
            <th>Sportsbook</th>
            <th>Currency</th>
            <th>Deposits</th>
            <th>Withdrawals</th>
            <th>Net Deposits</th>
            <th>Total Real Money Wagers</th>
            <th>Total Free-Bet Wagers</th>
            <th>Free-Bets Balance</th>
            <th>Real Money Balance</th>
            <th>Net Profit/Loss</th>
          </tr>
        </thead>
        <tbody>
          {balance_sheet && balance_sheet.map((sportsbook_signup) => (
            sportsbook_signup &&
            <tr key={sportsbook_signup.id}>
              <td>{sportsbook_signup.label || sportsbook_signup.sportsbook.name}</td>
              <td>{sportsbook_signup.currency.code}</td>
              <td>{currencyFormatter.format(sportsbook_signup.total_real_money_deposits)}</td>
              <td>{currencyFormatter.format(sportsbook_signup.total_withdrawals)}</td>
              <td>{currencyFormatter.format(sportsbook_signup.total_real_money_deposits - sportsbook_signup.total_withdrawals)}</td>
              <td>{currencyFormatter.format(sportsbook_signup.total_real_money_wagers)}</td>
              <td>{currencyFormatter.format(sportsbook_signup.total_free_bet_wagers)}</td>
              <td>{currencyFormatter.format(sportsbook_signup.free_bets_balance)}</td>
              <td>{currencyFormatter.format(sportsbook_signup.real_money_balance)}</td>
              <td>{currencyFormatter.format(sportsbook_signup.net_profit_loss)}</td>
            </tr>
          ))}
          {balance_sheet &&
            <tr className={'totalsRow'}>
              <td>Total {currentUser && currentUser.base_currency.code}</td>
              <td>-</td>
              <td>{currencyFormatter.format(balance_sheet.reduce((total, sportsbook_signup) => total + sportsbook_signup.total_deposits * exchangeRate(sportsbook_signup.currency.code, currentUser.base_currency.code), 0))}</td>
              <td>{currencyFormatter.format(balance_sheet.reduce((total, sportsbook_signup) => total + sportsbook_signup.total_withdrawals * exchangeRate(sportsbook_signup.currency.code, currentUser.base_currency.code), 0))}</td>
              <td>{currencyFormatter.format(balance_sheet.reduce((total, sportsbook_signup) => total + sportsbook_signup.total_deposits * exchangeRate(sportsbook_signup.currency.code, currentUser.base_currency.code) - sportsbook_signup.total_withdrawals * exchangeRate(sportsbook_signup.currency.code, currentUser.base_currency.code), 0))}</td>
              <td>{currencyFormatter.format(balance_sheet.reduce((total, sportsbook_signup) => total + sportsbook_signup.total_real_money_wagers * exchangeRate(sportsbook_signup.currency.code, currentUser.base_currency.code), 0))}</td>
              <td>{currencyFormatter.format(balance_sheet.reduce((total, sportsbook_signup) => total + sportsbook_signup.total_free_bet_wagers * exchangeRate(sportsbook_signup.currency.code, currentUser.base_currency.code), 0))}</td>
              <td>{currencyFormatter.format(balance_sheet.reduce((total, sportsbook_signup) => total + sportsbook_signup.free_bets_balance * exchangeRate(sportsbook_signup.currency.code, currentUser.base_currency.code), 0))}</td>
              <td>{currencyFormatter.format(balance_sheet.reduce((total, sportsbook_signup) => total + sportsbook_signup.real_money_balance * exchangeRate(sportsbook_signup.currency.code, currentUser.base_currency.code), 0))}</td>
              <td>{currencyFormatter.format(balance_sheet.reduce((total, sportsbook_signup) => total + sportsbook_signup.net_profit_loss * exchangeRate(sportsbook_signup.currency.code, currentUser.base_currency.code), 0))}</td>
            </tr>
          }
        </tbody>
      </table>
      {/*exchange_rates && Object.keys(exchange_rates).length > 0 &&
        <div className={'exchange-rates'}>
          <h2>Exchange Rates</h2>
          <table id='exchange-rates'>
            <thead>
              <tr>
                <th>From</th>
                <th>To</th>
                <th>Rate</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(exchange_rates).map((exchange_rate_key) => (
                <tr key={exchange_rate_key}>
                  <td>{exchange_rate_key.substring(0, 3)}</td>
                  <td>{exchange_rate_key.substring(3, 6)}</td>
                  <td>{exchange_rates[exchange_rate_key]}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      */}
    </div>
  )
}

export default BalanceSheet;