import {useEffect, useState} from "react";
import './BetPayoutSummary.css'
import {betBonusTypeIdIsRiskFreeBet, betBonusTypeIdIsFreeBet, convertOddsToDecimal} from "../utils/BetUtils";

const BetPayoutSummary = ({wager_data}) => {
  const [display_odds, setDisplayOdds] = useState(100);
  const [winnings, setWinnings] = useState(0);
  const [stake_returned, setStakeReturned] = useState(0);

  useEffect(() => {
    const odds = +(wager_data.odds);
    if (odds > 0) {
      setDisplayOdds(`+${odds}`);
    } else {
      setDisplayOdds(`${odds}`);
    }
  }, [wager_data.odds]);

  useEffect(() => {
    let winnings = wager_data.wager * convertOddsToDecimal(wager_data.odds) - wager_data.wager;
    let stake_returned = wager_data.wager;
    if (betBonusTypeIdIsFreeBet(wager_data.bet_bonus_type)) {
      winnings -= wager_data.wager;
      stake_returned = 0;
    }
    setWinnings(winnings);
    setStakeReturned(stake_returned);
  }, [wager_data]);


  return (
    <table className='bet-payout-summary'>
      <thead>
      <tr>
        <td>
          {wager_data.sportsbook_signup && wager_data.sportsbook_signup.name}
        </td>
        <td>
          {wager_data.sportsbook_signup && wager_data.sportsbook_signup.currency}
        </td>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>
          Wager:
        </td>
        <td>
          ${(+(wager_data.wager)).toFixed(2)}
        </td>
      </tr>
      <tr>
        <td>
          Odds:
        </td>
        <td>
          {display_odds}
        </td>
      </tr>
      <tr>
        <td>
          Winnings:
        </td>
        <td>
          ${(+winnings).toFixed(2)}
        </td>
      </tr>
      <tr>
        <td>
          Stake returned:
        </td>
        <td>
          ${(+stake_returned).toFixed(2)}
        </td>
      </tr>
      <tr className='totals-row'>
        <td>
          Payout on win:
        </td>
        <td>
          ${(+(wager_data.payout)).toFixed(2)}
        </td>
      </tr>
      {betBonusTypeIdIsRiskFreeBet(wager_data.bet_bonus_type) && wager_data.expected_bonus_conversion_on_loss &&
        <tr>
        <td>
          Bonus conversion <span className={'material-symbols-outlined md-18'} data-tooltip-id="main-tooltip" data-tooltip-content="The estimated amount of the bonus returned in sportsbook A that will be converted to cash if the bet loses.">help_outline</span>:
        </td>
        <td>
          ${(+(wager_data.expected_bonus_conversion_on_loss)).toFixed(2)}
        </td>
      </tr>
      }
      </tbody>
    </table>
  )
}

export default BetPayoutSummary;