import moment from "moment";

export const getAverageMoneylineDecimalOdds = (team_name, bookmakers) => {
  let runningTotalOdds = 0.0;
  let counter = 0;

  for (let i = 0; i < bookmakers.length; i++) {
    const bookmaker = bookmakers[i];
    const bookmaker_name = bookmaker['title'];
    const markets = bookmaker['markets'];
    for (let u = 0; u < markets.length; u++) {
      const market = markets[u];
      if (market.key === "h2h") {
        const outcomes = market['outcomes'];
        for (let v = 0; v < outcomes.length; v++) {
          const outcome = outcomes[v];
          if (outcome['name'].toLowerCase() === team_name.toLowerCase()) {
            runningTotalOdds += outcome['price'];
            counter += 1;
          }
        }
      }
    }
  }

  return counter === 0 ? null : (runningTotalOdds / counter).toFixed(2);
}

export const moneylineFavouriteTeam = (team_a, team_b, bookmakers) => {
  const team_a_name = team_a.name;
  const team_b_name = team_b.name;
  return getAverageMoneylineDecimalOdds(team_a_name, bookmakers) <=
    getAverageMoneylineDecimalOdds(team_b_name, bookmakers) ? team_a : team_b;
}
export const moneylineUnderdogTeam = (team_a, team_b, bookmakers) => {
  const team_a_name = team_a.name;
  const team_b_name = team_b.name;
  return getAverageMoneylineDecimalOdds(team_a_name, bookmakers) <=
    getAverageMoneylineDecimalOdds(team_b_name, bookmakers) ? team_b : team_a;
}
export const totalsOptions = () => {
  let ret = [];
  let score = 0.0;
  while (score <= 400.0) {
    score += 0.5;
    ret.push({
      label: score,
      value: score
    });
  }
  return ret;
};
export const spreadOptions = () => {
  let ret = [];
  let score = 0.5;
  while (score <= 100.0) {
    ret.push({
      label: `+${score}`,
      value: score
    });
    ret.push({
      label: `-${score}`,
      value: -score
    });
    score += 0.25;
  }
  return ret;
};

export const americanToDecimalOdds = (american_odds) => {
  //Converts american odds to decimal odds.

  american_odds = +american_odds;
  if (american_odds > 0) {
    return ((american_odds + 100)/100);
  } else {
    return (-american_odds + 100)/-american_odds;
  }
}

export const decimalToAmericanOdds = (decimal_odds) => {
  const retfunc = () => {
    if (decimal_odds >= 2.0) {
      return (decimal_odds * 100) - 100;
    } else {
      return -100 / (decimal_odds-1);
    }
  }
  const ret = (retfunc()).toFixed(0);
  if (ret > 0) {
    return `+${ret}`
  } else {
    return `${ret}`
  }
}

export const expectedBonusConverionOnALoss = (wager_a_data) => {
  if (betBonusTypeIdIsRiskFreeBet(wager_a_data.bet_bonus_type)) {
    return Math.min(wager_a_data.wager * (wager_a_data.risk_free_percentage_returned / 100), wager_a_data.risk_free_max_value_returned === 0 ? wager_a_data.wager * (wager_a_data.risk_free_percentage_returned / 100) : wager_a_data.risk_free_max_value_returned) * (wager_a_data.risk_free_estimated_conversion_rate / 100);
  }
  return 0;
}


export const matchedBPayout = (wager_a_data) => {
  const er = wager_a_data.exchange_rate ? wager_a_data.exchange_rate : 1.0;
  let b_payout = wager_a_data.payout;
  if (wager_a_data.bet_bonus_type && betBonusTypeIdIsFreeBet(wager_a_data.bet_bonus_type)) {
    b_payout -= wager_a_data.wager;
  } else if (wager_a_data.bet_bonus_type && betBonusTypeIdIsRiskFreeBet(wager_a_data.bet_bonus_type)) {
    b_payout -= Math.min(wager_a_data.wager * (wager_a_data.risk_free_percentage_returned / 100.0), wager_a_data.risk_free_max_value_returned=== 0 ? wager_a_data.wager * (wager_a_data.risk_free_percentage_returned / 100.0) : wager_a_data.risk_free_max_value_returned) * (wager_a_data.risk_free_estimated_conversion_rate / 100.0);
  }
  b_payout *= er;
  return +(b_payout.toFixed(2));
}


export const roundedWagers = (wager_a_data, wager_b_data) => {
  let ret = [];
  let check_a_wager = 5.0;
  let check_a_payout = check_a_wager * convertOddsToDecimal(wager_a_data.odds);
  if (betBonusTypeIdIsFreeBet(+wager_a_data.bet_bonus_type)) {
    check_a_payout = check_a_wager * (convertOddsToDecimal(wager_a_data.odds) - 1.0);
  }

  let matched_b_payout = matchedBPayout({
      ...wager_a_data,
      wager: check_a_wager,
      payout: check_a_payout
    });
  let check_b_wager = matched_b_payout / convertOddsToDecimal(wager_b_data.odds);

  let counter = 0;
  while (ret.length < 100 && counter < 1000) {
    if (check_b_wager % 5 < 0.5 || 5-(check_b_wager % 5) < 0.5) {
      ret.push([check_a_wager, check_b_wager]);
    }
    check_a_wager += 5.0;
    check_a_payout = check_a_wager * convertOddsToDecimal(wager_a_data.odds);
    if (betBonusTypeIdIsFreeBet(+wager_a_data.bet_bonus_type)) {
      check_a_payout = check_a_wager * (convertOddsToDecimal(wager_a_data.odds) - 1.0);
    }

    matched_b_payout = matchedBPayout({
      ...wager_a_data,
      wager: check_a_wager,
      payout: check_a_payout
    });
    check_b_wager = matched_b_payout / convertOddsToDecimal(wager_b_data.odds);
    counter += 1;
  }

  return ret;
}

export const betHasUnresolvedAutoGradingError = (bet) => {
  for (let i in bet.conditions) {
    const bc = bet.conditions[i];
    if (bc.auto_graded === false && bc.manual_graded === false && bc.pinnacle_event.settled === true) {
      return true;
    }
  }
  return false;
}

export const matchedBetHasUnresolvedAutoGradingError = (matched_bet) => {
  return betHasUnresolvedAutoGradingError(matched_bet.bet_a) || betHasUnresolvedAutoGradingError(matched_bet.bet_b);
}

/*
export const roundedWagers = (start_wager, wager_a_data, wager_b_data) => {

  if (!wager_a_data || !wager_b_data || !wager_a_data.odds || !wager_b_data.odds) {
    return [];
  }

  let odds_a = wager_a_data.odds;
  let odds_b = wager_b_data.odds;
  let bet_bonus_type = wager_a_data.bet_bonus_type;
  let exchange_rate = wager_a_data.exchange_rate;
  bet_bonus_type = bet_bonus_type || 0;
  start_wager = start_wager || 5.0;
  let current_value = start_wager - (start_wager % 5);
  if (current_value < 0) { current_value = 0 }
  exchange_rate = exchange_rate || 1.0;


  let b_payout_modifier = 0;
  if (betBonusTypeIdIsFreeBet(bet_bonus_type)) {
    //Free-Bet (no stake returned)
    b_payout_modifier = wager_a_data.wager;
  } else if (betBonusTypeIdIsRiskFreeBet(bet_bonus_type)) {
    b_payout_modifier = expectedBonusConverionOnALoss(wager_a_data);
  }

  odds_a = +odds_a;
  odds_b = +odds_b;
  bet_bonus_type = +bet_bonus_type;
  const setCheckPayout = (current_value) => {

    if (betBonusTypeIdIsFreeBet(bet_bonus_type)) {
      //Free-Bet (no stake returned)
      return (current_value * americanToDecimalOdds(odds_a) - current_value).toFixed(2);
    } else {
      //All other bet types return full stake?
      return (current_value * americanToDecimalOdds(odds_a)).toFixed(2);
    }
  }

  let ret_above = [];
  let ret_below = [];
  let counter = 0;
  while (ret_above.length < 6 && counter < 100) {
    const checkAPayout = setCheckPayout(current_value);
    const checkBWager =  +(((checkAPayout - b_payout_modifier) / americanToDecimalOdds(odds_b)) * exchange_rate).toFixed(2);
    if (checkBWager % 5 < 0.5) {
      ret_above.push([current_value, checkBWager]);
    } else {
      if (5-(checkBWager % 5) < 0.5) {
        ret_above.push([current_value, checkBWager]);
      }
    }
    counter += 1;
    current_value += 5;
  }
  current_value = start_wager - (start_wager % 5);
  current_value -= 5;
  counter = 0;
  if (current_value < 15) { return [ret_above, ret_below] }
  while (ret_below.length < 6 && counter < 100 && current_value > 0) {
    const checkAPayout =  setCheckPayout(current_value);
    const checkBWager =  +(((checkAPayout - b_payout_modifier) / americanToDecimalOdds(odds_b)) * exchange_rate).toFixed(2);
    if (checkBWager % 5 <= 0.5) {
      ret_below.push([current_value, checkBWager]); //(checkAPayout / americanToDecimalOdds(odds_b)).toFixed(0)]);
    } else {
      if (5-(checkBWager % 5) <= 0.5) {
        ret_below.push([current_value, checkBWager]); //(checkAPayout / americanToDecimalOdds(odds_b)).toFixed(0)]);
      }
    }
    counter += 1;
    current_value -= 5;
  }
  return [ret_above, ret_below];
}

 */

export const determineOddsFormat = (odds) => {
  odds = `${odds}`;
  if (odds.includes('/')) {
    return 'fractional';
  } else if (odds.includes('.')) {
    return 'decimal';
  } else {
    return 'american';
  }
}

export const convertOddsToDecimal = (odds) => {
  const odds_format = determineOddsFormat(odds);
  if (odds_format === 'fractional') {
    const split_odds = odds.split('/');
    return (+split_odds[0] / +split_odds[1]) + 1;
  } else if (odds_format === 'american') {
    return americanToDecimalOdds(odds);
  } else {
    return +odds;
  }
}

const gcd = (a, b) => {
  if (b === 0) return a;
  return gcd(b, a % b);
};

export const decimalOddsToFractional = (decimal_odds) => {
  const decimal_odds_float = +decimal_odds;
  const numerator = decimal_odds_float - 1;
  const denominator = 1;
  const greatestCommonDivisor = gcd(numerator, denominator);

  const simplifiedNumerator = numerator / greatestCommonDivisor;
  const simplifiedDenominator = denominator / greatestCommonDivisor;

  return `${simplifiedNumerator}/${simplifiedDenominator}`;
};


export const convertOdds = (odds, to_odds_format) => {
  const decimal_odds = convertOddsToDecimal(odds);
  if (to_odds_format === 'american') {
    return decimalToAmericanOdds(decimal_odds);
  }
  if (to_odds_format === 'decimal') {
    return decimal_odds;
  }
  if (to_odds_format === 'fractional') {
    return decimalOddsToFractional(decimal_odds);
  }
}


// Create a currency formatter with your desired locale and options
export const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});
export const noPenniesCurrencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0
});

// Create a percentage formatter with your desired locale and options
export const percentageFormatter = new Intl.NumberFormat('en-US', {
  style: 'percent',
  minimumFractionDigits: 0, // Optional: specify the minimum number of fraction digits to display
  maximumFractionDigits: 2, // Optional: specify the maximum number of fraction digits to display
});
// Create a percentage formatter with your desired locale and options
export const noDecimalPercentageFormatter = new Intl.NumberFormat('en-US', {
  style: 'percent',
  minimumFractionDigits: 0, // Optional: specify the minimum number of fraction digits to display
  maximumFractionDigits: 0, // Optional: specify the maximum number of fraction digits to display
});

// A function that returns a description for a bet, based on the first condition
// (Assuming each bet has at least one condition)
export const descriptionForBet = (bet) => {
  return descriptionForBetCondition(bet.conditions[0]);
}

// A helper function that returns a description for a single condition of a bet
export const descriptionForBetCondition = (betCondition, betConditionTypes) => {
  if (!betCondition) { return '' }
  if (!betConditionTypes || betConditionTypes.length == 0) { return '' }
  const t = betConditionTypes.filter((bct) => {
    if (bct.id === betCondition.bet_condition_type) {
      return true;
    }
  })[0];

  const type = t.name.toLowerCase();

  // If the bet type is moneyline, return the name of the linked team
  if (type === 'moneyline') {
    return betCondition.team_name;
  }

  // If the bet type is spread or team total, return the linked team name and handicap
  if (type === 'spread') {
    const handicap = betCondition.handicap > 0 ? `+${betCondition.handicap}` : betCondition.handicap;
    return `${betCondition.team_name} ${handicap}`
  }
  if (type === 'total') {
    const handicap = betCondition.handicap > 0 ? `Over ${+betCondition.handicap}` : `Under ${-betCondition.handicap}`;
    return `${handicap}`
  }
  if (type === 'team total') {
    const handicap = betCondition.handicap > 0 ? `Over ${+betCondition.handicap}` : `Under ${-betCondition.handicap}`;
    return `${betCondition.team_name} ${handicap}`
  }
  if (type === 'player prop') {
    const handicap = betCondition.handicap > 0 ? `Over ${+betCondition.handicap}` : `Under ${-betCondition.handicap}`;
    return `${betCondition.player_name} ${handicap} ${betCondition.player_prop_bet_type.name}`
  }
  if (type === 'other') {
    return `${betCondition.condition}`
  }

  // If the bet type is not recognized, return the type as a string
  return `${type}`
}

export const eventTitleForBet = (bet) => {
  return `${bet.conditions[0].pinnacle_event.away} vs. ${bet.conditions[0].pinnacle_event.home}`;
}
export const eventTitleForEvent = (event) => {
  return `${event.away} vs. ${event.home}`;
}


export const dateTimeFormatted = (datetime) => {
  datetime = new Date(datetime);
  return datetime.toLocaleDateString() + ' ' + datetime.toLocaleTimeString();
}

export const timeFormatted = (datetime) => {
  datetime = new Date(datetime);
  return datetime.toLocaleTimeString();
}

export const getMainlineOdds = (pinnacleEvent, pinnaclePeriod, type) => {
  let oddsList = null;
  if (type === 'moneyline') {
    oddsList = pinnacleEvent.moneyline_odds;
  } else if (type === 'spread') {
    oddsList = pinnacleEvent.spread_odds;
  } else if (type === 'totals') {
    oddsList = pinnacleEvent.totals_odds;
  } else if (type === 'team_total') {
    oddsList = pinnacleEvent.team_total_odds;
    return oddsList;
  }
  if (oddsList === null) { return null; }

  const periodId = pinnaclePeriod.id;

  let maxDate = null;
  for (const oddsKey in oddsList) {
    const odds = oddsList[oddsKey];
    if (odds.period !== periodId) { continue; }

    const oddsDate = moment(odds.last_seen)
    if (maxDate === null) {
      maxDate = oddsDate;
    }

    if (oddsDate.isAfter(maxDate)) {
      maxDate = oddsDate;
    }
  }

  const recentOdds = [];
  for (const oddsKey in oddsList) {
    const odds = oddsList[oddsKey];
    if (odds.period !== periodId) { continue; }

    const oddsDate = moment(odds.last_seen)
    if (oddsDate.isSame(maxDate)) {
      recentOdds.push(odds);
    }
  }

  let mainlineOdds = null;
  for (const oddsKey in recentOdds) {
    const odds = recentOdds[oddsKey];
    if (mainlineOdds === null) {
      mainlineOdds = odds;
    }

    if (Math.abs(+(odds.over) - +(odds.under)) <  Math.abs(+(mainlineOdds.over) - +(mainlineOdds.under))) {
      mainlineOdds = odds;
    }
  }

  return mainlineOdds;
}


export const matchedBetIsMiddleThatIsTracking = (matchedBet) => {
  if (matchedBetIsMiddle(matchedBet)) {
    if (matchedBet.bet_a.bet_status.name.toLowerCase() === `open` && matchedBet.bet_b.bet_status.name.toLowerCase() === `open`) {
      const type = matchedBet.bet_a.conditions[0].bet_condition_type;

      if (type === 3) {
        const mainlineOdds = getMainlineOdds(matchedBet.bet_a.conditions[0].pinnacle_event, matchedBet.bet_a.conditions[0].pinnacle_period, 'totals')

        if (mainlineOdds === null) {
          return false;
        }

        const mainlineTarget = +(mainlineOdds.points);
        const lowerScore = Math.abs(+(matchedBet.bet_a.conditions[0].handicap)) < Math.abs(+(matchedBet.bet_b.conditions[0].handicap)) ? Math.abs(+(matchedBet.bet_a.conditions[0].handicap)) : Math.abs(+(matchedBet.bet_b.conditions[0].handicap));
        const higherScore = Math.abs(+(matchedBet.bet_a.conditions[0].handicap)) < Math.abs(+(matchedBet.bet_b.conditions[0].handicap)) ? Math.abs(+(matchedBet.bet_b.conditions[0].handicap)) : Math.abs(+(matchedBet.bet_a.conditions[0].handicap));

        if (mainlineTarget >= lowerScore - 2.0 && mainlineTarget <= higherScore + 2.0) { //This hard coding is meh... only works for basketball
          return true;
        }
      }
    }
  }
  return false;

}

export const matchedBetWonBothSides = (matchedBet) => {
  if (matchedBet.bet_a.bet_status.name.toLowerCase() === 'win' && matchedBet.bet_b.bet_status.name.toLowerCase() === 'win') {
    return true;
  }
  return false;
}

export const matchedBetIsMiddleThatHit = (matchedBet) => {
  if (matchedBetIsMiddle(matchedBet)) {
    if (matchedBet.bet_a.bet_status.name.toLowerCase() !== `open` && matchedBet.bet_b.bet_status.name.toLowerCase() !== `open`) {
      if (matchedBet.bet_a.bet_status.name.toLowerCase() !== `loss` && matchedBet.bet_b.bet_status.name.toLowerCase() !== `loss`) {
        if (matchedBet.bet_a.bet_status.name.toLowerCase() === 'half-win/half-push' && matchedBet.bet_b.bet_status.name.toLowerCase() !== 'half-loss/half-push') {
          return true;
        } else if (matchedBet.bet_a.bet_status.name.toLowerCase() === 'half-loss/half-push' && matchedBet.bet_b.bet_status.name.toLowerCase() !== 'half-win/half-push') {
          return true;
        } else if (matchedBet.bet_a.bet_status.name.toLowerCase() !== 'push/void' || matchedBet.bet_b.bet_status.name.toLowerCase() !== 'push/void') {
          return true;
        }
      }
    }
  }
  return false;
}

export const matchedBetIsMiddle = (matched_bet) => {
  const type = matched_bet.bet_a.conditions[0].bet_condition_type;

  if (type === 3 || type === 4 || type === 5) {
    if (Math.abs(matched_bet.bet_a.conditions[0].handicap) !== Math.abs(matched_bet.bet_b.conditions[0].handicap)) {
      //Either a middle or an outtie
      if (matched_bet.bet_a.conditions[0].handicap > 0 && matched_bet.bet_a.conditions[0].handicap < Math.abs(matched_bet.bet_b.conditions[0].handicap)) {
        return true;
      } else if (matched_bet.bet_a.conditions[0].handicap < 0 && Math.abs(matched_bet.bet_a.conditions[0].handicap) > matched_bet.bet_b.conditions[0].handicap) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } else if (type === 2) {
    if (Math.abs(matched_bet.bet_a.conditions[0].handicap) !== Math.abs(matched_bet.bet_b.conditions[0].handicap)) {
      //Either a middle or an outtie
      if (matched_bet.bet_a.conditions[0].handicap > 0 && matched_bet.bet_a.conditions[0].handicap > Math.abs(matched_bet.bet_b.conditions[0].handicap)) {
        return true;
      } else if (matched_bet.bet_a.conditions[0].handicap < 0 && Math.abs(matched_bet.bet_a.conditions[0].handicap) < matched_bet.bet_b.conditions[0].handicap) {
        return true;
      } else {
        return false;
      }
    }
  }
}

export const middleOutcomes = (matched_bet) => {
  let ret = [];
  if (!matched_bet) {
    return [];
  }

  if (!matchedBetIsMiddle(matched_bet)) {
    return [];
  }
  //Bet is a middle, so we can figure out the outcomes
  //Assume that a push is a win for either side.
  const a_handicap = +(matched_bet.bet_a.conditions[0].handicap);
  const b_handicap = +(matched_bet.bet_b.conditions[0].handicap);

  if (matched_bet.bet_a.conditions[0].bet_condition_type === 3 || matched_bet.bet_a.conditions[0].bet_condition_type === 4) {
    if (a_handicap === Math.trunc(a_handicap)) {
      let desc = `Exactly ${Math.abs(a_handicap)}`
      if (matched_bet.bet_a.conditions[0].bet_condition_type === 3) {
        desc += ` Total Combined Points`;
      } else {
        desc += ` Total Points by ${matched_bet.bet_a.conditions[0].team_name}`;
      }

      ret.push({
        required_outcome: desc,
        description: 'Bet B Wins, Bet A Pushes',
        extra_winnings: `Win an extra ${currencyFormatter.format(matched_bet.bet_a.wager_amount)}`,
      });
    }
    if (b_handicap === Math.trunc(b_handicap)) {
      let desc = `Exactly ${Math.abs(b_handicap)}`
      if (matched_bet.bet_a.conditions[0].bet_condition_type === 3) {
        desc += ` Total Combined Points`;
      } else {
        desc += ` Total Points by ${matched_bet.bet_a.conditions[0].team_name}`;
      }

      ret.push({
        required_outcome: desc,
        description: 'Bet A Wins, Bet B Pushes',
        extra_winnings: `Win an extra ${currencyFormatter.format(matched_bet.bet_b.wager_amount)}`,
      });
    }

    let full_point_delta = Math.abs(Math.abs(a_handicap) - Math.abs(b_handicap));
    //If the spread is either more than a full point, or it is exactly a full point and both handicaps are not integers, then we can have a middle
    if (full_point_delta > 1 || (full_point_delta === 1 && (Math.floor(a_handicap) !== a_handicap || Math.floor(b_handicap) !== b_handicap))) {
      if (matched_bet.bet_a.conditions[0].bet_condition_type === 3 || matched_bet.bet_a.conditions[0].bet_condition_type === 4) {
        //Start at the lower of the two numbers, round down to the nearest integer, and then add 1
        let starting_point = Math.floor(Math.abs(Math.min(Math.abs(a_handicap), Math.abs(b_handicap)))) + 1;
        let desc = `Exactly ${starting_point}`;

        //Create a text list of the possible full point middle outcomes
        while (full_point_delta >= 2) {
          desc += full_point_delta > 1.5 ? `, ${starting_point}` : ` or ${starting_point}`;
          starting_point += 1;
          full_point_delta -= 1;
        }

        if (matched_bet.bet_a.conditions[0].bet_condition_type === 3) {
          desc += ` Total Combined Points`;
        } else {
          desc += ` Total Points by ${matched_bet.bet_a.conditions[0].team_name}`;
        }
        const conversions = matchedBetConversionRate(matched_bet);

        ret.push({
          required_outcome: desc,
          description: `Both Bets Win`,
          extra_winnings: `Win a total of ${currencyFormatter.format(matched_bet.bet_a.possible_net_winnings + matched_bet.bet_b.possible_net_winnings)}`,
        });
      }
    }

  } else if (matched_bet.bet_a.conditions[0].bet_condition_type === 2) {
    //Spread
    if (a_handicap > 0 &&
      b_handicap < 0 &&
      Math.abs(a_handicap) > Math.abs(b_handicap)) {
      //Bet a has + handicap, bet b has - handicap
      //Bet A has a higher absolute value handicap than bet B
      //So B has to win by some specific amounts to get the middle to hit

      //Here we check for a push middle on the bet A side
      if (Math.trunc(a_handicap) === a_handicap) {
        //Bet a is a full number and bet b handicap is less than bet a handicap
        //So one way a middle can hit is if the team B wins by exactly the handicap of bet A
        //This would make bet A push, and bet B win.

        let desc = `${matched_bet.bet_b.conditions[0].team_name}`;
        desc += ` wins by exactly ${Math.abs(a_handicap)} points`

        ret.push({
          required_outcome: desc,
          description: 'Bet B Wins, Bet A Pushes',
          extra_winnings: `Win an extra ${currencyFormatter.format(matched_bet.bet_a.wager_amount)}`,
        });
      }

      //Here we check for a push middle on the bet B side
      if (Math.trunc(b_handicap) === b_handicap) {
        //Bet b handicap is a full number and bet b handicap is greater than bet a handicap
        //So one way a middle can hit is if the team B wins by exactly the handicap of bet b
        //This would make bet A push, and bet B win.

        let desc = `${matched_bet.bet_b.conditions[0].team_name}`;
        desc += ` wins by ${Math.abs(b_handicap)} points`

        ret.push({
          required_outcome: desc,
          description: 'Bet A Wins, Bet B Pushes',
          extra_winnings: `Win an extra ${currencyFormatter.format(matched_bet.bet_b.wager_amount)}`,
        });
      }


      //Full point middles
      let b_handicap_absolute = Math.trunc(Math.abs(b_handicap));
      if (b_handicap_absolute !== Math.trunc(b_handicap_absolute)) {
        //Here B handicap is not a whole number, so we round up to the next whole number to start
        b_handicap_absolute = Math.trunc(b_handicap_absolute) + 1;
      } else {
        //Here B handicap is already a whole number, and we already took care of this middle case above, so we add 1.
        b_handicap_absolute += 1;
      }
      let a_handicap_absolute = Math.abs(a_handicap);

      if (b_handicap_absolute < a_handicap) {
        //If the absolute value of bet B, which is the negative number, is less than the value of bet A
        // then we have at least one full point middle

        let desc = `${matched_bet.bet_b.conditions[0].team_name}`;
        desc += ` wins by `
        let fullMiddleCount = 0;
        while (b_handicap_absolute < a_handicap) {
          if (fullMiddleCount === 0) {
            desc += `${b_handicap_absolute}`;
          } else {
            desc += a_handicap_absolute - b_handicap_absolute > 1 ? `, ${b_handicap_absolute}` : ` or ${b_handicap_absolute}`;
          }

          b_handicap_absolute += 1;
          fullMiddleCount += 1;
        }
        desc += ` points`;

        ret.push({
          required_outcome: desc,
          description: 'Both Bets Win',
          extra_winnings: `Win a total of ${currencyFormatter.format(matched_bet.bet_a.possible_net_winnings + matched_bet.bet_b.possible_net_winnings)}`,
        });
      }
    } else if (a_handicap < 0 &&
      b_handicap > 0 &&
      Math.abs(a_handicap) < Math.abs(b_handicap)) {
      //Bet a has - handicap, bet b has + handicap


      //Here we check for a push middle on the bet A side
      if (Math.trunc(a_handicap) === a_handicap) {
        //Bet a is a full number and bet b handicap is greater than bet a handicap
        //So one way a middle can hit is if the team B wins by exactly the handicap of bet A
        //This would make bet A push, and bet B win.

        let desc = `${matched_bet.bet_a.conditions[0].team_name}`;
        desc += ` wins by ${Math.abs(a_handicap)} points`

        ret.push({
          required_outcome: desc,
          description: 'Bet B Wins, Bet A Pushes',
          extra_winnings: `Win an extra ${currencyFormatter.format(matched_bet.bet_a.wager_amount)}`,
        });
      }

      //Here we check for a push middle on the bet B side
      if (Math.trunc(b_handicap) === b_handicap) {
        //Bet b handicap is a full number and bet b handicap is greater than bet a handicap
        //So one way a middle can hit is if the team B wins by exactly the handicap of bet b
        //This would make bet A push, and bet B win.

        let desc = `${matched_bet.bet_a.conditions[0].team_name}`;
        desc += ` wins by ${Math.abs(b_handicap)} points`

        ret.push({
          required_outcome: desc,
          description: 'Bet A Wins, Bet B Pushes',
          extra_winnings: `Win an extra ${currencyFormatter.format(matched_bet.bet_b.wager_amount)}`,
        });
      }

      //Full point middles
      let a_handicap_absolute = Math.trunc(Math.abs(a_handicap));
      if (a_handicap_absolute !== Math.trunc(a_handicap_absolute)) {
        a_handicap_absolute = Math.trunc(a_handicap_absolute) + 1;
      } else {
        a_handicap_absolute += 1;
      }
      let b_handicap_absolute = Math.abs(b_handicap);

      if (a_handicap_absolute < b_handicap) {
        let desc = `${matched_bet.bet_a.conditions[0].team_name}`;
        desc += ` wins by `

        let fullMiddleCount = 0;
        while (a_handicap_absolute < b_handicap) {
          if (fullMiddleCount === 0) {
            desc += `${a_handicap_absolute}`;
          } else {
            desc += b_handicap_absolute - a_handicap_absolute > 1 ? `, ${a_handicap_absolute}` : ` or ${a_handicap_absolute}`;
          }

          a_handicap_absolute += 1;
          fullMiddleCount += 1;
        }
        desc += ` points`;

        ret.push({
          required_outcome: desc,
          description: 'Both Bets Win',
          extra_winnings: `Win a total of ${currencyFormatter.format(matched_bet.bet_a.possible_net_winnings + matched_bet.bet_b.possible_net_winnings)}`,
        });
      }

    } else {
      return false;
    }
  }



  return ret;
}

export const matchedBetTypeDescription = (matched_bet) => {
  if (matched_bet.bet_a.bet_bonus_type.id === 0 && matched_bet.bet_b.bet_bonus_type.id === 0) {
    if (matchedBetIsMiddle(matched_bet)) {
      return "Middle";
    }
    if (matchedBetArbitragePercentage(matched_bet).a_wins > 0 || matchedBetArbitragePercentage(matched_bet).b_wins > 0) {
      return "Arbitrage";
    }
    return "Low-Hold";
  }
  if (betBonusTypeIdIsFreeBet(matched_bet.bet_a.bet_bonus_type.id)) {
    return "Free-Bet Conversion"
  }
  if (betBonusTypeIdIsRiskFreeBet(matched_bet.bet_a.bet_bonus_type.id)) {
    return "Risk-Free Conversion";
  }
  return matched_bet.bet_a.bet_bonus_type.name;
}

export const matchedBetIsFreeBet = (matched_bet) => {
  return betBonusTypeIdIsFreeBet(matched_bet.bet_a.bet_bonus_type.id);
}
export const betTypeDescription = (bet) => {
  if (bet.bet_bonus_type === null) {
    return "";
  }
  return bet.bet_bonus_type.name;
}


export const matchedBetArbitragePercentage = (matched_bet, exchange_rate= 1.0) => {

  let total_wager = 0; //+(matched_bet.bet_a.wager_amount) + +(matched_bet.bet_b.wager_amount);
  if (!matched_bet.bet_a.bet_bonus_type || !betBonusTypeIdIsFreeBet(matched_bet.bet_a.bet_bonus_type.id)) {
    total_wager += +(matched_bet.bet_a.wager_amount)
  }
  if (!matched_bet.bet_b.bet_bonus_type || !betBonusTypeIdIsFreeBet(matched_bet.bet_b.bet_bonus_type.id)) {
    total_wager += +(matched_bet.bet_b.wager_amount)/ exchange_rate
  }
  if (total_wager === 0) {
    return Infinity;
  }
  const a_wins = (+(matched_bet.bet_a.possible_net_winnings) + +(matched_bet.bet_a.wager_amount) - total_wager) / total_wager;
  const b_wins = ((+(matched_bet.bet_b.possible_net_winnings)/ exchange_rate) + (+(matched_bet.bet_b.wager_amount)/ exchange_rate) - total_wager) / total_wager;

  return {
    a_wins: a_wins,
    a_wins_amount: +(matched_bet.bet_a.possible_net_winnings) + +(matched_bet.bet_a.wager_amount) - total_wager,
    b_wins: b_wins,
    b_wins_amount: (+(matched_bet.bet_b.possible_net_winnings)/ exchange_rate) + (+(matched_bet.bet_b.wager_amount)/ exchange_rate) - total_wager,
  }
}

export const matchedBetRiskFreeConversion = (matched_bet, exchange_rate=1.0) => {

  let total_wager = 0;  // +(matched_bet.bet_a.wager_amount) + +(matched_bet.bet_b.wager_amount);
  if (!betBonusTypeIdIsFreeBet(matched_bet.bet_a.bet_bonus_type.id)) {
    total_wager += +(matched_bet.bet_a.wager_amount)
  }
  if (!betBonusTypeIdIsFreeBet(matched_bet.bet_b.bet_bonus_type.id)) {
    total_wager += +(matched_bet.bet_b.wager_amount)/ exchange_rate
  }
  if (total_wager === 0) {
    return Infinity;
  }

  const a_wins_amount = (+(matched_bet.bet_a.possible_net_winnings) + +(matched_bet.bet_a.wager_amount) - total_wager);
  const a_wins = a_wins_amount / total_wager;
  const estimated_risk_free_bonus_conversion = matched_bet.bet_a.risk_free_estimated_conversion_rate * Math.min(matched_bet.bet_a.wager_amount, matched_bet.bet_a.risk_free_max_value_returned === 0 ? matched_bet.bet_a.wager_amount : matched_bet.bet_a.wager_amount);
  const b_wins_amount = ((+(matched_bet.bet_b.possible_net_winnings)/ exchange_rate) + (+(matched_bet.bet_b.wager_amount)/ exchange_rate) + ((estimated_risk_free_bonus_conversion / exchange_rate) - total_wager));
  const b_wins = b_wins_amount / total_wager;

  return {
    a_wins: a_wins,
    a_wins_amount: a_wins_amount,
    b_wins: b_wins,
    b_wins_amount: b_wins_amount,
  }
}

export const matchedBetConversionRate = (matched_bet, exchange_rate= 1.0) => {
  const type = matchedBetTypeDescription(matched_bet);

  if (type === "Arbitrage" || type === "Low-Hold" || type === "Middle") {
    return matchedBetArbitragePercentage(matched_bet, exchange_rate);
  }
  if (type === "Free-Bet Conversion") {

    let total_free_wager = 0;
    let a_wins = 0;
    let b_wins = 0;
    let a_real_wager = 0;
    let b_real_wager = 0;

    if (betBonusTypeIdIsFreeBet(matched_bet.bet_a.bet_bonus_type.id)) {
      total_free_wager += +(matched_bet.bet_a.wager_amount)
    } else {
      a_real_wager = +(matched_bet.bet_a.wager_amount);
    }
    if (betBonusTypeIdIsFreeBet(matched_bet.bet_b.bet_bonus_type.id)) {
      total_free_wager += +(matched_bet.bet_b.wager_amount)/ exchange_rate
    } else {
      b_real_wager = +(matched_bet.bet_b.wager_amount)/ exchange_rate;
    }
    if (total_free_wager === 0) {
      return Infinity;
    }

    if (betBonusTypeIdIsFreeBet(matched_bet.bet_a.bet_bonus_type.id)) {
      a_wins = (+(matched_bet.bet_a.possible_net_winnings) - b_real_wager) / total_free_wager;
    }
    if (betBonusTypeIdIsFreeBet(matched_bet.bet_b.bet_bonus_type.id)) {
      b_wins = ((+(matched_bet.bet_b.possible_net_winnings)/ exchange_rate) - a_real_wager) / total_free_wager;
    }

    return {
      a_wins: a_wins,
      a_wins_amount: +(matched_bet.bet_a.possible_net_winnings) - b_real_wager,
      b_wins: b_wins,
      b_wins_amount: (+(matched_bet.bet_b.possible_net_winnings)/ exchange_rate) - a_real_wager,
    }
  }

  if (type === "Risk-Free Conversion") {
    return matchedBetRiskFreeConversion(matched_bet, exchange_rate);
  }
}
export const betBonusTypeById = (bet_bonus_types, id) => {
  if (!bet_bonus_types || bet_bonus_types.length === 0) return null;
  return bet_bonus_types.find((bet_bonus_type) => bet_bonus_type.id === id);
}
export const betBonusTypeByName = (bet_bonus_types, name) => {
  if (!bet_bonus_types || bet_bonus_types.length === 0) return null;
  return bet_bonus_types.find((bet_bonus_type) => bet_bonus_type.name === name);
}
export const betBonusTypeIdIsFreeBet = (id) => {
  return id === 1; // bet_bonus_type && bet_bonus_type.name.toLowerCase() === "Free Bet (Stake not returned)"
}
export const betBonusTypeIdIsRiskFreeBet = (id) => {
  id = +id;
  return id === 2 || id === 3; // bet_bonus_type && bet_bonus_type.name.toLowerCase() === "Free Bet (Stake not returned)"
}

export const friendlyTimeUntil = (targetTime) => {
  targetTime = new Date(targetTime);
  let now = new Date();
  let deltaMilliseconds = targetTime - now;
  let deltaSeconds = Math.round(deltaMilliseconds / 1000);

  if (deltaSeconds < 0) {
    return "Live";
  }

  if (deltaSeconds < 300) {
    return "Starting soon";
  } else if (deltaSeconds < 3600) {
    return `in ${Math.round(deltaSeconds / 60)} minute${Math.round(deltaSeconds / 60) === 1 ? '' : 's'}`;
  } else if (deltaSeconds < 86400) {
    return `in ${Math.round(deltaSeconds / 3600)} hour${Math.round(deltaSeconds / 3600) === 1 ? '' : 's'}`;
  } else {
    return `in ${Math.round(deltaSeconds / 86400)} day${Math.round(deltaSeconds / 86400) === 1 ? '' : 's'}`;
  }
}