import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import apiClient from "../APIClient";

const PlayerSelector = ({setPlayer}) => {
  const [inputText, setInputText] = useState('');
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState('');

  useEffect(() => {
    const fetchData = () => {
      apiClient.get(`/api/player-search?q=${inputText}`)
        .then((response) => {
          const data = response.data;
          setOptions(data.map((result) => ({ value: result.id, label: `${result.name} (${result.team.name})`, result })));
        })
        .catch((error) => {
          console.error(error);
        });
    };

    if (inputText) {
      fetchData();
    } else {
      setOptions([]);
    }
  }, [inputText]);
const handleChange = (so) => {
    if (so) {
      setSelectedOption(so);
      setPlayer(so.result)
    }
  };

  const customStyles = {
    clearIndicator: (provided, state) => ({
      ...provided,
      display: 'none',
    }),
  };

  return (
    <div>
      <Select
        value={inputText ? { value: inputText, label: inputText } : (selectedOption ? { value: selectedOption.label, label: selectedOption.label } : null)}
        options={options}
        onChange={handleChange}
        onInputChange={setInputText}
        placeholder="Search by player or team name..."
        isClearable
        styles={customStyles}
      />
    </div>
  );
};

export default PlayerSelector;