import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import apiClient from "../APIClient";
import {dateTimeFormatted, eventTitleForBet, eventTitleForEvent} from "../utils/BetUtils";

const PinnacleEventSelector = ({setEvent}) => {
  const [inputText, setInputText] = useState('');
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState('');

  useEffect(() => {

    if (inputText.length < 3) { return }
    const fetchData = () => {
      apiClient.get(`/api/events?q=${inputText}`)
        .then((response) => {
          const data = response.data;
          setOptions(data.map((result) => ({
            value: result.id,
            label: `${result.home} vs. ${result.away} - ${new Date(result.starts).toLocaleDateString()} ${new Date(result.starts).toLocaleTimeString()}`,
            result
          })));
        })
        .catch((error) => {
          console.error(error);
        });
    };

    if (inputText) {
      fetchData();
    } else {
      setOptions([]);
    }
  }, [inputText]);

  const handleChange = (so) => {
    if (so) {
      setSelectedOption(so);
      setEvent(so.result)
    }
  };

  const customStyles = {
    clearIndicator: (provided, state) => ({
      ...provided,
      display: 'none',
    }),
  };

  return (
    <div>
      <Select
        value={inputText ? { value: inputText, label: inputText } : (selectedOption ? { value: selectedOption.label, label: selectedOption.label } : null)}
        options={options}
        onChange={handleChange}
        onInputChange={setInputText}
        placeholder="Search by city or team name..."
        isClearable
        styles={customStyles}
      />
    </div>
  );

  /*
  return (
    <div>
      <div className="bet-tile--subtitle-small">
        {selectedOption && selectedOption.result &&
          <img className={'team-logo__tiny'} src={selectedOption.result.home_team.logo_url} />
        }
        {selectedOption && eventTitleForEvent(selectedOption.result)}
        {selectedOption && selectedOption.result &&
          <img className={'team-logo__tiny'} src={selectedOption.result.away_team.logo_url} />
        }
      </div>
      <div className="bet-tile--subtitle-small">{dateTimeFormatted(selectedOption.result.event_date)}</div>
    </div>
  )
  */
};

export default PinnacleEventSelector;