import {
  descriptionForBetCondition,
  eventTitleForBet,
  dateTimeFormatted,
  currencyFormatter,
  matchedBet,
  matchedBetTypeDescription,
  betTypeDescription, friendlyTimeUntil
} from "../utils/BetUtils";
import './BetTile.css'
import { useState, useEffect } from "react";
import {fetchBetConditionTypes} from "../utils/ApiUtils";
const BetTile = ({bet, betConditionTypes}) => {
  const [bet_bonus_type_name, setBetBonusTypeName] = useState(null);
  //const [betConditionTypes, setBetConditionTypes] = useState([]);
  const [betConditionTypeName, setBetConditionTypeName] = useState('');

  useEffect(() => {
    if(!betConditionTypes) { return }

    const type = betConditionTypes.find((bct) => bct.id === bet.conditions[0].bet_condition_type);
    if (!type) { return }
    setBetConditionTypeName(type.name);
  }, [betConditionTypes]);

  return (
    <div className="bet-tile">
      <div className="bet-tile__event">
        <div className="bet-tile--title">
          {descriptionForBetCondition(bet.conditions[0], betConditionTypes)}</div>
        <div className="bet-tile--subtitle">{betConditionTypeName} - {bet.conditions[0].pinnacle_period && bet.conditions[0].pinnacle_period.description}</div>
        <div className="bet-tile--subtitle-small">
          {eventTitleForBet(bet)}
        </div>
        <div className="bet-tile--subtitle-small">
          {dateTimeFormatted(bet.conditions[0].pinnacle_event.starts)}
          <span> - </span>
          {bet.conditions[0].pinnacle_event.settled === true &&
            <span>{bet.bet_status.name}</span>
          }
          {bet.conditions[0].pinnacle_event.settled !== true && bet.conditions[0].pinnacle_event.event_type === 'live' &&
            <span>Live</span>
          }
          {bet.conditions[0].pinnacle_event.settled !== true && bet.conditions[0].pinnacle_event.event_type === 'prematch' &&
            <span>{friendlyTimeUntil(bet.conditions[0].pinnacle_event.starts)}</span>
          }

        </div>
      </div>
      <div className="bet-tile__wager">
        <div className="bet-tile--title">
          {bet.bet_bonus_type && bet.bet_bonus_type.id !== 0 &&
            <span className="material-symbols-outlined md-18" data-tooltip-id="main-tooltip" data-tooltip-content={betTypeDescription(bet)}>redeem</span>
          }
          <span>{currencyFormatter.format(bet.wager_amount)}</span>
        </div>
        <div className="bet-tile--subtitle">{bet.odds}</div>
        <div className="bet-tile--subtitle-small">{bet.sportsbook_signup.label}</div>
        <div className="bet-tile--subtitle-small">{bet.sportsbook_signup.currency.code}</div>
      </div>
    </div>
  )
}

export default BetTile;