import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import SearchableDropdown from "./SearchableDropdown";
import DateTimePicker from 'react-datetime-picker'
import './App.css';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import BetCondition from "./BetCondition";
const BetConditionList = ({conditions, betConditionTypes, leagues, sports, betStatuses, removeFunction, collectValues}) => {


    return (
        <div id="bet-conditions-container">
          {Object.keys(conditions).map((c) => (
              <BetCondition
                  betConditionTypes={betConditionTypes}
                  leagues={leagues}
                  sports={sports}
                  key={c}
                  id={c}
                  condition_data={conditions[c]}
                  betStatuses={betStatuses}
                  removeFunction={removeFunction}
                  collectValues={collectValues}
              />
          ))}
        </div>
    )
};

export default BetConditionList;