import {
  currencyFormatter,
  matchedBetConversionRate, matchedBetIsMiddle,
  matchedBetTypeDescription,
  percentageFormatter,
  matchedBetHasUnresolvedAutoGradingError,
  matchedBetIsMiddleThatHit,
  matchedBetIsMiddleThatIsTracking, getMainlineOdds, matchedBetWonBothSides
} from "../utils/BetUtils";
import React, {useEffect, useState} from "react";
import apiClient from "../APIClient";
import './MatchedBetTypeTile.css';
import {fetchBetStatuses, fetchCurrentUser, fetchMatchedBetStatuses} from "../utils/ApiUtils";
import MiddleOutcomes from "./MiddleOutcomes";
import ConfirmMatchedBetsDeletion from "./ConfirmMatchedBetsDeletion";
import {useModal} from "./modal/ModalContext";

const MatchedBetTypeTile = ({matchedBet, betsUpdated, deleteMatchedBet, fetchExchangeRate, betStatuses, matchedBetStatuses, currentUser}) => {

  const { showModal } = useModal();
  //const [betStatuses, setBetStatuses] = useState([]);
  //const [matchedBetStatuses, setMatchedBetStatuses] = useState([]);
  const [betStatus, setBetStatus] = useState('');
  //const conversionRates = matchedBetConversionRate(matchedBet);
  const [conversionRates, setConversionRates] = useState(matchedBetConversionRate(matchedBet));
  //const formattedAWins = percentageFormatter.format(conversionRates.a_wins);
  //const formattedBWins = percentageFormatter.format(conversionRates.b_wins);

  const [formattedAWins, setFormattedAWins] = useState(0);
  const [formattedBWins, setFormattedBWins] = useState(0);

  //const aWinsAmount = conversionRates.a_wins_amount;
  //const bWinsAmount = conversionRates.b_wins_amount;
  const [aWinsAmount, setAWinsAmount] = useState(0);
  const [bWinsAmount, setBWinsAmount] = useState(0);

  const combinedWager = +(matchedBet.bet_a.wager_amount) + +(matchedBet.bet_b.wager_amount);
  const [exchange_rate, setExchangeRate] = useState(1);
  const [exchange_rate_to_base, setExchangeRateToBase] = useState(1);

  const threshold_percentage = 0.0001;
  const aCurrencyClass = aWinsAmount > 0 ? 'net-winnings winnings' : aWinsAmount == 0 ? 'net-winnings' : 'net-winnings losses';
  const bCurrencyClass = bWinsAmount > 0 ? 'net-winnings winnings' : aWinsAmount == 0 ? 'net-winnings' : 'net-winnings losses';

  const [mainlineTotalsOdds, setMainlineTotalsOdds] = useState([]);
  const [mainlineTeamTotalOdds, setMainlineTeamTotalOdds] = useState([]);
  const [mainlineSpreadOdds, setMainlineSpreadOdds] = useState([]);

  useEffect(() => {

    if (!conversionRates) { return }

    setFormattedAWins(percentageFormatter.format(conversionRates.a_wins));
    setFormattedBWins(percentageFormatter.format(conversionRates.b_wins));
    setAWinsAmount(conversionRates.a_wins_amount);
    setBWinsAmount(conversionRates.b_wins_amount);


  }, [conversionRates]);

  useEffect(() => {
    setMainlineTotalsOdds(getMainlineOdds(matchedBet.bet_a.conditions[0].pinnacle_event, matchedBet.bet_a.conditions[0].pinnacle_period, 'totals'));
    setMainlineTeamTotalOdds(getMainlineOdds(matchedBet.bet_a.conditions[0].pinnacle_event, matchedBet.bet_a.conditions[0].pinnacle_period, 'team_total'));
    setMainlineSpreadOdds(getMainlineOdds(matchedBet.bet_a.conditions[0].pinnacle_event, matchedBet.bet_a.conditions[0].pinnacle_period, 'spread'));
  }, [matchedBet]);

  const getMatchedBetStatusByName = (name) => {
    return matchedBetStatuses.find((betStatus) => betStatus.name === name);
  }
  const getBetStatusByName = (name) => {
    return betStatuses.find((betStatus) => betStatus.name === name);
  }
  const determineMatchedBetStatus = () => {
    if (matchedBet.bet_a.bet_status.name === "Open" &&
      matchedBet.bet_b.bet_status.name === "Open") {
      return getMatchedBetStatusByName("Open");
    }
    if (matchedBet.bet_a.bet_status.name === "Win" &&
      matchedBet.bet_b.bet_status.name === "Loss") {
      return getMatchedBetStatusByName("Bet A Wins - Bet B Loses");
    }
    if (matchedBet.bet_a.bet_status.name === "Loss" &&
      matchedBet.bet_b.bet_status.name === "Win") {
      return getMatchedBetStatusByName("Bet A Loses - Bet B Wins");
    }
    if (matchedBet.bet_a.bet_status.name === "Win" &&
      matchedBet.bet_b.bet_status.name === "Win") {
      return getMatchedBetStatusByName("Both Bets Win");
    }
    return getMatchedBetStatusByName("Other");
  }

  useEffect(() => {
    if (betStatuses.length === 0) { return }
    const bs = determineMatchedBetStatus();
    if (!bs || !bs.id) return;

    setBetStatus(bs.id);
  }, [betStatuses, matchedBet]);

  const _setBetStatus = (betStatusId) => {
    const betStatus = matchedBetStatuses.find((betStatus) => betStatus.id === +(betStatusId));
    if (betStatus.name === "Bet A Wins - Bet B Loses") {
      // Save the updated bet status
      updateBetStatus(matchedBet.bet_a.id, getBetStatusByName("Win").id, true);
    } else if (betStatus.name === "Bet A Loses - Bet B Wins") {
      updateBetStatus(matchedBet.bet_a.id, getBetStatusByName("Loss").id, true);
    } else if (betStatus.name === "Both Bets Win") {
      updateBetStatus(matchedBet.bet_a.id, getBetStatusByName("Win").id, false);
      updateBetStatus(matchedBet.bet_b.id, getBetStatusByName("Win").id, false);
    } else if (betStatus.name === "Open") {
      updateBetStatus(matchedBet.bet_a.id, getBetStatusByName("Open").id, true);
    } else if (betStatus.name === "Delete Both Bets") {
      _deleteMatchedBet(matchedBet);
      return;
    }
    setBetStatus(betStatusId);
  }
  const _setIndividualBetStatus = (bet_id, betStatusId) => {
    updateBetStatus(bet_id,betStatusId, false);
  }

  const updateBetStatus = (betID, betStatusId, updateRelated=true) => {

    const updatedBetData = {
      bet_status: betStatusId,
      update_related_bet: updateRelated,
    };

    apiClient
      .put(`/api/bet-status-update/${betID}/`, updatedBetData)
      .then((response) => {
        const updated_bet = response.data.bet;
        const related_bet = response.data.related_bet;

        if (related_bet) {
          betsUpdated({
            matchedBet_id: matchedBet.id,
            bets: {
              [updated_bet.id]: updated_bet,
              [related_bet.id]: related_bet,
            }
          })
        } else {
          betsUpdated({
            matchedBet_id: matchedBet.id,
            bets: {
              [updated_bet.id]: updated_bet,
            }
          })
        }
      });
  }

  const _setBetAStatus = (betStatusId) => {
    _setIndividualBetStatus(matchedBet.bet_a.id, betStatusId);
  }
  const _setBetBStatus = (betStatusId) => {
    _setIndividualBetStatus(matchedBet.bet_b.id, betStatusId);
  }

  const _deleteMatchedBet = () => {
    showModal(<ConfirmMatchedBetsDeletion matchedBet={matchedBet} deleteMatchedBet={deleteMatchedBet} />);
    //deleteMatchedBet(matchedBet);
  }

  const betStatusDropdown = () => {

    if (!matchedBetStatuses.length > 0) { return }
    const otherStatusDropdowns = () => {
      if (!getMatchedBetStatusByName("Other")) { return }
      if (+betStatus === getMatchedBetStatusByName("Other").id) {
        return (
          <div className={'other-matched-bet-statuses'}>
            <span>
              <select value={matchedBet.bet_a.bet_status.id} onChange={e => _setBetAStatus(e.target.value)}>
                {betStatuses.map((betStatus) => (
                  <option key={betStatus.id} value={betStatus.id}>{betStatus.name}</option>
                ))}
              </select>
            </span>
            <span>
              <select value={matchedBet.bet_b.bet_status.id} onChange={e => _setBetBStatus(e.target.value)}>
                {betStatuses.map((betStatus) => (
                  <option key={betStatus.id} value={betStatus.id}>{betStatus.name}</option>
                ))}
              </select>
            </span>
          </div>
        )
      }
    }

    return (
      <div>
        <select value={betStatus} onChange={e => _setBetStatus(e.target.value)}>
          {matchedBetStatuses.map((betStatus) => (
            <option key={betStatus.id} value={betStatus.id}>{betStatus.name}</option>
          ))}
        </select>
        {otherStatusDropdowns()}
      </div>
    )
  }

  let risk_free_info = () => {
    if (matchedBetTypeDescription(matchedBet) === "Risk-Free Conversion") {
      return (
        <span data-tooltip-id="main-tooltip"
              data-tooltip-content={"If Bet A loses, the conversion rate below will require another matched Free Bet conversion."}
              className={'material-symbols-outlined md-18'}>
          info
        </span>
      )
    }
  }

  const tracking_middle_details = () => {
    return (
      <div className={'tracking-middle-details'}>
        {matchedBet.bet_a.conditions[0].bet_condition_type === 3 &&
          <span data-tooltip-id="main-tooltip" data-tooltip-content={'Recent Mainline Total'}><span className="material-symbols-outlined md-18">trending_flat</span>{mainlineTotalsOdds.points}</span>
        }

        {matchedBet.bet_a.conditions[0].bet_condition_type === 2 && mainlineSpreadOdds && mainlineSpreadOdds.handicap < 0 &&
          <span data-tooltip-id="main-tooltip" data-tooltip-content={'Recent Mainline Spread'}><span className="material-symbols-outlined md-18">trending_flat</span>{matchedBet.bet_a.conditions[0].pinnacle_event.home} by {Math.abs(mainlineSpreadOdds.handicap)}</span>
        }
        {matchedBet.bet_a.conditions[0].bet_condition_type === 2 && mainlineSpreadOdds && mainlineSpreadOdds.handicap > 0 &&
          <span data-tooltip-id="main-tooltip" data-tooltip-content={'Recent Mainline Spread'}><span className="material-symbols-outlined md-18">trending_flat</span>{matchedBet.bet_a.conditions[0].pinnacle_event.away} by {mainlineSpreadOdds.handicap}</span>
        }


        {matchedBet.bet_a.conditions[0].bet_condition_type === 4 && mainlineTeamTotalOdds && matchedBet.bet_a.conditions[0].team_name === matchedBet.bet_a.conditions[0].pinnacle_event.home && mainlineTeamTotalOdds.find((tto) => { return tto.home_or_away === 'home'}) &&
          <span data-tooltip-id="main-tooltip" data-tooltip-content={'Recent Mainline Team Total'}><span className="material-symbols-outlined md-18">trending_flat</span>{mainlineTeamTotalOdds.find((tto) => { return tto.home_or_away === 'home'}).points}</span>
        }
        {matchedBet.bet_a.conditions[0].bet_condition_type === 4 && mainlineTeamTotalOdds && matchedBet.bet_a.conditions[0].team_name === matchedBet.bet_a.conditions[0].pinnacle_event.away && mainlineTeamTotalOdds.find((tto) => { return tto.home_or_away === 'away'}) &&
          <span data-tooltip-id="main-tooltip" data-tooltip-content={'Recent Mainline Team Total'}><span className="material-symbols-outlined md-18">trending_flat</span>{mainlineTeamTotalOdds.find((tto) => { return tto.home_or_away === 'away'}).points}</span>
        }
        {matchedBetIsMiddleThatIsTracking(matchedBet) &&
          <span>!</span>
        }
      </div>
    )
  }

  if (matchedBet.bet_a.bet_status.name !== "Open" && matchedBet.bet_b.bet_status.name !== "Open") {
    //Bets have been graded

    let net_profit_loss = +(matchedBet.bet_a.net_winnings) + +(matchedBet.bet_b.net_winnings) / +(matchedBet.exchange_rate || 1.0);
    const formattedAWins = percentageFormatter.format(net_profit_loss / combinedWager);

    const currencyClass = net_profit_loss > 0 ? 'net-winnings winnings' : net_profit_loss === 0 ? 'net-winnings' : 'net-winnings losses';

    return (
      <div className={'matched-bet-type-tile'}>
        <div className={'shared-result-container'}>
          <div>
            <span>{matchedBetTypeDescription(matchedBet)}</span>
            {risk_free_info()}
            {(matchedBetWonBothSides(matchedBet) || matchedBetIsMiddleThatHit(matchedBet)) &&
              <span data-tooltip-id="main-tooltip"
                data-tooltip-content={"This middle bet hit!"}>
                &nbsp;&#129395;
              </span>
            }
          </div>
          <div className={currencyClass}
              data-tooltip-id="main-tooltip"
              data-tooltip-content={"The resulting profit/loss percentage from this matched bet."}>
            {formattedAWins}
          </div>
          <div className={currencyClass}
              data-tooltip-id="main-tooltip"
              data-tooltip-content={"The resulting profit/loss from this matched bet."}>
            {currencyFormatter.format(net_profit_loss)}
          </div>
        </div>
        <div>
          {betStatusDropdown()}
        </div>
      </div>
    )

  } else if (Math.abs(aWinsAmount - bWinsAmount) / combinedWager > threshold_percentage) {
    //Bets are still open
    //There is a meaningful difference between the two payouts

    return (
      <div className={'matched-bet-type-tile'}>
        <div>
          {matchedBetHasUnresolvedAutoGradingError(matchedBet) &&
            <span data-tooltip-id="main-tooltip"
                  data-tooltip-content={"One or both of these bets could not be graded automatically."}
                  className={'material-symbols-outlined md-18'}>warning</span>
          }
          <span>{matchedBetTypeDescription(matchedBet)}</span>
          {risk_free_info()}
          {matchedBetIsMiddle(matchedBet) && (
            <span data-tooltip-id="main-tooltip"
                  data-tooltip-content={"This is a Middle bet. Click to see the possible Middle outcomes."}
                  className={'material-symbols-outlined md-18 clickable rotate-90'} onClick={(e) => {
              showModal(<MiddleOutcomes matchedBet={matchedBet} />);
            }}>vertical_align_center</span>
          )}
          {matchedBetIsMiddle(matchedBet) && mainlineSpreadOdds && mainlineTotalsOdds && mainlineTeamTotalOdds &&
            tracking_middle_details()
          }
        </div>
        <div className={'split-result-container'}>
          <div>
            <div className={aCurrencyClass}
              data-tooltip-id="main-tooltip"
              data-tooltip-content={"The resulting profit/loss percentage if Bet A wins."}>
              {formattedAWins}
            </div>
            <div className={aCurrencyClass}
              data-tooltip-id="main-tooltip"
              data-tooltip-content={"The resulting profit/loss if Bet A wins."}>
              {currencyFormatter.format(aWinsAmount)}
            </div>
          </div>
          <div>
            <div className={bCurrencyClass}
              data-tooltip-id="main-tooltip"
              data-tooltip-content={"The resulting profit/loss percentage if Bet B wins."}>
              {formattedBWins}
            </div>
            <div className={bCurrencyClass}
              data-tooltip-id="main-tooltip"
              data-tooltip-content={"The resulting profit/loss if Bet B wins."}>
              {currencyFormatter.format(bWinsAmount)}
            </div>
          </div>
        </div>
        <div>
          {betStatusDropdown()}
        </div>
      </div>
    )
  } else {
    //Bets are still open
    //No meaningful difference between the two payouts

    const currencyClass = aWinsAmount > 0 ? 'net-winnings winnings' : aWinsAmount === 0 ? 'net-winnings' : 'net-winnings losses';

    return (
      <div className={'matched-bet-type-tile'}>
        <div className={'shared-result-container'}>
          <div>
            {matchedBetHasUnresolvedAutoGradingError(matchedBet) &&
              <span data-tooltip-id="main-tooltip"
                    data-tooltip-content={"One or both of these bets could not be graded automatically."}
                    className={'material-symbols-outlined md-18'}>warning</span>
            }
            <span>{matchedBetTypeDescription(matchedBet)}</span>
            {risk_free_info()}
            {matchedBetIsMiddle(matchedBet) && (
              <span data-tooltip-id="main-tooltip"
                    data-tooltip-content={"This is a Middle bet. Click to see the possible Middle outcomes."}
                    className={'material-symbols-outlined md-18 clickable rotate-90'} onClick={(e) => {
                showModal(<MiddleOutcomes matchedBet={matchedBet} />);
              }}>vertical_align_center</span>
            )}
            {matchedBetIsMiddle(matchedBet) && mainlineSpreadOdds && mainlineTotalsOdds && mainlineTeamTotalOdds &&
              tracking_middle_details()
            }
          </div>
          <div className={currencyClass}
              data-tooltip-id="main-tooltip"
              data-tooltip-content={"The resulting profit/loss percentage regardless of which bet wins."}>
            {formattedAWins}
          </div>
          <div className={currencyClass}
              data-tooltip-id="main-tooltip"
              data-tooltip-content={"The resulting profit/loss percentage regardless of which bet wins."}>
            {currencyFormatter.format(aWinsAmount)}
          </div>
        </div>
        <div>
          {betStatusDropdown()}
        </div>
      </div>
    )
  }
}

export default MatchedBetTypeTile;