import React, {useContext, useState} from "react";
import axios from "axios";
import {Navigate} from "react-router-dom";
import {FormProvider, useForm} from "react-hook-form";
import {Input} from "./Input";
import {StatusMessageContext} from "../StatusMessageContext";
import {AuthContext} from "../AuthContext";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [user, setUser] = useState("");
  const methods = useForm();
  const { addStatusMessage } = useContext(StatusMessageContext);
  const auth_context = useContext(AuthContext);

  const onSubmit =  methods.handleSubmit((data) => {

    auth_context.loginUser(data.username, data.password);

    /*
    // send the username and password to the server
    axios.post(
      "/api/login",
      data
    ).then((response) => {
      // set the state of the user
      setUser(response.data)
      // store the user in localStorage
      localStorage.setItem('user', response.data)
    })
    .catch((error) => {
      addStatusMessage({'className': 'status-error', 'message': `There was a problem logging in.  ${error.response.data.error}`})
    });
  */
  });

  if (auth_context.isAuthenticated) {
    return (
      <Navigate to='/dashboard' />
    )
  }

  // if there's no user, show the login form
  return (
    <FormProvider {...methods}>
      <form
        onSubmit={e => e.preventDefault()}
        noValidate
      >
        <h1>Login</h1>
        <div id="user-register-form">
          <div>
            <Input
              id="username"
              label="Username:"
              type="text"
              placeholder=""
              validation={{required: "Required", pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "Username should be in E-Mail format"
              }}}
              />
          </div>
          <div>
            <Input
              id="password"
              label="Password:"
              type="password"
              placeholder=""
              validation={{required: {value: true, message: "Password is required"}}}
              />
          </div>
          <div>
            <input type="submit" value="Login" onClick={onSubmit} />
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export default Login;