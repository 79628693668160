import React, { useState, useEffect } from 'react';
import Select from 'react-select';
const SearchableDropdown = ({id, options, onChange, initialValue, ...rest}) => {
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
      if (!options) { return; }
      for (var i = 0; i < options.length; i++) {
          options[i].label = options[i].label || options[i].name;
      }
  }, [options]);

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    onChange(selectedOption);
  }

  useEffect(() => {
      if (initialValue) {
          for (var o in options) {
              if (options[o].id === initialValue) {
                  setSelectedOption(options[o]);
                  break;
              }
          }
      }
  }, [initialValue]);

  return (
    <div>
      <Select
        onChange={handleChange}
        options={options}
        value={selectedOption}
        isSearchable={true}
        placeholder="Select an option"
        className='searchable-dropdown'
        id={id}
        {...rest}
      />
    </div>
  );
};

export default SearchableDropdown;
