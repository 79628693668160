import React, { createContext, useState } from 'react';
import statusMessage from "./components/StatusMessage";

const StatusMessageContext = createContext();

const StatusMessageProvider = ({ children }) => {
  const [statusMessages, setStatusMessages] = useState([]);

  const addStatusMessage = (message) => {
    setStatusMessages([...statusMessages, message])
  }
  const addErrorStatusMessage = (message_text) => {
    const message = {
      "className": "status-error",
      "message": message_text
    }
    setStatusMessages([...statusMessages, message])
  }
  const addInfoStatusMessage = (message_text) => {
    const message = {
      "className": "status-info",
      "message": message_text
    }
    setStatusMessages([...statusMessages, message])
  }

  const clearStatusMessages = () => {
    setStatusMessages([]);
  }

  return (
    <StatusMessageContext.Provider value={{ statusMessages, addStatusMessage, clearStatusMessages, addErrorStatusMessage, addInfoStatusMessage }}>
      {children}
    </StatusMessageContext.Provider>
  );
};

export { StatusMessageContext, StatusMessageProvider };