
import {useState, useEffect, useContext} from "react";
import {useForm, FormProvider} from 'react-hook-form'
import apiClient from "../APIClient";
import './Register.css'
import {Input} from "./Input";
import {Select} from "./Select";
import {Navigate} from "react-router-dom";
import login from "./Login";
import {StatusMessageContext} from "../StatusMessageContext";
import { useNavigate } from 'react-router-dom';
import { fetchCurrencies } from "../utils/ApiUtils";


const Register = () => {
  /*
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [password2, setPassword2] = useState('')
  const [first_name, setFirstName] = useState('')
  const [last_name, setLastName] = useState('')
  */
  //const [status_message, setStatusMessage] = useState('');
  const methods = useForm();
  const { addStatusMessage } = useContext(StatusMessageContext);
  const navigate = useNavigate();
  const [currencies, setCurrencies] = useState([]);

  useEffect(() => {
    fetchCurrencies(setCurrencies);
  }, []);

  /*
  const handleSubmitRegistration = () => {
    setStatusMessage("");

    if (email.length < 6) {
      setStatusMessage("The password must be at least 6 characters.");
    }
    if (password.length < 6) {
      setStatusMessage("The password must be at least 6 characters.");
    }
  }*/


  const onSubmit =  methods.handleSubmit((data) => {
      console.log(data);

      apiClient.post('/api/register', data)
        .then((response) => {
          addStatusMessage({
            "className": "status-success",
            "message": "Registration successful.  You can now log in."
          });
          navigate("/login");
          //setSuccess(true);
        })
        .catch((error) => {
          addStatusMessage({'className': 'status-error', 'message': `There was a problem during registration. ${error.response.data.error}`});
        })
    }
  );

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={e => e.preventDefault()}
        noValidate
      >
        <h1>Register</h1>
        <div id="user-register-form">
          <div>
            <Input
              id="first_name"
              label="First Name:"
              type="text"
              placeholder=""
              validation={{required: {value: true, message: "First Name is required"}}}
              />
          </div>
          <div>
            <Input
              id="last_name"
              label="Last Name:"
              type="text"
              placeholder=""
              validation={{required: {value: true, message: "Last Name is required"}}}
              />
          </div>
          <div>
            <Input
              id="email"
              label="Email Address:"
              type="text"
              placeholder=""
              validation={{required: "Required", pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "Invalid email address"
              }}}
              />
          </div>
          <div>
            <select
              id="base_currency"
              label="Main currency"
            >
              {currencies && currencies.map((currency) => (
                <option key={currency.id} value={currency.id}>{currency.name}</option>
              ))}
            </select>
          </div>
          <div>
            <Input
              id="password"
              label="Password:"
              type="password"
              placeholder=""
              validation={{required: {value: true, message: "Password is required"}, minLength: {value: 6, message: "Password must be at least 6 characters"}}}
              />
          </div>
          <div>
            <Input
              id="password2"
              label="Confirm Password:"
              type="password"
              placeholder=""
              validation={{required: {value: true, message: "Confirmation Password is required"}, validate: (val) => {  if(methods.watch('password') !== val) { return "Password must match" }}}}
              />
          </div>
          <div>
            <input type="submit" value="Register" onClick={onSubmit} />
          </div>
        </div>
      </form>
    </FormProvider>
  );

};

export default Register;