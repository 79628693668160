import {useFormContext} from "react-hook-form";
import {findInputError, isFormValid, InputError} from "../utils/FormUtils";
export const Input = ({id, label, type, placeholder, validation, setFunction}) => {
  const { register, formState: {errors} } = useFormContext();
  const inputError = findInputError(errors, id);
  const isValid = isFormValid(inputError)

  return (
    <div>
      <label>

        <div>{label}</div>
        <div>
          <input
              id={id}
              type={type}
              name={id}
              placeholder={placeholder}
              {...register(id, validation)}
            />
        </div>
      </label>
      {!isValid && (
        <InputError
          message={inputError.error.message}
          key={inputError.error.message}
          />
      )}
    </div>
  );
}

