import SearchableDropdown from "../../SearchableDropdown";
import {
    americanToDecimalOdds, betBonusTypeIdIsFreeBet,
    betBonusTypeIdIsRiskFreeBet, convertOddsToDecimal,
    decimalToAmericanOdds,
    fixInvalidAmericanOdds, roundedWagers
} from "../../utils/BetUtils";
import React, {useEffect, useState, useRef} from "react";
import RiskFreeBetAdvancedOptions from "./RiskFreeBetAdvancedOptions";
import {useModal} from "../modal/ModalContext";
import {fetchBetBonusTypes, fetchExchangeRate, fetchSportsbookSignups} from "../../utils/ApiUtils";
import BetCalculatorInputs from "./BetCalculatorInputs";
import './BetCalculator.css'
import BetPayoutSummary from "../BetPayoutSummary";
import MatchedBetPayoutSummary from "../MatchedBetPayoutSummary";
import MatchedBetConditions from "../MatchedBetConditions";
import apiClient from "../../APIClient";
import SuggestedWagers from "./SuggestedWagers";
import TransactionItemForm from "../TransactionItemForm";

const BetCalculator = ({matchedBetAdded, wager_a_data, setWagerAData, wager_b_data, setWagerBData}) => {
    const [sportsbook_signups, setSportsbookSignups] = useState([]);
    const [bet_bonus_types, setBetBonusTypes] = useState([]);
    //const [a_input_fields, setAInputFields] = useState({});
    //const [b_input_fields, setBInputFields] = useState({});
    const a_input_fields = useRef({});
    const b_input_fields = useRef({});

    const [exchange_rate, setExchangeRate] = useState(1.0);
    const [exchange_rate_code_target, setExchangeRateCodeTarget] = useState("CADCAD");
    const [unlinked_wagers, setUnlinkedWagers] = useState(false);
    const [suggested_a_wagers, setSuggestedAWagers] = useState(null);
    const [suggested_b_wagers, setSuggestedBWagers] = useState(null);
    const [bet_a_conditions, setBetAConditions] = useState(null);
    const [bet_b_conditions, setBetBConditions] = useState(null);
    const [betStatuses, setBetStatuses] = useState([])

    const { showModal, hideModal } = useModal();

    /*
    [wager_a_data, setWagerAData] = useState({
        wager: 100,
        odds: '',
        bet_bonus_type: 0,
        payout: 200,
        risk_free_percentage_returned: 100,
        risk_free_max_value_returned: 0,
        risk_free_estimated_conversion_rate: 60,
        sportsbook_signup: null,
    });
    [wager_b_data, setWagerBData] = useState({
        wager: 100,
        odds: '',
        bet_bonus_type: 0,
        payout: 200,
        risk_free_percentage_returned: 100,
        risk_free_max_value_returned: 0,
        risk_free_estimated_conversion_rate: 60,
        sportsbook_signup: null,
    });


    const [wager_a_data, setWagerAData] = useState({
        wager: 100,
        odds: '',
        bet_bonus_type: 0,
        payout: 200,
        risk_free_percentage_returned: 100,
        risk_free_max_value_returned: 0,
        risk_free_estimated_conversion_rate: 60,
        sportsbook_signup: null,
    });
    const [wager_b_data, setWagerBData] = useState({
        wager: 100,
        odds: '',
        bet_bonus_type: 0,
        payout: 200,
        risk_free_percentage_returned: 100,
        risk_free_max_value_returned: 0,
        risk_free_estimated_conversion_rate: 60,
        sportsbook_signup: null,
    });
    */

    useEffect(() => {
        apiClient.get('/api/bet-statuses/').then((response) => setBetStatuses(response.data));
    }, []);

    const registerAInputField = (field) => {
        //We use this to track input fields based on their ID, in particular so we can tab through them in a custom way

        if (!field || !field.id) {
            return;
        }

        if (!(field.id in a_input_fields.current)) {
            a_input_fields.current[field.id] = field;
            field.addEventListener("keydown", handleTabPress);
        }

        /*
        setAInputFields((prevInputFields) => {
            if (field.id in prevInputFields) {
                return prevInputFields;
            }
            return { ...prevInputFields, [field.id]: field };
        });

         */

    }
    const registerBInputField = (field) => {
        //We use this to track input fields based on their ID, in particular so we can tab through them in a custom way

        if (!field || !field.id) {
            return;
        }

        if (!(field.id in b_input_fields.current)) {
            b_input_fields.current[field.id] = field;
            field.addEventListener("keydown", handleTabPress);
        }
        /*
        setBInputFields((prevInputFields) => {
            if (field.id in prevInputFields) {
                return prevInputFields;
            }
            return { ...prevInputFields, [field.id]: field };
        });

         */
    }

    /*
    useEffect(() => {
        //Add event listener so we handle tabbing through the fields in a custom way.
        //We have to do this in useEffect so that it executes after the thing is loaded
        for (var f in a_input_fields) {
            a_input_fields[f].addEventListener("keydown", handleTabPress);
        }
    }, [a_input_fields]);
    useEffect(() => {
        //Add event listener so we handle tabbing through the fields in a custom way.
        //We have to do this in useEffect so that it executes after the thing is loaded
        for (var f in b_input_fields) {
            b_input_fields[f].addEventListener("keydown", handleTabPress);
        }
    }, [b_input_fields]);
    */

    const handleTabPress = (e) => {
        if (e.keyCode !== 9) {
            return;
        }
        if (e.target.id === "sportsbook_signup_b") {
            if (e.shiftKey) {
                a_input_fields.current['sportsbook_signup_a'].focus();
                b_input_fields.current['odds_a'].select();
                e.preventDefault();
            } else {
                b_input_fields.current['odds_a'].focus();
                b_input_fields.current['odds_a'].select();
                e.preventDefault();
            }
            return;
        }
        if (e.target.id === "odds_a") {
            if (e.shiftKey) {
                //input_fields['odds_b'].focus();
            } else {
                b_input_fields.current['odds_b'].focus();
                b_input_fields.current['odds_b'].select();
                e.preventDefault();
            }
            return;
        }
        if (e.target.id === "odds_b") {
            if (e.shiftKey) {
                a_input_fields.current['odds_a'].focus();
                a_input_fields.current['odds_a'].select();
            } else {
                a_input_fields.current['wager_a'].focus();
                a_input_fields.current['wager_a'].select();
            }
            e.preventDefault();
            return;
        }
        if (e.target.id === "wager_a") {
            if (e.shiftKey) {
                b_input_fields.current['odds_b'].focus();
                b_input_fields.current['odds_b'].select();
            } else {
                b_input_fields.current['wager_b'].focus();
                b_input_fields.current['wager_b'].select();
            }
            e.preventDefault();
            return;
        }
        if (e.target.id === "wager_b") {
            if (e.shiftKey) {
                a_input_fields.current['wager_a'].focus();
                a_input_fields.current['wager_a'].select();
            } else {
                a_input_fields.current['bet_bonus_type_a'].focus();
            }
            e.preventDefault();
            return;
        }
        /*if (e.target.id === "bet_bonus_type_a") {
            if (e.shiftKey) {
                b_input_fields.current['wager_b'].focus();
                b_input_fields.current['wager_b'].select();
            } else {
                b_input_fields.current['bet_bonus_type_b'].focus();
            }
            e.preventDefault();
            return;
        }
        if (e.target.id === "bet_bonus_type_b") {
            if (e.shiftKey) {
                a_input_fields.current['bet_bonus_type_a'].focus();
                e.preventDefault();
            }
            return;
        }*/
    }



    useEffect(() => {
        fetchSportsbookSignups(setSportsbookSignups);
        fetchBetBonusTypes(setBetBonusTypes);
    }, []);



    const sportsbookSignupById = (id) => {
        return sportsbook_signups.find((sportsbook_signup) => {
            return sportsbook_signup.id === id;
        });
    }

    useEffect(() => {
        if (!wager_a_data.sportsbook_signup || !wager_b_data.sportsbook_signup) { return; }

        //Update the exchange rate if it doesn't match the currently selected currencies.
        if (`${wager_a_data.sportsbook_signup.currency}${wager_b_data.sportsbook_signup.currency}` !== exchange_rate_code_target) {
            fetchExchangeRate(wager_a_data.sportsbook_signup.currency, wager_b_data.sportsbook_signup.currency, setExchangeRate);
            setExchangeRateCodeTarget(`${wager_a_data.sportsbook_signup.currency}${wager_b_data.sportsbook_signup.currency}`);
        }

    }, [wager_a_data, wager_b_data]);

    useEffect(() => {
        const er = exchange_rate ? exchange_rate : 1.0;
        _setWagerAData({
            ...wager_a_data,
            exchange_rate: er,
        });

        const original_er = wager_b_data.exchange_rate ? wager_b_data.exchange_rate : 1.0;

        setWagerBData((prevState) => {
            return {
                ...prevState,
                exchange_rate: er,
                wager: +((wager_b_data.wager / original_er * er).toFixed(2)),
                payout: +((wager_b_data.payout / original_er * er).toFixed(2)),
            }
        });

    }, [exchange_rate]);


    useEffect(() => {
        if (!unlinked_wagers) {
            _setWagerAData(wager_a_data);
        }
    }, [unlinked_wagers]);


    const _setWagerAData = (data, linked = null) => {
        const key = Object.keys(data)[0];
        if (linked === null) {
            linked = !unlinked_wagers;
        }

        let new_data = {
            ...wager_a_data,
            ...data,
        }

        if (!(new_data.odds === '' || new_data.wager === '')) {

            if (key === 'bet_bonus_type') {
                if (+data[key] === 2) {
                    //Risk-free to free bet
                    new_data = {
                        ...new_data,
                        risk_free_estimated_conversion_rate: 60,
                    }
                } else if (+data[key] === 3) {
                    //Risk-free to bonus money bet
                    new_data = {
                        ...new_data,
                        risk_free_estimated_conversion_rate: 95,
                    }
                }
            }

            let a_payout = new_data.wager * convertOddsToDecimal(new_data.odds);
            if (betBonusTypeIdIsFreeBet(+new_data.bet_bonus_type)) {
                a_payout = new_data.wager * (convertOddsToDecimal(new_data.odds) - 1.0);
            }/* else if (betBonusTypeIdIsRiskFreeBet(+new_data.bet_bonus_type)) {
                a_payout = (new_data.wager * (convertOddsToDecimal(new_data.odds))) - (Math.min(new_data.wager * (new_data.risk_free_percentage_returned / 100.0), new_data.risk_free_max_value_returned === 0 ? new_data.wager * (new_data.risk_free_percentage_returned / 100.0) : new_data.risk_free_max_value_returned) * (new_data.risk_free_estimated_conversion_rate / 100.0));
            }*/
            new_data = {
                ...new_data,
                payout: a_payout,
            }

            if (linked && new_data.sportsbook_signup && wager_b_data.sportsbook_signup) {

                const b_payout = matchedBPayout(new_data);
                const b_wager = ((b_payout / americanToDecimalOdds(wager_b_data.odds)).toFixed(2));

                setWagerBData((prevValue) => {
                    return {
                        ...prevValue,
                        wager: b_wager,
                        payout: b_payout,
                    }
                });

            }

            const sw = roundedWagers(wager_a_data, wager_a_data);
            setSuggestedAWagers(sw);
        }

        setWagerAData(new_data);
    }

    const suggestedWagersSelected = (wager_a, wager_b) => {
        setUnlinkedWagers(true);
        _setWagerAData({
            wager: wager_a,
        }, false);
        _setWagerBData({
            wager: wager_b,
        }, false);

        hideModal();
    };

    const _setWagerBData = (data, linked = null) => {
        const key = Object.keys(data)[0];
        if (linked === null) {
            linked = !unlinked_wagers;
        }

        let new_data = {
            ...wager_b_data,
            ...data,
        }

        if (!linked) {
            new_data = {
                ...new_data,
                payout: new_data.wager * convertOddsToDecimal(new_data.odds),
            }
            setWagerBData(new_data);
        } else {

            if (key === 'wager') { // && +(data.odds) === +(wager_b_data.odds)) {
                //setting the wager, so we need to set Bet A
                const b_payout = +(new_data.wager) * convertOddsToDecimal(new_data.odds);
                let a_payout = +(b_payout / exchange_rate).toFixed(2);
                if (wager_a_data.bet_bonus_type && betBonusTypeIdIsFreeBet(wager_a_data.bet_bonus_type)) {
                    a_payout += wager_a_data.wager;
                } else if (wager_a_data.bet_bonus_type && betBonusTypeIdIsRiskFreeBet(wager_a_data.bet_bonus_type)) {
                    a_payout += Math.min((wager_a_data.wager * wager_a_data.risk_free_percentage_returned / 100.0), wager_a_data.risk_free_max_value_returned === 0 ? wager_a_data.wager * (wager_a_data.risk_free_percentage_returned / 100.0) : wager_a_data.risk_free_max_value_returned) * (wager_a_data.risk_free_estimated_conversion_rate / 100.0);
                }
                const a_wager = ((a_payout / convertOddsToDecimal(wager_a_data.odds)).toFixed(2));

                setWagerAData((prevValue) => {
                    return {
                        ...prevValue,
                        wager: a_wager,
                        payout: a_payout,
                    }
                });

                new_data = {
                    ...new_data,
                    payout: b_payout,
                }

                setWagerBData(new_data);

            } else {
                //Setting something other than wager, so we need to match the payout

                const b_payout = matchedBPayout(wager_a_data);
                const b_wager = ((b_payout / americanToDecimalOdds(new_data.odds)).toFixed(2));
                new_data['wager'] = b_wager;
                new_data['payout'] = b_payout;

                setWagerBData(new_data);
            }
        }

        const sw = roundedWagers(wager_a_data, wager_a_data);
        setSuggestedAWagers(sw);

    }


    const matchedBPayout = (wager_a_data) => {
        const er = exchange_rate ? exchange_rate : 1.0;
        let b_payout = wager_a_data.payout;
        if (wager_a_data.bet_bonus_type && betBonusTypeIdIsFreeBet(wager_a_data.bet_bonus_type)) {
            b_payout -= wager_a_data.wager;
        } else if (wager_a_data.bet_bonus_type && betBonusTypeIdIsRiskFreeBet(wager_a_data.bet_bonus_type)) {
            b_payout -= Math.min(wager_a_data.wager * (wager_a_data.risk_free_percentage_returned / 100.0), wager_a_data.risk_free_max_value_returned=== 0 ? wager_a_data.wager * (wager_a_data.risk_free_percentage_returned / 100.0) : wager_a_data.risk_free_max_value_returned) * (wager_a_data.risk_free_estimated_conversion_rate / 100.0);
        }
        b_payout *= er;
        return +(b_payout.toFixed(2));
    }

    const _setBetAConditions = (conditions) => {
        setBetAConditions({
            ...conditions,
            odds: wager_a_data.odds
        });
    }
    const _setBetBConditions = (conditions) => {
        setBetBConditions({
            ...conditions,
            odds: wager_b_data.odds,
        });
    }
    const addMatchedBets = (wager_a_data, wager_b_data, bet_a_conditions, bet_b_conditions) => {
        const bct = +(bet_a_conditions.bet_condition_type);
        if (bct === 2 ||
          bct === 3 ||
          bct === 4 ||
          bct === 5
        ) {
            if (!bet_a_conditions.handicap || !bet_b_conditions.handicap) {
                console.log("Need to set handicap first");
                return;
            }
        }

        const bet_a_data = {
            sportsbook_signup: wager_a_data.sportsbook_signup.id,
            wager_amount: wager_a_data.wager,
            bet_bonus_type: wager_a_data.bet_bonus_type,
            odds: wager_a_data.odds,
            conditions: [bet_a_conditions],
            bet_status: 1, //Maybe fix this?
        };

        apiClient.post('/api/bets/', bet_a_data).then((response) => {

            const bet_b_data = {
                sportsbook_signup: wager_b_data.sportsbook_signup.id,
                wager_amount: wager_b_data.wager,
                bet_bonus_type: 0,
                odds: wager_b_data.odds,
                conditions: [bet_b_conditions],
                bet_status: 1, //Maybe fix this?
            };

            apiClient.post('/api/bets/', bet_b_data).then((response_b) => {
                const matched_bet_data = {
                    bet_a: response.data.id,
                    bet_b: response_b.data.id
                }

                apiClient.post('/api/matched-bets/', matched_bet_data).then((response_c) => {
                    console.log(response_c)

                    const matched_bet = {
                        ...response_c.data,
                    }


                    apiClient.get(`/api/bets/${matched_bet.bet_a}`).then((response_bet_a) => {
                        matched_bet.bet_a = response_bet_a.data;

                        apiClient.get(`/api/bets/${matched_bet.bet_b}`).then((response_bet_b) => {
                            matched_bet.bet_b = response_bet_b.data;

                            if (matchedBetAdded) {
                                matchedBetAdded(matched_bet);
                                hideModal();
                            }
                        });
                    });

                });

            });

        });
    }



    const showSuggestedWagers = () => {
        showModal(<SuggestedWagers
          wager_a_data={wager_a_data}
          wager_b_data={wager_b_data}
          suggestedWagersSelected={suggestedWagersSelected}
      />);
    }

    const showAddMatchedBetForm = () => {
        if (wager_a_data.sportsbook_signup && wager_b_data.sportsbook_signup) {
            showModal(<div>
              <MatchedBetConditions
                wager_a_data={wager_a_data}
                wager_b_data={wager_b_data}
                setBetAConditions={_setBetAConditions}
                setBetBConditions={_setBetBConditions}
                addMatchedBets={addMatchedBets}
              />
            </div>);
      }
    }


    return (
      <div id='matched-bet-calculator'>
          <div>
              <BetCalculatorInputs
                sportsbook_signups={sportsbook_signups}
                bet_bonus_types={bet_bonus_types}
                registerInputField={registerAInputField}
                wager_data={wager_a_data}
                setWagerData={_setWagerAData}
                showSuggestedWagers={showSuggestedWagers}
                id_suffix={'_a'}
              />
              <BetCalculatorInputs
                sportsbook_signups={sportsbook_signups}
                registerInputField={registerBInputField}
                wager_data={wager_b_data}
                setWagerData={_setWagerBData}
                setUnlinkedWagers={setUnlinkedWagers}
                unlinked_wagers={unlinked_wagers}
                id_suffix={'_b'}
              />
          </div>
          {wager_a_data.odds && wager_b_data.odds && wager_a_data.wager && wager_b_data.wager &&
            <div>
              <div>
                  <div className={'bet-details'}>
                      <BetPayoutSummary
                        wager_data = {{
                            ...wager_a_data
                        }}
                      />
                  </div>
                  <div className={'bet-details'}>
                      <BetPayoutSummary
                        wager_data = {{
                            ...wager_b_data
                        }}
                      />
                  </div>
              </div>
              <MatchedBetPayoutSummary
                betBonusTypes={bet_bonus_types}
                wagers_data={[wager_a_data,wager_b_data]}
                suggestedWagersSelected={suggestedWagersSelected}
              />

              <button onClick={showAddMatchedBetForm}>Add to Tracker</button>
            </div>
            }
      </div>
    )
}

export default BetCalculator;