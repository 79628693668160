import {useEffect, useState} from 'react';
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css';
import './BetsCalendar.css';
import {fetchDeposits, fetchMatchedBets, fetchSportsbookSignups, fetchWithdrawals, fetchMatchedBetsByDateRange} from "../../utils/ApiUtils";
import {
  matchedBetIsMiddle, matchedBetIsMiddleThatHit,
  matchedBetIsFreeBet, noPenniesCurrencyFormatter, betTypeDescription, matchedBetWonBothSides
} from "../../utils/BetUtils";
import {useParams} from "react-router-dom";
import moment from "moment";
import {useNavigate} from "react-router-dom";
import MatchedBetsList from "../MatchedBetsList/MatchedBetsList";

//dateSelected should be a funciton accepting the date, and a list of matched bets for that date
const BetsCalendar = ({matchedBets, dateSelected, activeStartDateChanged}) => {
  const params = useParams();
  const [date, setDate] = useState(new Date());
  const [activeStartDate, setActiveStartDate] = useState(new Date());
  const [deposits, setDeposits] = useState([]);
  const [withdrawals, setWithdrawals] = useState([]);
  const [sportsbook_signups, setSportsbookSignups] = useState([]);

  useEffect(() => {
    const now = new Date();


    fetchDeposits(setDeposits);
    fetchWithdrawals(setWithdrawals);
    fetchSportsbookSignups(setSportsbookSignups);
  }, []);

  /*
  useEffect(() => {
    let start_date = moment(activeStartDate).startOf('month').subtract(7, 'day').format('YYYY-MM-DD');
    let end_date = moment(activeStartDate).endOf('month').add(7, 'day').format('YYYY-MM-DD');
    fetchMatchedBetsByDateRange(start_date, end_date, setMatchedBets);
  }, [activeStartDate]);
   */

  const compareDatePortion = (date1, date2) => {
      const firstDate = moment(date1);
      const secondDate = moment(date2);

      return (firstDate.isSame(secondDate, 'date'));
  }

  const _dateSelected = (value, event) => {
    let matchedBets_for_date = [];
    for (const index in matchedBets) {
      const matched_bet = matchedBets[index];
      const event_date = moment(matched_bet.bet_a.conditions[0].pinnacle_event.starts)  //new Date(matched_bet.bet_a.conditions[0].pinnacle_event.starts);

      if (compareDatePortion(value, event_date)) {
        matchedBets_for_date.push(matched_bet);
      }
    }
    setDate(value);
    dateSelected(value, matchedBets_for_date);
  }

  const activeStartDateChange = (date, view) => {
    setActiveStartDate(date);
  }

  useEffect(() => {
    activeStartDateChanged(activeStartDate);
  }, [activeStartDate]);

  const tileContent = (date, view) => {

    if (date.getDate() === 30) {
      console.log("what");
    }

    let matched_bet_count = 0;
    let free_bet_count = 0;
    let middle_bet_count = 0;
    let middle_bet_hit_count = 0;
    let deposit_count = 0;
    let deposit_real_money_count = 0;
    let deposit_bonus_money_count = 0;
    let deposit_free_bets_count = 0;
    let deposit_real_money_total = 0;
    let deposit_bonus_money_total = 0;
    let deposit_free_bets_total = 0;
    let total_real_money_wagered = 0;
    let total_free_bets_wagered = 0;
    let total_net_winnings = 0;
    let withdrawal_count = 0;
    let withdrawal_total = 0;

    for (const index in matchedBets) {
      const matched_bet = matchedBets[index];
      const event_date = moment(matched_bet.bet_a.conditions[0].pinnacle_event.starts)  //new Date(matched_bet.bet_a.conditions[0].pinnacle_event.starts);

      if (compareDatePortion(date, event_date)) {
        matched_bet_count += 1;

        if (matchedBetIsMiddle(matched_bet)) {
          middle_bet_count += 1;
        }
        if (matchedBetWonBothSides(matched_bet) || matchedBetIsMiddleThatHit(matched_bet)) {
          middle_bet_hit_count += 1;
        }

        if (matchedBetIsFreeBet(matched_bet)) {
          free_bet_count += 1;
          total_free_bets_wagered += +(matched_bet.bet_a.wager_amount);
        } else {
          total_real_money_wagered += +(matched_bet.bet_a.wager_amount);
        }

        total_real_money_wagered += +(matched_bet.bet_b.wager_amount);

        if (matched_bet.bet_a.bet_status.name.toLowerCase() !== `open`) {
          total_net_winnings += matched_bet.bet_a.net_winnings;
        }
        if (matched_bet.bet_b.bet_status.name.toLowerCase() !== `open`) {
          total_net_winnings += matched_bet.bet_b.net_winnings;
        }
      }
    }

    for (const index in deposits) {
      const deposit = deposits[index];
      const deposit_date = new Date(deposit.date);

      if (compareDatePortion(deposit_date, date)) {
        deposit_count += 1;
        if (deposit.money_type.name.toLowerCase() === `real money`) {
          deposit_real_money_count += 1;
          deposit_real_money_total += +(deposit.amount);
        } else if (deposit.money_type.name.toLowerCase() === `bonus real money`) {
          deposit_bonus_money_count += 1;
          deposit_bonus_money_total += +(deposit.amount);
        } else if (deposit.money_type.name.toLowerCase() === `free-bets (stake not returned)`) {
          deposit_free_bets_count += 1;
          deposit_free_bets_total += +(deposit.amount);
        }
      }
    }
    for (const index in withdrawals) {
      const withdrawal = withdrawals[index];
      const withdrawal_date = new Date(withdrawal.date);

      if (compareDatePortion(withdrawal_date, date)) {
        withdrawal_count += 1;
        withdrawal_total += withdrawal.amount;
      }
    }


    return <div className={'bets-calendar-day-cell'}>
      {total_real_money_wagered > 0 &&
        <p data-tooltip-id="main-tooltip" data-tooltip-content={`${matched_bet_count} matched bet${matched_bet_count !== 1 ? 's' : ''} with a total of ${noPenniesCurrencyFormatter.format(total_real_money_wagered)} in real/bonus money wagered`}>
          <span className={'bets-calendar-day-cell--item-detail'}>
            {matched_bet_count}
          </span>
          <span className="material-symbols-outlined md-18">payments</span>
          <span className={'bets-calendar-day-cell--item-detail'}>
            {noPenniesCurrencyFormatter.format(total_real_money_wagered)}
          </span>
        </p>
      }
      {total_free_bets_wagered > 0 &&
        <p data-tooltip-id="main-tooltip" data-tooltip-content={`${free_bet_count} free bet conversion${free_bet_count !== 1 ? 's' : ''} with a total of ${noPenniesCurrencyFormatter.format(total_free_bets_wagered)} in free-bets wagered`}>
          <span className={'bets-calendar-day-cell--item-detail'}>
            {free_bet_count}
          </span>
          <span className="material-symbols-outlined md-18">redeem</span>
          <span className={'bets-calendar-day-cell--item-detail'}>
            {noPenniesCurrencyFormatter.format(total_free_bets_wagered)}
          </span>
        </p>
      }
      {middle_bet_count > 0 &&
        <p data-tooltip-id="main-tooltip" data-tooltip-content={`${middle_bet_count} middle bets`}>
          <span className={'bets-calendar-day-cell--item-detail'}>
            {middle_bet_count}
          </span>
          <span className="material-symbols-outlined md-18 rotate-90">vertical_align_center</span>
        </p>
      }
      {deposit_real_money_total > 0 &&
        <p data-tooltip-id="main-tooltip" data-tooltip-content={`${noPenniesCurrencyFormatter.format(deposit_real_money_total)} real money deposited`}>
          <span className={'bets-calendar-day-cell--item-detail'}>
            {noPenniesCurrencyFormatter.format(deposit_real_money_total)}
          </span>
          <span className="material-symbols-outlined md-18">
            paid
          </span>
        </p>
      }
      {withdrawal_total > 0 &&
        <p data-tooltip-id="main-tooltip" data-tooltip-content={`${noPenniesCurrencyFormatter.format(withdrawal_total)} real money withdrawn`}>
          <span className={'bets-calendar-day-cell--item-detail'}>
            {noPenniesCurrencyFormatter.format(withdrawal_total)}
          </span>
          <span className="material-symbols-outlined md-18">
            account_balance_wallet
          </span>
        </p>
      }
      {deposit_bonus_money_total > 0 &&
        <p className={'positive_money'} data-tooltip-id="main-tooltip" data-tooltip-content={`${noPenniesCurrencyFormatter.format(deposit_bonus_money_total)} bonus money received.  This money will almost always require some kind of playthrough.`}>
          <span className={'bets-calendar-day-cell--item-detail'}>
            {noPenniesCurrencyFormatter.format(deposit_bonus_money_total)}
          </span>
          <span className="material-symbols-outlined md-18">
            featured_seasonal_and_gifts
          </span>
        </p>
      }
      {deposit_free_bets_total > 0 &&
        <p className={'positive_money'} data-tooltip-id="main-tooltip" data-tooltip-content={`${noPenniesCurrencyFormatter.format(deposit_free_bets_total)} free-bets received.  These bets will normally convert around 60%.`}>
          <span className={'bets-calendar-day-cell--item-detail'}>
            {noPenniesCurrencyFormatter.format(deposit_free_bets_total)}
          </span>
          <span className="material-symbols-outlined md-18">
            redeem
          </span>
        </p>
      }

      {total_net_winnings + deposit_bonus_money_total !== 0 &&
        <p className={total_net_winnings + deposit_bonus_money_total >= 0 ? `positive_money` : `negative_money`} data-tooltip-id="main-tooltip" data-tooltip-content={`${noPenniesCurrencyFormatter.format(total_net_winnings + deposit_bonus_money_total)} net profit/loss based on graded bets, plus ${noPenniesCurrencyFormatter.format(deposit_bonus_money_total)} bonus money received`}>
          <span className={'bets-calendar-day-cell--item-detail'}>
            {noPenniesCurrencyFormatter.format(total_net_winnings + deposit_bonus_money_total)}
          </span>
          <span className="material-symbols-outlined md-18">
            {Math.round(total_net_winnings + deposit_bonus_money_total) >= 0 ? `trending_up` : `trending_down`}
          </span>
        </p>
      }

      {middle_bet_hit_count > 0 &&
        <p className={'positive_money'} data-tooltip-id="main-tooltip" data-tooltip-content={`${middle_bet_hit_count} middle bet${middle_bet_hit_count !== 1 ? `s` : ``} hit!`}>
          <span className={'bets-calendar-day-cell--item-detail'}>
            {middle_bet_hit_count}
          </span>
          <span>
            &#129395;
          </span>
        </p>
      }

    </div>;
  }

  return (
    <div className={'calendar-container'}>
      <Calendar
        onChange={setDate}
        value={date}
        tileContent={({ date, view }) => tileContent(date, view)}
        onChange={dateSelected}
        onActiveStartDateChange={({ activeStartDate, view }) => activeStartDateChange(activeStartDate, view)}
      />

    </div>
  )
}

export default BetsCalendar;