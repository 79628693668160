import SearchableDropdown from "../SearchableDropdown";
import React from "react";
import { useState, useEffect, useRef } from 'react';
import './MatchedBetCalculator.css'
import Input from './Input'
import {type} from "@testing-library/user-event/dist/type";
import apiClient from "../APIClient";
import BetPayoutSummary from "./BetPayoutSummary";
import MatchedBetPayoutSummary from './MatchedBetPayoutSummary'
import BetCondition from "../BetCondition";
import EventSelector from "./EventSelector";
import MatchedBetConditions from "./MatchedBetConditions"
import {
  decimalToAmericanOdds,
  americanToDecimalOdds,
  roundedWagers,
  betBonusTypeById,
  betBonusTypeIdIsFreeBet, betBonusTypeIdIsRiskFreeBet
} from "../utils/BetUtils";
import 'react-tooltip/dist/react-tooltip.css'
import {fetchExchangeRate} from "../utils/ApiUtils";
import { handleTabPress, handleSportsbookBTabPress } from "../utils/CalculatorUtils";
import RiskFreeBetAdvancedOptions from "./calculator/RiskFreeBetAdvancedOptions";
import TransactionItemForm from "./TransactionItemForm";
import {useModal} from "./modal/ModalContext";

const MatchedBetCalculator = ({sportsbookSignups, betBonusTypes}) => {
  const [sportsbooks, setSportsbooks] = useState([]);

  const [sportsbook_a, sportsbookAChange] = useState('');
  const [odds_a, setOddsA] = useState('100');
  //const [wager_a, setWagerA] = useState('100');
  const [payout_a, setPayoutA] = useState('');
  const [sportsbook_b, sportsbookBChange] = useState('');
  const [odds_b, setOddsB] = useState('-100');
  //const [wager_b, setWagerB] = useState('100');
  const [wagers, setWagers] = useState({ wager_a: 100, wager_b: 100 });
  const [payout_b, setPayoutB] = useState('');
  const [set_wager_b_timeout, setWagerBTimeout] = useState(null);
  const [exchange_rate, setExchangeRate] = useState(1.0);
  const [bet_bonus_type, setBetBonusType] = useState(0);
  const [bet_b_bonus_type, setBetBBonusType] = useState(0);
  const [link_bet_b_wager, setLinkBetBWager] = useState(true);
  const [input_fields, setInputFields] = useState({});
  const [suggested_wagers, setSuggestedWagers] = useState([]);
  const [linked_event_schedule, setLinkedEventSchedule] = useState(null);
  const [bet_a_conditions, setBetAConditions] = useState({});
  const [bet_b_conditions, setBetBConditions] = useState({});
  const [new_wagers, setNewWagers] = useState({ wager_a: 0, wager_b: 0 });
  const [bet_a_winnings, setBetAWinnings] = useState(0);
  const [risk_free_percentage_returned, setRiskFreePercentageReturned] = useState(1.0);
  const [risk_free_max_value_returned, setRiskFreeMaxValueReturned] = useState(0);
  const [risk_free_estimated_conversion_rate, setRiskFreeEstimatedConversionRate] = useState(0.6);
  const [show_advanced_risk_free_options, setShowAdvancedRiskFreeOptions] = useState(false);
  const { showModal, hideModal } = useModal();

  const wager_a = () => {
    return wagers.wager_a;
  }
  const wager_b = () => {
    return wagers.wager_b;
  }

  const setWagerA = (wager) => {
    setWagers(prevState => { return { wager_a: wager, wager_b: prevState.wager_b }});
  }

  const setWagerB = (wager) => {
    setWagers(prevState => { return { wager_a: prevState.wager_a, wager_b: wager }});
  }

  useEffect(() => {
    if (!betBonusTypes || betBonusTypes.length === 0) { return }

    console.log("Running useEffect 10"); // Add a label to each useEffect
    if (!link_bet_b_wager && new_wagers.wager_a !== 0 && new_wagers.wager_b !== 0) {
      setWagers((prevState) => {
        setNewWagers({ wager_a: 0, wager_b: 0 });
        return {
          wager_a: new_wagers.wager_a,
          wager_b: new_wagers.wager_b
        }
      });
    }
  }, [link_bet_b_wager, new_wagers, betBonusTypes]);

  useEffect(() => {
    if (!betBonusTypes) { return; }
    setBetAWinnings(payout_a - +wager_a());
  }, [wagers, payout_a, bet_bonus_type, betBonusTypes]);

  const suggestedWagersSelected = (wager_a, wager_b) => {
    setNewWagers({ wager_a, wager_b });
    setLinkBetBWager(false);
  };
  const _setBetAConditions = (conditions) => {
    setBetAConditions({
      ...conditions,
      odds: odds_a
    });
  }
  const _setBetBConditions = (conditions) => {
    setBetBConditions({
        ...conditions,
        odds: odds_b,
    });
  }
  const addMatchedBets = () => {
    const bct = +(bet_a_conditions.bet_condition_type);
    if (bct === 2 ||
      bct === 3 ||
      bct === 4 ||
      bct === 5
    ) {
      if (!bet_a_conditions.handicap || !bet_b_conditions.handicap) {
        console.log("Need to set handicap first");
        return;
      }
    }

    const bet_a_data = {
      sportsbook_signup: sportsbook_a.signup_id,
      wager_amount: wager_a(),
      bet_bonus_type: bet_bonus_type,
      odds: odds_a,
      conditions: [bet_a_conditions],
      bet_status: 1, //Maybe fix this?
    };

    apiClient.post('/api/bets/', bet_a_data).then((response) => {
      console.log(response)

      const bet_b_data = {
        sportsbook_signup: sportsbook_b.signup_id,
        wager_amount: wager_b(),
        bet_bonus_type: 0,
        odds: odds_b,
        conditions: [bet_b_conditions],
        bet_status: 1, //Maybe fix this?
      };

      apiClient.post('/api/bets/', bet_b_data).then((response_b) => {
        console.log(response_b)

        const matched_bet_data = {
          bet_a: response.data.id,
          bet_b: response_b.data.id
        }

        apiClient.post('/api/matched-bets/', matched_bet_data).then((response_c) => {
          console.log(response_c)
        });

      });

    });
  }

  const handleSportsbookBTabPress = (e) => {
    if (e.keyCode !== 9) {
      return;
    }
    if (e.shiftKey) {
      //input_fields['odds_b'].focus();
    } else {
      input_fields['odds_a'].focus();
      input_fields['odds_a'].select();
      e.preventDefault();
    }
  }
  const handleTabPress = (e) => {
    if (e.keyCode !== 9) {
      return;
    }
    if (e.target.id === "odds_a") {
      if (e.shiftKey) {
        //input_fields['odds_b'].focus();
      } else {
        input_fields['odds_b'].focus();
        input_fields['odds_b'].select();
        e.preventDefault();
      }
      return;
    }
    if (e.target.id === "odds_b") {
      if (e.shiftKey) {
        input_fields['odds_a'].focus();
        input_fields['odds_a'].select();
      } else {
        input_fields['wager_a'].focus();
        input_fields['wager_a'].select();
      }
      e.preventDefault();
      return;
    }
    if (e.target.id === "wager_a") {
      if (e.shiftKey) {
        input_fields['odds_b'].focus();
        input_fields['odds_b'].select();
      } else {
        input_fields['wager_b'].focus();
        input_fields['wager_b'].select();
      }
      e.preventDefault();
      return;
    }
    if (e.target.id === "wager_b") {
      if (e.shiftKey) {
        input_fields['wager_a'].focus();
        input_fields['wager_a'].select();
      } else {
        input_fields['bet_bonus_type'].focus();
      }
      e.preventDefault();
      return;
    }
    if (e.target.id === "bet_bonus_type") {
      if (e.shiftKey) {
        input_fields['wager_b'].focus();
        input_fields['wager_b'].select();
      } else {
        input_fields['bet_b_bonus_type'].focus();
      }
      e.preventDefault();
      return;
    }
    if (e.target.id === "bet_b_bonus_type") {
      if (e.shiftKey) {
        input_fields['bet_bonus_type'].focus();
        e.preventDefault();
      }
      return;
    }
  }

  const registerInputField = (field) => {
    //We use this to track input fields based on their ID, in particular so we can tab through them in a custom way

    if (!field || !field.id) {
      return;
    }

    setInputFields((prevInputFields) => {
      if (field.id in prevInputFields) {
        return prevInputFields;
      }
      return { ...prevInputFields, [field.id]: field };
    });
  }

  useEffect(() => {
    console.log("Running useEffect 9"); // Add a label to each useEffect
    //Add event listener so we handle tabbing through the fields in a custom way.
    //We have to do this in useEffect so that it executes after the thing is loaded
    for (var f in input_fields) {
      input_fields[f].addEventListener("keydown", handleTabPress);
    }
  }, [input_fields]);
  const fixInvalidOdds = () => {
    if (+odds_a > -100.0 && +odds_a < 100.0) {
      setOddsA(Math.round(decimalToAmericanOdds(americanToDecimalOdds(odds_a))));
    }
    if (+odds_b > -100.0 && +odds_b < 100.0) {
      setOddsB(Math.round(decimalToAmericanOdds(americanToDecimalOdds(odds_b))));
    }
  }

  useEffect(() => {
    console.log("Running useEffect 8"); // Add a label to each useEffect

    //When we get the list of sportsbooks that the user is signed up for, we create the necessary data list that can
    //be used in the calculator

    setSportsbooks(sportsbookSignups.map((ss) => {
      return {
        id: ss.sportsbook.id,
        name: ss.sportsbook.name,
        label: ss.label,
        currency: ss.currency.code,
        signup_id: ss.id
      }
    }));
  }, [sportsbookSignups])

  const _setWagerB = (wager) => {
    //This allows the user to set wager B and have it backwards calculate wager A.  This works in a little bit of a
    //hacky way, but it works.

    setWagerB(wager);

    if (link_bet_b_wager) {
      clearTimeout(set_wager_b_timeout);
      setWagerBTimeout(setTimeout(() => {
        const new_b_payout = wager * americanToDecimalOdds(odds_b);
        setWagerABasedOnBPayout(new_b_payout);

        //setWagerA(((new_payout / americanToDecimalOdds(odds_a) / exchange_rate)).toFixed(2));
      }, 700))
    } else {
      setPayoutB((wager * americanToDecimalOdds(+odds_b)).toFixed(2));
    }
  }

  const setWagerABasedOnBPayout = (new_b_payout) => {
    const bbt = betBonusTypes.find((bbt) => bbt.id === bet_bonus_type);
    const bbt_name = bbt.name.toLowerCase();

    if (betBonusTypes.id === 0) {
      //arbitrage or Stake Back Free-Bet
      setWagerA(((new_b_payout / exchange_rate) / americanToDecimalOdds(odds_a)).toFixed(2));
    } else if (bbt_name === "Free Bet (Stake not returned)".toLowerCase()) {
      //Free-Bet (no stake returned)
      //Subtract 1 from decimal odds to remove the stake being returned.
      setWagerA(((new_b_payout / exchange_rate) / (americanToDecimalOdds(odds_a) - 1)).toFixed(2));
    } else if (bbt_name === "Risk-Free to Free Bet".toLowerCase()) {
      //Risk-Free Bet returning Free-Bet

      //const risk_free_estimated_conversion_rate = wager_a().risk_free_estimated_conversion_rate || 0.6;
      let returned_wager_amount = +(wager_a().wager_amount) * risk_free_percentage_returned;
      returned_wager_amount = Math.min(returned_wager_amount, risk_free_max_value_returned && risk_free_max_value_returned > 0 ? risk_free_max_value_returned : returned_wager_amount);

      setWagerA(((new_b_payout / exchange_rate) - (returned_wager_amount * risk_free_estimated_conversion_rate) / americanToDecimalOdds(odds_a)).toFixed(2));
    } else if (bbt_name === "Risk-Free to Bonus Money".toLowerCase()) {
      //Risk-Free Bet returning Bonus Money

      //const risk_free_estimated_conversion_rate = wager_a().risk_free_estimated_conversion_rate || 0.95;
      let returned_wager_amount = +(wager_a().wager_amount) * risk_free_percentage_returned;
      returned_wager_amount = Math.min(returned_wager_amount, risk_free_max_value_returned && risk_free_max_value_returned > 0 ? risk_free_max_value_returned : returned_wager_amount);

      setWagerA(((new_b_payout / exchange_rate) - (returned_wager_amount * risk_free_estimated_conversion_rate) / americanToDecimalOdds(odds_a)).toFixed(2));
    }
  }

  useEffect(() => {
    console.log("Running useEffect 6"); // Add a label to each useEffect

    //When the user changes the bet bonus type, this affects how we calculate the matched payout on book B

    if (link_bet_b_wager) {
      if (betBonusTypeIdIsRiskFreeBet(bet_bonus_type)) {
        const returned_wager_amount_on_loss = Math.min(wager_a() * risk_free_percentage_returned, (risk_free_max_value_returned === 0 ? wager_a() * risk_free_percentage_returned : risk_free_max_value_returned));
        setPayoutB((payout_a - (returned_wager_amount_on_loss * risk_free_estimated_conversion_rate)) * exchange_rate);
      } else {
        setPayoutB(payout_a * exchange_rate);
      }
    }

  }, [payout_a, bet_bonus_type, risk_free_percentage_returned, risk_free_max_value_returned, risk_free_estimated_conversion_rate, link_bet_b_wager])

  useEffect(() => {
    if (betBonusTypeIdIsRiskFreeBet(bet_bonus_type)) {
      const bbt = betBonusTypeById(betBonusTypes, bet_bonus_type);
      const bbt_name = bbt.name.toLowerCase();

      if (bbt_name === "Risk-Free to Free Bet".toLowerCase()) {
        setRiskFreeEstimatedConversionRate(0.6);
      } else {
        setRiskFreeEstimatedConversionRate(0.95);
      }
    }
  }, [bet_bonus_type]);

  useEffect(() => {
    console.log("Running useEffect 5"); // Add a label to each useEffect

    //B Payout should match the A payout in some way.  B Payout value may change when any of the A values change,
    //or if the exchange rate changes for any reason.  When it gets updated, we need to work backwards to figure out
    //what the B Wager should be

    if (link_bet_b_wager) {
      if (isNaN(payout_b) || payout_b === "") {
        return;
      }
      setWagerB((payout_b / americanToDecimalOdds(odds_b)).toFixed(2));
    }
  }, [payout_b])

  useEffect(() => {
    console.log("Running useEffect 4"); // Add a label to each useEffect

    //When the user updates Odds B, we need to change the B Wager amount so that the payouts are still matched as needed

    if (isNaN(odds_b) && odds_b !== "-") {
      setOddsB('');
      return;
    }
    if (link_bet_b_wager) {
      //setWagerB((payout_b / americanToDecimalOdds(odds_b)).toFixed(2));
      const new_b_payout = payout_b;
      setWagerABasedOnBPayout(new_b_payout);
    } else {
      setPayoutB((wager_b() * americanToDecimalOdds(+odds_b)).toFixed(2));
    }
    //setSuggestedWagers(roundedWagers(wager_a(), odds_a, odds_b, bet_bonus_type, exchange_rate));
  }, [odds_b])

  useEffect(() => {
    console.log("Running useEffect 9"); // Add a label to each useEffect

    //When the user updates Odds B, we need to change the B Wager amount so that the payouts are still matched as needed

    if (isNaN(odds_b) && odds_b !== "-") {
      setOddsB('');
      return;
    }
    if (!link_bet_b_wager) {
      setPayoutB((wager_b() * americanToDecimalOdds(+odds_b)).toFixed(2));
    }
    //setSuggestedWagers(roundedWagers(wager_a(), odds_a, odds_b, bet_bonus_type, exchange_rate));
  }, [wagers.wager_b])

  useEffect(() => {
    console.log("Running useEffect 3"); // Add a label to each useEffect

    //When the exchange_rate changes, it is because one of the books has changed or the value was retrieved for the
    //first time.  Either way we need to update the B payout to match the A payout but with the exchange rate considered

    if (link_bet_b_wager) {
      if (isNaN(odds_b) && odds_b !== "-") {
        setOddsB('');
      } else {
        if (wager_a() && odds_a) {
          setPayoutB((payout_a * exchange_rate).toFixed(2));
        }
      }
    }
  }, [exchange_rate])

  useEffect(() => {
    console.log("Running useEffect 2"); // Add a label to each useEffect

    if (!betBonusTypes || betBonusTypes.length === 0) { return }

    //When the user updates either Wager A or Odds A, and both values are populated, we can recalculate Payout A
    //straight from the wager and odds.

    if (isNaN(wager_a()) && wager_a() !== "") {
      //setWagerA('');
      setPayoutA('');
    } else {
      if (wager_a() && odds_a) {
        if (betBonusTypeIdIsFreeBet(bet_bonus_type)) {
          //Free-Bet (no stake returned)
          setPayoutA( (wager_a() * americanToDecimalOdds(+odds_a) - wager_a()).toFixed(2));
        } else {
          //All other bet types return full stake on win?
          setPayoutA( (wager_a() * americanToDecimalOdds(+odds_a)).toFixed(2));
        }

        setSuggestedWagers(roundedWagers(wager_a(), odds_a, odds_b, bet_bonus_type, exchange_rate));
      }
    }
  }, [wagers.wager_a, odds_a, bet_bonus_type, exchange_rate, betBonusTypes])


  useEffect(() => {
    console.log("Running useEffect 1"); // Add a label to each useEffect
    //When both sportsbooks are selected for the first time, or one changes, we need to get the exchange rate.

    if (!sportsbook_a || !sportsbook_b) { return }
    const _setExchangeRate = (rate) => {
      setExchangeRate(+(rate.rate.toFixed(6)));
    }
    fetchExchangeRate(sportsbook_a.currency, sportsbook_b.currency, _setExchangeRate);

  }, [sportsbook_a, sportsbook_b])

  const handleBetBonusTypeChange = (e) => {
    setBetBonusType(+(e.target.value));
  }
  const handleBetBBonusTypeChange = (e) => {
    setBetBBonusType(+(e.target.value));
  }

  const riskFreeOptionsForm = () => {
    showModal(<RiskFreeBetAdvancedOptions
        risk_free_percentage_returned={risk_free_percentage_returned}
        risk_free_max_value_returned={risk_free_max_value_returned}
        risk_free_estimated_conversion_rate={risk_free_estimated_conversion_rate}
        setRiskFreePercentageReturned={setRiskFreePercentageReturned}
        setRiskFreeMaxValueReturned={setRiskFreeMaxValueReturned}
        setRiskFreeEstimatedConversionRate={setRiskFreeEstimatedConversionRate}
        hideAdvancedOptions={hideModal}
      />);
  }
  const hideRiskFreeOptionsForm = () => {
    hideModal();
  }

  return (
    <div id='matched-bet-calculator'>
      <div id='bet-a-details' className='bet-details'>
        <div>
          <label>
            <div>
              Sportsbook A:
            </div>
            <div data-testid='sportsbook-a'>
              <SearchableDropdown
                  options={sportsbooks}
                  onChange={sportsbookAChange}
                  value={sportsbook_a}
                  initialValue={sportsbook_a}
              />
            </div>
          </label>
        </div>
        {sportsbook_a && sportsbook_b &&
          <div>
            <div>
              <label>
                <div>Odds:</div>
                <input
                  type='number'
                  value={odds_a}
                  ref={(input) => { registerInputField(input) }}
                  onChange={(e) => setOddsA(e.target.value)}
                  onBlur={fixInvalidOdds}
                  id='odds_a'
                  className='odds-input'
                  placeholder='American i.e. +110'
                />
                <span className='implied-prob-info'>{((1.0/americanToDecimalOdds(odds_a))*100.0).toFixed(2)}% implied prob.</span>
              </label>
            </div>

            <div>
              <label>
                <div>
                  <span>Wager:</span>
                </div>
                <div className='decorated-text-field-container'>
                  <input
                    type='number'
                    ref={(input) => { registerInputField(input) }}
                    className='currency-input'
                    value={wager_a()}
                    id='wager_a'
                    onChange={(e) => setWagerA(e.target.value)}
                    placeholder=''
                  />
                  <div className='text-field-decoration'>$</div>
                </div>
                <span className='wager-currency-indicator'>{ sportsbook_a ? sportsbook_a.currency : "" }</span>
              </label>
            </div>
            <div>
              <div>
                <select
                  onChange={handleBetBonusTypeChange}
                  id='bet_bonus_type'
                  ref={(input) => { registerInputField(input) }}
                  value={bet_bonus_type}
                >
                  {betBonusTypes &&
                  betBonusTypes.map((bbt) => (
                    <option key={bbt.id} value={bbt.id}>{bbt.name}</option>
                    ))
                  }
                </select>
                {betBonusTypeIdIsRiskFreeBet(bet_bonus_type) && !show_advanced_risk_free_options &&
                  <button onClick={riskFreeOptionsForm}>Risk-Free Options</button>
                }
              </div>
            </div>
            <div
              className='suggested-wagers'
              id='suggested_wagers'
            >
              <div>
                {suggested_wagers && suggested_wagers[0].map((sw) => (
                  <span key={sw[0]} className='suggested-wager' onClick={(e) => suggestedWagersSelected(sw[0], Math.round(sw[1],0))}>${sw[0]}/${Math.round(sw[1])}</span>
                ))}
              </div>
            </div>


          </div>
        }
      </div>

      <div id='bet-b-details' className='bet-details'>
        <div>
          <label>
            <div>
              Sportsbook B:
            </div>
            <SearchableDropdown
                options={sportsbooks}
                onChange={sportsbookBChange}
                value={sportsbook_b}
                initialValue={sportsbook_b}
                onKeyDown={handleSportsbookBTabPress}
            />
          </label>
        </div>
        {sportsbook_a && sportsbook_b &&
          <div>
            <div>
              <label>
                <div>Odds:</div>
                <input
                  type='number'
                  value={odds_b}
                  ref={(input) => { registerInputField( input) }}
                  onChange={(e) => setOddsB(e.target.value)}
                  onBlur={fixInvalidOdds}
                  id='odds_b'
                  className='odds-input'
                  placeholder='American i.e. -110'
                />
              </label>
              <span className='implied-prob-info'>{((1.0/americanToDecimalOdds(odds_b))*100.0).toFixed(2)}% implied prob.</span>
            </div>

            <div>
              <label>
                <div>
                  Wager:
                  <label className='small-label' data-tooltip-id="main-tooltip" data-tooltip-content="Check this to allow imbalanced wager values">
                    <input type='checkbox' checked={!link_bet_b_wager} onChange={(e) => setLinkBetBWager(!e.target.checked)} /> unlink amount <span className="material-symbols-outlined md-18">info</span>
                  </label>
                </div>
                <div className='decorated-text-field-container'>
                  <input
                    type='number'
                    ref={(input) => { registerInputField(input) }}
                    className='currency-input'
                    value={wager_b()}
                    id='wager_b'
                    onChange={(e) => _setWagerB(e.target.value)}
                    placeholder=''
                  />
                  <div className='text-field-decoration'>$</div>
                </div>
                <span className='wager-currency-indicator'>{ sportsbook_b ? sportsbook_b.currency : "" }</span>
              </label>
            </div>
            <div>
              <select
                onChange={handleBetBBonusTypeChange}
                id='bet_b_bonus_type'
                ref={(input) => { registerInputField(input) }}
                value={bet_b_bonus_type}
              >
                <option value='0'>No bonus/Stake Back Free Bet</option>
              </select>
            </div>
            <div
              className='suggested-wagers'
              id='suggested_wagers'
            >
              <div>
                {suggested_wagers && suggested_wagers[1].map((sw) => (
                  <span key={sw[0]} className='suggested-wager' onClick={(e) => suggestedWagersSelected(sw[0], Math.round(sw[1],0))}>${sw[0]}/${Math.round(sw[1])}</span>
                ))}
              </div>
            </div>

          </div>
        }
      </div>

      {sportsbook_a && sportsbook_b &&
        <div>
          <div className={'bet-details'}>
            <BetPayoutSummary
              wager_data={{
                wager: wager_a(),
                odds: odds_a,
                winnings: bet_a_winnings,
                bet_bonus_type: bet_bonus_type,
                stake_returned: betBonusTypeIdIsFreeBet(bet_bonus_type) ? 0 : wager_a(),
                total_payout: payout_a,
                expected_bonus_conversion_on_loss: null,
                currency: sportsbook_a.currency,
                sportsbook: sportsbook_a,
              }}
              />
          </div>
          <div className={'bet-details'}>
            <BetPayoutSummary
              wager_data={{
                wager: wager_b(),
                odds: odds_b,
                winnings: payout_b - wager_b(),
                bet_bonus_type: bet_bonus_type,
                stake_returned: wager_b(),
                total_payout: payout_b,
                expected_bonus_conversion_on_loss: betBonusTypeIdIsRiskFreeBet(bet_bonus_type) ? Math.min(wager_a() * risk_free_percentage_returned, +risk_free_max_value_returned === 0 ? wager_a() * risk_free_percentage_returned : risk_free_max_value_returned) * risk_free_estimated_conversion_rate : 0,
                currency: sportsbook_b.currency,
                sportsbook: sportsbook_b,
              }}
            />
          </div>
        </div>
      }
      <MatchedBetPayoutSummary
        betBonusTypes={betBonusTypes}
        wagers_data={[{
          wager: wager_a(),
          odds: odds_a,
          winnings: bet_a_winnings,
          bet_bonus_type: bet_bonus_type,
          stake_returned: bet_bonus_type === 1 ? 0 : wager_a(),
          total_payout: payout_a,
          expected_bonus_conversion_on_loss: null,
          currency: sportsbook_a.currency,
          sportsbook: sportsbook_a,
          exchange_rate: exchange_rate,
          risk_free_percentage_returned: risk_free_percentage_returned,
          risk_free_max_value_returned: risk_free_max_value_returned,
          risk_free_estimated_conversion_rate: risk_free_estimated_conversion_rate
        },{
          wager: wager_b(),
          odds: odds_b,
          winnings: payout_b - wager_b(),
          bet_bonus_type: bet_b_bonus_type,
          stake_returned: bet_b_bonus_type === 1 ? 0 : wager_b(),
          total_payout: payout_b,
          expected_bonus_conversion_on_loss: betBonusTypeIdIsRiskFreeBet(bet_bonus_type) ? Math.min(wager_a() * risk_free_percentage_returned, +risk_free_max_value_returned === 0 ? wager_a() * risk_free_percentage_returned : risk_free_max_value_returned) * risk_free_estimated_conversion_rate : 0,
          currency: sportsbook_b.currency,
          sportsbook: sportsbook_b,
          exchange_rate: exchange_rate,
          risk_free_percentage_returned: risk_free_percentage_returned,
          risk_free_max_value_returned: risk_free_max_value_returned,
          risk_free_estimated_conversion_rate: risk_free_estimated_conversion_rate
        }]}
      />


      {exchange_rate && +exchange_rate !== 1 &&
        <div>
          Exchange Rate: {exchange_rate}
        </div>
      }

      {sportsbook_a && sportsbook_b &&
        <div>
          <MatchedBetConditions
            setBetAConditions={_setBetAConditions}
            setBetBConditions={_setBetBConditions}
          />

          {bet_a_conditions && bet_a_conditions.bet_condition_type &&
            <input type='button' onClick={addMatchedBets} value='Add Bets to Tracker' />
          }
        </div>
      }
      <div>
        <input type={'button'} value={'Close'} onClick={hideModal} />
      </div>

    </div>
  );
};

export default MatchedBetCalculator;