import apiClient from "../APIClient";
import {useEffect} from "react";
import moment from "moment";

export const fetchGeneric = (url, setVal, setError) => {
  apiClient
    .get(url)
    .then((response) => {
      setVal(response.data);
    }).catch((error) => {
      if (setError) {
        setError(error)
      }
    });
}
export const deleteGeneric = (url, setVal, setError) => {
  apiClient
    .delete(url)
    .then((response) => {
      setVal(response.data);
    }).catch((error) => {
      if (setError) {
        setError(error)
      }
    });
}
export const fetchBets = (setVal, setError) => {
  fetchGeneric('/api/bets', setVal, setError);
};
export const fetchBetStatuses = (setVal, setError) => {
  fetchGeneric('/api/bet-statuses', setVal, setError);
};
export const fetchMatchedBets = (setVal, setError) => {
  fetchGeneric('/api/matched-bets', setVal, setError);
};
export const fetchMatchedBetsByDateRange = (startDate, endDate, setVal, setError) => {

  endDate = moment(endDate).add(1, 'days').toDate();
  fetchGeneric(`/api/matched-bets?start_date=${moment(startDate).year()}-${moment(startDate).month()+1}-${moment(startDate).date()}&end_date=${moment(endDate).year()}-${moment(endDate).month()+1}-${moment(endDate).date()}`, setVal, setError);
};
export const fetchMatchedBetStatuses = (setVal, setError) => {
  fetchGeneric('/api/matched-bet-statuses', setVal, setError);
};
export const fetchCurrencies = (setVal, setError) => {
  fetchGeneric('/api/currencies', setVal, setError);
}
export const fetchCurrentUser = (setVal, setError) => {
  fetchGeneric('/api/user', setVal, setError);
}
export const fetchSportsbookSignups = (setVal, setError) => {
  fetchGeneric('/api/sportsbook-signups', setVal, setError);
}
export const fetchBalanceSheet = (setVal, setError) => {
  fetchGeneric('/api/balance-sheet', setVal, setError);
}
export const fetchExchangeRate = (from, to, setVal, setError) => {
  const _setVal = (data) => {
    setVal(data.rate);
  }
  fetchGeneric(`/api/exchange-rate/${from}/${to}`, _setVal, setError);
}
export const fetchDeposits = (setVal, setError) => {
  fetchGeneric('/api/deposits', setVal, setError);
}
export const fetchWithdrawals = (setVal, setError) => {
  fetchGeneric('/api/withdrawals', setVal, setError);
}

export const fetchMoneyTypes = (setVal, setError) => {
  fetchGeneric('/api/money-types', setVal, setError);
}

export const fetchBetBonusTypes = (setVal, setError) => {
  fetchGeneric('/api/bet-bonus-types', setVal, setError);
}

export const fetchBetConditionTypes = (setVal, setError) => {
  fetchGeneric('/api/bet-condition-types', setVal, setError);
}

export const deleteBet = (bet_id, setVal, setError) => {
  deleteGeneric(`/api/bets/${bet_id}`, setVal, setError);
}