import {useEffect, useState} from "react";
import {currencyFormatter, middleOutcomes} from "../utils/BetUtils";
import './MiddleOutcomes.css'

const MiddleOutcomes = ({matchedBet}) => {
  const [outcomes, setOutcomes] = useState([]);

  useEffect(() => {
    const outcomes = middleOutcomes(matchedBet);
    setOutcomes(outcomes);
  }, [matchedBet]);

  return (
    <div className="middle-outcomes">
      <h1>Possible Middle Outcomes</h1>
      <ul>
      {outcomes.map((outcome, index) => {
        return (
          <li className="middle-outcomes__outcome" key={index}>
            <div className="middle-outcomes__outcome--title">{outcome.required_outcome}</div>
            <div className="middle-outcomes__outcome--title">{outcome.description}</div>
            <div className="middle-outcomes__outcome--subtitle">{outcome.extra_winnings}</div>
          </li>
        )
      })}
      </ul>
    </div>
  )
}

export default MiddleOutcomes;