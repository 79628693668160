import {AuthContext} from "../AuthContext";
import {useContext} from "react";

const Footer = () => {
  const { isAuthenticated, logoutUser } = useContext(AuthContext);

  return (
    <div id="footer">
    </div>
  )
}

export default Footer;