import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import AppRouter from "./AppRouter";
import Bet from './Bet'
import reportWebVitals from './reportWebVitals';
import App from './App'
import {BrowserRouter, Routes, Route} from "react-router-dom";
import BetPage from "./App";
import Calculator from "./Calculator";
import Register from "./components/Register"

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
