
import AddDeposit from "./AddDeposit";
import {useEffect, useState} from "react";
import apiClient from "../APIClient";
import AddWithdrawal from "./AddWithdrawal";
import BalanceSheet from "./BalanceSheet";
import TransactionList from "./TransactionList";
import {fetchBalanceSheet, fetchSportsbookSignups, fetchWithdrawals, fetchDeposits} from "../utils/ApiUtils";
import Modal from "./modal/Modal";


const Banking = () => {
  const [deposits, setDeposits] = useState([]);
  const [withdrawals, setWithdrawals] = useState([]);
  const [sportsbook_signups, setSportsbookSignups] = useState([]);
  const [balance_sheet, setBalanceSheet] = useState([]);

  useEffect(() => {
    fetchDeposits(setDeposits);
    fetchWithdrawals(setWithdrawals);
    fetchSportsbookSignups(setSportsbookSignups);
  }, []);

  useEffect(() => {
    fetchBalanceSheet(setBalanceSheet);
  }, [deposits, withdrawals]);

  const depositAdded = (newDeposit) => {
    setDeposits([...deposits, newDeposit]);
  }
  const withdrawalAdded = (newWithdrawal) => {
    setWithdrawals([...withdrawals, newWithdrawal]);
  }


  return (
    <div data-testid='banking-component'>
      <h1>Banking</h1>
      <BalanceSheet
        balance_sheet={balance_sheet}
        />
      <TransactionList
        items={deposits}
        setItems={setDeposits}
        itemAdded={depositAdded}
        item_type_name={"deposit"}
      />
      <TransactionList
        items={withdrawals}
        setItems={setWithdrawals}
        itemAdded={withdrawalAdded}
        item_type_name={"withdrawal"}
      />
    </div>
  )
}

export default Banking;