import "./MatchedBetsListFilter.css";
import React from "react";

const MatchedBetsListFilter = ({
  showOpen, setShowOpen,
  showSettled, setShowSettled,
  showAribitrage, setShowArbitrage,
  showLowHold, setShowLowHold,
  showMiddle, setShowMiddle,
  showFreeBet, setShowFreeBet,
  showRiskFreeBet, setShowRiskFreeBet,
  filterText, setFilterText
                               }) => {

  return (
    <div className="matched-bets-list-filters">
        <span>
          <label>
            <input type="checkbox" checked={showOpen} onChange={() => setShowOpen(!showOpen)} />
            Open
          </label>
        </span>
        <span>
          <label>
            <input type="checkbox" checked={showSettled} onChange={() => setShowSettled(!showSettled)} />
            Settled
          </label>
        </span>
        <span>
          <label>
            <input type="checkbox" checked={showAribitrage} onChange={() => setShowArbitrage(!showAribitrage)} />
            Arbitrage
          </label>
        </span>
        <span>
          <label>
            <input type="checkbox" checked={showLowHold} onChange={() => setShowLowHold(!showLowHold)} />
            Low Holds
          </label>
        </span>
        <span>
          <label>
            <input type="checkbox" checked={showMiddle} onChange={() => setShowMiddle(!showMiddle)} />
            Middles
          </label>
        </span>
        <span>
          <label>
            <input type="checkbox" checked={showFreeBet} onChange={() => setShowFreeBet(!showFreeBet)} />
            Free Bets
          </label>
        </span>
        <span>
          <label>
            <input type="checkbox" checked={showRiskFreeBet} onChange={() => setShowRiskFreeBet(!showRiskFreeBet)} />
            Risk-Free Bets
          </label>
        </span>
      <span>
        <input type={'text'} placeholder={"Search"} value={filterText} onChange={(e) => setFilterText(e.target.value)} />
        {filterText !== "" &&
          <span className={'material-symbols-outlined clickable'}
            onClick={() => setFilterText("")}
            data-tooltip-id="main-tooltip"
            data-tooltip-content={"Clear Search"}>
            cancel
          </span>
        }
      </span>
    </div>
  )
}

export default MatchedBetsListFilter;